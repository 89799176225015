import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Input } from '@gamma/form-fields';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_GENERAL_SETTINGS,
  useGetLicenseInfo,
  useUpdateTenantDisplayName,
} from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { useContext, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { TenantDisplayNameConfig } from '../TenantSettingsDashboard';

interface UpdateTenantDisplayNameProps {
  tenantDisplayNameConfig: Array<TenantDisplayNameConfig>;
}

const { buttons, toast, updateModal } = i18n.pages.tenantSettings;

export const UpdateTenantDisplayName = ({
  tenantDisplayNameConfig,
}: UpdateTenantDisplayNameProps) => {
  const initialValue = tenantDisplayNameConfig[0]?.display_name;
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const { handleSubmit, register, reset, setValue, watch } = useForm();
  const { setUserLicense } = useContext(AuthContext);
  const { isOpen, onClose, onOpen } = useDisclosure({
    onClose: () => {
      setDisableButton(true);
      reset();
    },
  });
  const showToast = useToast();

  const [currentValue] = watch(['display_name']);

  const [getUserLicense] = useGetLicenseInfo({ fetchPolicy: 'network-only' });

  const [updateTenantDisplayName, { loading: updateTenantDisplayNameLoading }] =
    useUpdateTenantDisplayName({
      awaitRefetchQueries: true,
      refetchQueries: [GET_GENERAL_SETTINGS],
      onCompleted: async () => {
        onClose();
        getUserLicense().then(({ data }) => {
          if (data?.getLicenseInfo) {
            setUserLicense?.(data?.getLicenseInfo);
          }
        });
        showToast({
          title: toast.success.title,
          description: toast.success.description,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      },
      onError: async () => {
        onClose();
        showToast({
          title: toast.error.title,
          description: toast.error.description,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const onSubmit = (formData: FieldValues) => {
    updateTenantDisplayName({ variables: { name: formData?.display_name } });
  };

  useEffect(() => {
    if (currentValue && currentValue !== initialValue) {
      setDisableButton(false);
    }
  }, [currentValue]);

  return (
    <>
      <IconButton
        data-testid="update-tenant-display-name"
        size="box-md"
        variant="solid"
        colorScheme="gray"
        icon={<MuiIcon color="text.primary">edit</MuiIcon>}
        aria-label="update tenant display name button"
        onClick={() => {
          onOpen();
        }}
      />
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnEsc={!updateTenantDisplayNameLoading}
        closeOnOverlayClick={!updateTenantDisplayNameLoading}
        isCloseDisabled={updateTenantDisplayNameLoading}
        isCentered
        size="sm"
        title={updateModal.title}
        body={
          <VStack alignItems="left">
            <HStack alignItems="flex-end" spacing={4}>
              <Box width="-webkit-fill-available">
                <Input
                  label="Tenant Display Name"
                  {...register('display_name')}
                  defaultValue={initialValue}
                ></Input>
              </Box>
            </HStack>
          </VStack>
        }
        footer={
          <HStack justifyContent="space-between" width="full">
            <Button
              colorScheme="blue"
              variant="link"
              isDisabled={disableButton || updateTenantDisplayNameLoading}
              onClick={() => {
                setValue('display_name', initialValue);
                setDisableButton(true);
              }}
            >
              {buttons.reset}
            </Button>
            <ButtonGroup>
              <Button
                variant="solid"
                colorScheme="gray"
                onClick={onClose}
                isDisabled={updateTenantDisplayNameLoading}
              >
                {buttons.cancel}
              </Button>
              <Button
                variant="solid"
                colorScheme="blue"
                onClick={handleSubmit(onSubmit)}
                isDisabled={updateTenantDisplayNameLoading || disableButton}
                isLoading={updateTenantDisplayNameLoading}
              >
                {buttons.save}
              </Button>
            </ButtonGroup>
          </HStack>
        }
      />
    </>
  );
};
