import { Flex, Tag, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ChatGPTToolTip } from '@gamma/investigator/components';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { LoadingSpinner } from '@gamma/progress';
import { useContext, useState } from 'react';

import { ChatGPTSettingsCardModal } from '../ChatGPTSettingsCardModal';

const { integrations } = i18n.pages;
const { available, enabled } = integrations;
const {
  GPT,
  gptCardHoverText,
  gptCardHoverTextData,
  privateData,
  nonPrivateData,
} = integrations.chatGPT;

export const ChatGPTSettingsCard = ({
  isPrivateDataCard = false,
  isEnabled,
}: {
  isPrivateDataCard?: boolean;
  isEnabled: boolean;
}) => {
  const { userRole, userPrivilegesLoading } = useContext(AuthContext);

  const [showHoverBackGround, setShowHoverBackGround] =
    useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Panel
        w="300px"
        h="200px"
        onClick={onOpen}
        layerStyle="first"
        _hover={{ bgColor: 'blue.alpha500.10' }}
        onMouseEnter={() => setShowHoverBackGround(true)}
        onMouseLeave={() => setShowHoverBackGround(false)}
        cursor={
          userRole !== 'admin' || userPrivilegesLoading
            ? 'not-allowed'
            : 'pointer'
        }
      >
        <VStack spacing={userPrivilegesLoading ? 4 : 8}>
          {/* Logo and Text */}
          <Flex
            h="92px"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {showHoverBackGround ? (
              <VStack mt={4}>
                <Text textStyle="h4">{GPT}</Text>
                <Text textAlign="center" textStyle="body-md">
                  {isPrivateDataCard ? gptCardHoverTextData : gptCardHoverText}
                </Text>
              </VStack>
            ) : (
              <VStack>
                <ChatGPTToolTip boxSize={16} />
                <Text mt={1} textStyle="h4">
                  {GPT}
                </Text>
                <Text>{isPrivateDataCard ? privateData : nonPrivateData}</Text>
              </VStack>
            )}
          </Flex>
          {/* Status Tag  and loading spinner */}
          {userPrivilegesLoading ? (
            <LoadingSpinner size="sm" />
          ) : (
            <Tag
              data-testid="chat-gpt-configuration-status"
              alignItems="center"
              justifyContent="center"
              backgroundColor={isEnabled ? 'green.600' : 'gray.600'}
            >
              {isEnabled ? (
                <MuiIcon mr={2}>check_circle</MuiIcon>
              ) : (
                <MuiIcon mr={2}>add_circle</MuiIcon>
              )}
              <Text textStyle="body-sm">{isEnabled ? enabled : available}</Text>
            </Tag>
          )}
        </VStack>
      </Panel>
      {userRole === 'admin' && isOpen && isEnabled !== null && (
        <ChatGPTSettingsCardModal
          isOpen={isOpen}
          onClose={onClose}
          isEnabled={isEnabled}
          isPrivateDataCard={isPrivateDataCard}
        />
      )}
    </>
  );
};
