import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ChatGPTIcon } from '@gamma/icons';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Panel, PanelHeader } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';

const {
  enableGPTIntegrationContent,
  enableGPTIntegrationTitle,
  enableGPTPrivateData,
} = i18n.pages.integrations.chatGPT;
const { systemSettingsIntegrationsConfig } = ROUTES;

export const EnableGPT = () => {
  return (
    <Panel>
      <PanelHeader divider>
        <HStack>
          <ChatGPTIcon boxSize={5} />{' '}
          <Text textStyle="h5">{enableGPTIntegrationTitle}</Text>
        </HStack>
      </PanelHeader>
      <VStack alignItems="start">
        <Text textStyle="body-md" color="text.secondary">
          {enableGPTIntegrationContent}
        </Text>
        <Button
          as={RouterLink}
          variant="solid"
          colorScheme="blue"
          mt={2}
          data-testid="chat-gpt-is-disabled-button"
          rightIcon={<MuiIcon>open_in_new</MuiIcon>}
          to={systemSettingsIntegrationsConfig}
          target="_blank"
        >
          {enableGPTPrivateData}
        </Button>
      </VStack>
    </Panel>
  );
};
