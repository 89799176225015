import { OperationVariables } from '@apollo/client';
import {
  HStack,
  IconButton,
  Link,
  ListItem,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Input } from '@gamma/form-fields';
import { REGEX } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { Alert } from '@gamma/overlay';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { MutationFunctionType } from '../../../types';
import { storeIdentityProviderProps } from '../../IdpConfigurationModal';

interface ConfigureRolesProps {
  useFormProps: UseFormReturn<FieldValues, any>;
  getStoredIdentityProviderData: () => storeIdentityProviderProps;
  configureIdentityProvider: MutationFunctionType;
  updateIdentityProvider: MutationFunctionType;
  isUpdating?: boolean | undefined;
  updateIDPConfig: boolean;
  setUpdateIDPConfig: Dispatch<SetStateAction<any>>;
  data: OperationVariables | undefined;
}

export const SYSTEM_ROLES = {
  ADMIN: 'corelight_admin',
  ANALYST: 'corelight_analyst',
  VIEWER: 'corelight_viewer',
};

export const ConfigureRoles = ({
  useFormProps,
  getStoredIdentityProviderData,
  configureIdentityProvider,
  updateIdentityProvider,
  updateIDPConfig,
  setUpdateIDPConfig,
  isUpdating,
  data,
}: ConfigureRolesProps) => {
  const { idpConfigurationModal } = i18n.pages.access;
  const describeData = data?.describeIdentityProvider || {};
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { dirtyFields, errors, isDirty },
  } = useFormProps;

  const storedIdentityProviderData = getStoredIdentityProviderData();

  useEffect(() => {
    reset({});
  }, []);

  useEffect(() => {
    if (describeData?.idp_user_role_mapping) {
      let idp_user_role_mapping = describeData?.idp_user_role_mapping
        ? JSON.parse(describeData?.idp_user_role_mapping)
        : {};

      if (idp_user_role_mapping && Object.keys(idp_user_role_mapping).length) {
        idp_user_role_mapping = reverseObject(idp_user_role_mapping);
        const defaultValues = {
          [SYSTEM_ROLES.ADMIN]: idp_user_role_mapping?.corelight_admin || '',
          [SYSTEM_ROLES.ANALYST]:
            idp_user_role_mapping?.corelight_analyst || '',
          [SYSTEM_ROLES.VIEWER]: idp_user_role_mapping?.corelight_viewer || '',
        };

        reset(defaultValues);
      }
    }
  }, [describeData]);

  useEffect(() => {
    if (isUpdating) {
      setUpdateIDPConfig(
        Object.keys(dirtyFields).length > 0 || updateIDPConfig,
      );
    }
  }, [isDirty]);

  function reverseObject(obj: Record<string, string>): Record<string, string> {
    return Object.entries(obj).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
          acc[value] = key;
        }
        return acc;
      },
      {},
    );
  }

  const validateField = (value: string, fieldName: string) => {
    const values = getValues();
    const { corelight_admin, corelight_analyst, corelight_viewer } = values;

    if (!value) {
      return true;
    }

    if (isUpdating) {
      setUpdateIDPConfig(Object.keys(dirtyFields).length > 0);
    }

    if (!REGEX.USER_ROLE.test(value)) {
      return idpConfigurationModal.configureRoles.form.errors.invalid;
    }

    if (
      (fieldName === SYSTEM_ROLES.ADMIN &&
        (value === corelight_analyst || value === corelight_viewer)) ||
      (fieldName === SYSTEM_ROLES.ANALYST &&
        (value === corelight_admin || value === corelight_viewer)) ||
      (fieldName === SYSTEM_ROLES.VIEWER &&
        (value === corelight_admin || value === corelight_analyst))
    ) {
      return idpConfigurationModal.configureRoles.form.errors.unique_role;
    }

    return true;
  };

  const onSubmit = async (userData: FieldValues) => {
    const variables = {
      attribute_mapping: storedIdentityProviderData.attribute_mapping,
      provider_details: storedIdentityProviderData.provider_details,
      provider_type: storedIdentityProviderData.provider_type,
      idp_user_role_mapping: JSON.stringify(reverseObject(userData)),
    };

    if (isUpdating && updateIDPConfig) {
      updateIdentityProvider({ variables });
    }
    if (!isUpdating && !describeData?.provider_details) {
      configureIdentityProvider({ variables });
    }
  };

  return (
    <Stack spacing={6}>
      <Stack
        as="form"
        id="idpRoleConfigurationForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text>{idpConfigurationModal.configureRoles.heading}</Text>
        <Panel layerStyle="second">
          <VStack w="full" alignItems="start">
            <HStack w="full">
              <Text fontWeight="bold">
                {idpConfigurationModal.configureRoles.adminUsers}
              </Text>
              <Tooltip
                label={"Optional  - defaults to '" + SYSTEM_ROLES.ADMIN + "'"}
              >
                <IconButton
                  aria-label="copy"
                  icon={<MuiIcon>info</MuiIcon>}
                  color="blue.300"
                />
              </Tooltip>
            </HStack>
          </VStack>
          <VStack w="full">
            <Input
              label="custom_admin_role"
              isLabelHidden={true}
              afterLabel={true}
              data-testid="custom_admin_role_field"
              placeholder={
                'add custom role for ' +
                idpConfigurationModal.configureRoles.admin
              }
              {...register(SYSTEM_ROLES.ADMIN, {
                value: SYSTEM_ROLES.ADMIN,
                validate: (value) => validateField(value, 'corelight_admin'),
              })}
              error={errors.corelight_admin?.message?.toString()}
            />
          </VStack>
        </Panel>
        <Panel layerStyle="second">
          <VStack w="full" alignItems="start">
            <HStack w="full">
              <Text fontWeight="bold">
                {idpConfigurationModal.configureRoles.analystUsers}
              </Text>
              <Tooltip
                label={"Optional  - defaults to '" + SYSTEM_ROLES.ANALYST + "'"}
              >
                <IconButton
                  aria-label="copy"
                  icon={<MuiIcon>info</MuiIcon>}
                  color="blue.300"
                />
              </Tooltip>
            </HStack>
          </VStack>
          <VStack w="full">
            <Input
              isLabelHidden={true}
              label="custom_analyst_role"
              data-testid="custom_analyst_role_field"
              placeholder={
                'add custom role for ' +
                idpConfigurationModal.configureRoles.analyst
              }
              {...register(SYSTEM_ROLES.ANALYST, {
                value: SYSTEM_ROLES.ANALYST,
                validate: (value) => validateField(value, 'corelight_analyst'),
              })}
              error={errors.corelight_analyst?.message?.toString()}
            />
          </VStack>
        </Panel>
        <Panel layerStyle="second">
          <VStack w="full" alignItems="start">
            <HStack w="full">
              <Text fontWeight="bold">
                {idpConfigurationModal.configureRoles.viewerUsers}
              </Text>
              <Tooltip
                label={"Optional  - defaults to '" + SYSTEM_ROLES.ANALYST + "'"}
              >
                <IconButton
                  aria-label="copy"
                  icon={<MuiIcon>info</MuiIcon>}
                  color="blue.300"
                />
              </Tooltip>
            </HStack>
          </VStack>
          <VStack w="full">
            <Input
              isLabelHidden={true}
              data-testid="custom_viewer_role_field"
              label="custom_viewer_role"
              placeholder={
                'add custom role for ' +
                idpConfigurationModal.configureRoles.viewer
              }
              {...register(SYSTEM_ROLES.VIEWER, {
                value: SYSTEM_ROLES.VIEWER,
                validate: (value) => validateField(value, 'corelight_viewer'),
              })}
              error={errors.corelight_viewer?.message?.toString()}
            />
          </VStack>
        </Panel>
        <Alert
          status="info"
          variant="subtle"
          title={
            <UnorderedList>
              <ListItem fontWeight="normal">
                {idpConfigurationModal.configureRoles.listItemAdmin}
              </ListItem>
              <ListItem fontWeight="normal">
                {idpConfigurationModal.configureRoles.listItemAnalystViewer}
              </ListItem>
            </UnorderedList>
          }
        />
      </Stack>
      <Stack>
        <Text>{idpConfigurationModal.configure.help}</Text>
        <Link
          isExternal
          color="blue.400"
          href="/docs/settings/users/saml-sso-users.html"
          width="fit-content"
        >
          {idpConfigurationModal.configure.helpTextSSO}
        </Link>
      </Stack>
    </Stack>
  );
};
