import {
  Box,
  CloseButton,
  Grid,
  Heading,
  Portal,
  Stack,
  usePrevious,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useId, useRef } from 'react';
import { useSlideDrawerContext } from '../hooks';

export interface SlideDrawerProps {
  children: ReactNode;
  title: ReactNode;
  actions: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}

const MotionGrid = motion(Grid);

export const SlideDrawer = ({
  children,
  title,
  actions,
  isOpen,
  onClose = () => {
    /*noop*/
  },
}: SlideDrawerProps) => {
  const titleRef = useRef<HTMLSpanElement>(null);
  const titleId = `slide-drawer-title-${useId()}`;

  const { portalRef } = useSlideDrawerContext();

  const wasOpen = usePrevious(isOpen);
  const previouslyActiveElement = useRef<Element | null>(null);

  useEffect(() => {
    if (wasOpen === isOpen) {
      return;
    }

    if (isOpen && titleRef.current) {
      previouslyActiveElement.current = document.activeElement;
      titleRef.current.focus();
    } else if (previouslyActiveElement.current instanceof HTMLElement) {
      previouslyActiveElement.current.focus();
    }
  }, [isOpen, wasOpen]);

  return (
    <Portal containerRef={portalRef}>
      <MotionGrid
        initial={false}
        animate={{ width: isOpen ? '400px' : 0 }}
        height="100%"
      >
        <Grid bg="layer.1" borderLeft="1px solid" borderColor="border.1">
          <Box
            role="dialog"
            hidden={!isOpen}
            aria-labelledby={titleId}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                e.stopPropagation();
                onClose();
              }
            }}
          >
            <Stack
              position="sticky"
              top={0}
              zIndex="sticky"
              bg="layer.1"
              borderBottom="solid 1px"
              borderColor="border.1"
              px={3}
              py={4}
            >
              <Box mr={6}>
                <Heading as="h5" textStyle="h5">
                  <span ref={titleRef} id={titleId} tabIndex={-1}>
                    {title}
                  </span>
                </Heading>
                <CloseButton
                  onClick={onClose}
                  size="md"
                  position="absolute"
                  top={4}
                  right={3}
                >
                  <MuiIcon>close</MuiIcon>
                </CloseButton>
              </Box>
              {actions && (
                <Box data-testid={`dataTestId-actions-wrapper`}>{actions}</Box>
              )}
            </Stack>
            {children}
          </Box>
        </Grid>
      </MotionGrid>
    </Portal>
  );
};
