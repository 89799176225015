import { useQuery } from '@apollo/client';
import { Box, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import {
  AlertCategoriesDetectionsTable,
  HighRiskEntitiesDetectionsTable,
} from '@gamma/investigator/components';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_DETECTION_PERIOD_SUMMARY,
  IGetDetectionPeriodSummary,
} from '@gamma/investigator/queries';
import {
  Board,
  Column,
  FlushPanelContent,
  Panel,
  PanelHeader,
  PanelHeading,
} from '@gamma/layout';
import { Tab, Tabs } from '@gamma/tabs';
import { useContext, useMemo, useState } from 'react';

import {
  CorelightBlogsPanel,
  DetectionsByCategoryPanel,
  EntitiesWithDetectionsPanel,
  TotalDetectionsPanel,
} from './Panels';

const { highestRiskDetections, includesOpen, panels } =
  i18n.pages.securityOverview;

export const SecurityOverview = () => {
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const { start, end } = useDateRangeQueryString();
  const { loading, error, data } = useQuery<IGetDetectionPeriodSummary>(
    GET_DETECTION_PERIOD_SUMMARY,
    {
      skip: !start || !end || !orgTenantsQueryParam?.length,
      variables: {
        start,
        end,
        prev_start: start - (end - start),
        prev_end: start,
        must_conds: [
          `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
        ],
      },
    },
  );

  const [tabIndex, setTabIndex] = useState(
    Number(localStorage.getItem('security-overview-active-table') ?? 0),
  );

  const tabs = useMemo(
    () => [
      {
        title: panels.highestRiskEntities.heading,
        content: (
          <div data-testid="highest-risk-entities">
            <HighRiskEntitiesDetectionsTable />
          </div>
        ),
      },
      {
        title: panels.alertCategories,
        content: (
          <div data-testid="alert-categories">
            <AlertCategoriesDetectionsTable />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Board h="100%" flex="1 1 auto" height="inherit" margin={0} width="auto">
      <Column display="flex" alignItems="start" col={{ base: 12, lg: 3.5 }}>
        <Board>
          <Column>
            <Panel>
              <PanelHeader>
                <PanelHeading>
                  <HStack>
                    <Text>{highestRiskDetections}</Text>
                    <Tooltip label={includesOpen} shouldWrapChildren>
                      <MuiIcon
                        color="text.secondary"
                        data-testid="highest-risk-detections-info-label"
                      >
                        info
                      </MuiIcon>
                    </Tooltip>
                  </HStack>
                </PanelHeading>
              </PanelHeader>
              <FlushPanelContent>
                <div data-testid="top-entities-table">
                  <Tabs
                    variant="line"
                    defaultIndex={tabIndex}
                    onChange={(index) => {
                      setTabIndex(index);
                      localStorage.setItem(
                        'security-overview-active-table',
                        String(index),
                      );
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab key={tab.title} title={tab.title}>
                        <Box pt={2.5}>{tab.content}</Box>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
              </FlushPanelContent>
            </Panel>
          </Column>
          <Column data-testid="corelight-blogs">
            <CorelightBlogsPanel />
          </Column>
        </Board>
      </Column>
      <Column display="flex" alignItems="start" col={{ base: 12, lg: 8.5 }}>
        <Board w="100%" flexDir="column" flex="1 1 auto">
          <Flex w="100%" flexWrap="wrap">
            <Column
              col={{ base: 12, lg: 6 }}
              data-testid="entities-with-alerts-list"
            >
              <EntitiesWithDetectionsPanel
                loading={loading}
                error={error}
                data={data}
              />
            </Column>
            <Column
              col={{ base: 12, lg: 6 }}
              data-testid="total-alert-categories"
            >
              <TotalDetectionsPanel
                loading={loading}
                error={error}
                data={data}
              />
            </Column>
          </Flex>
          <Flex flex="1 1 auto">
            <Column col={{ base: 12 }} data-testid="mitre-attack-map">
              <DetectionsByCategoryPanel />
            </Column>
          </Flex>
        </Board>
      </Column>
    </Board>
  );
};
