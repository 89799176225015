import {
  ChakraProps,
  PropsOf,
  StyleProps,
  chakra,
  useStyleConfig,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export type MuiIconProps = StyleProps & {
  children: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: string;
  tabIndex?: number;
};

export const MuiIcon = forwardRef<
  HTMLSpanElement,
  PropsOf<'span'> & MuiIconProps & ChakraProps
>(({ children, size = 'md', color, className = '', ...rest }, ref) => {
  const styles = useStyleConfig('MuiIcon', { size });
  return (
    <chakra.span
      aria-hidden
      className={`mui-icon ${className}`}
      __css={styles}
      color={color}
      ref={ref}
      {...rest}
    >
      {children}
    </chakra.span>
  );
});
