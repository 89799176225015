import {
  Button,
  ButtonGroup,
  CloseButton,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Input } from '@gamma/form-fields';
import { USER_STATUS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  LIST_USERS,
  User,
  UserStatus,
  useEnableDisableUsers,
} from '@gamma/investigator/queries';
import { PanelHeading } from '@gamma/layout';
import { Modal } from '@gamma/overlay';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Row } from 'react-table';
import { BulkStatusModalBody } from '../BulkStatusModalBody';
import { CreateUserDrawer } from '../CreateUserDrawer';
import { DeleteUserModal } from '../DeleteUserModal';
import { useContext } from 'react';
import { AuthContext } from '@gamma/investigator/context';

export interface UsersAccessToolbarProps {
  userSelection: Row<User>[];
  tableSearch: string;
  onSearchChange: (search: string) => void;
}

interface initialStateInterface {
  title: string;
  status: UserStatus;
}

export const UsersAccessToolbar = ({
  userSelection,
  tableSearch,
  onSearchChange,
}: UsersAccessToolbarProps) => {
  const { active, inactive, invited } = USER_STATUS;
  const initialConfig = {} as initialStateInterface;
  const [modalConfigs, setModalConfigs] = useState(initialConfig);
  const { panels, search, select, buttons, modals, toasts } =
    i18n.pages.usersAccess;
  const searchRef = useRef<HTMLInputElement>(null);
  const { user } = useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      reset();
    },
  });

  const showToastMessage = useToast();

  // Bulk EnableDisable API
  const [enableDisableUsers, { loading: enableDisableLoading, reset }] =
    useEnableDisableUsers({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: LIST_USERS,
          variables: { tenants: [`${user?.attributes['custom:tenant_id']}`] },
        },
      ],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: toasts.bulkStatus.success.title,
          description: toasts.bulkStatus.success.description,
          isClosable: true,
          position: 'bottom-right',
        });
        onClose();
      },
      onError: (err: Error) => {
        showToastMessage({
          status: 'error',
          title: toasts.bulkStatus.error.title,
          description: err.message,
          isClosable: true,
          position: 'bottom-right',
        });
        onClose();
      },
    });

  const toggleUsersStatus = useCallback(
    async (status: UserStatus) => {
      reset();
      const validCurrentStatusMap = new Map<
        Omit<UserStatus, 'invited'>,
        UserStatus[]
      >([
        [active, [inactive]],
        [inactive, [active, invited]],
      ]);
      const validStatuses = validCurrentStatusMap.get(status);

      // Show error message when trying to change the status of invited user to active.
      const showErrMsgFrmInvitedToActiveStatusChange =
        status === active &&
        userSelection.some(({ original }) => original.status === invited);

      const selectedUsersWithValidStatuses = userSelection.filter(
        ({ original }) => validStatuses?.includes(original.status),
      );

      const usernamesToBeUpdatedFormatted: string[] =
        selectedUsersWithValidStatuses.map(({ original }) => {
          return original.username;
        });

      if (!usernamesToBeUpdatedFormatted.length) {
        onClose();
        return;
      }

      await enableDisableUsers({
        variables: {
          disable: status !== active,
          usernames: usernamesToBeUpdatedFormatted,
        },
      });
    },
    [userSelection, enableDisableUsers],
  );

  const usersToBeDeleted = () =>
    userSelection.map(({ original: { email } }) => email);

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const selectedUsersCount = userSelection?.length;

  return (
    <>
      <Stack w={350}>
        <PanelHeading>{panels.users.heading}</PanelHeading>
        <Input
          name="Search"
          label="Search"
          ref={searchRef}
          isLabelHidden
          value={tableSearch}
          leftElement={<MuiIcon>search</MuiIcon>}
          rightElement={
            tableSearch && (
              <CloseButton
                data-testid="clear-search"
                onClick={() => onSearchChange('')}
              />
            )
          }
          placeholder={search.placeholder}
          onChange={(e) => onSearchChange(e.target.value)}
          data-testid="search-input"
        />
      </Stack>
      <ButtonGroup
        alignItems="center"
        alignSelf="end"
        variant="solid"
        size="md"
      >
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<MuiIcon>play_arrow</MuiIcon>}
            rightIcon={<MuiIcon>keyboard_arrow_down</MuiIcon>}
            isDisabled={!selectedUsersCount}
            data-testid="change-status-select"
          >
            {select.placeholder}
          </MenuButton>
          <Modal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isCloseDisabled={enableDisableLoading}
            closeOnOverlayClick={!enableDisableLoading}
            closeOnEsc={!enableDisableLoading}
            isCentered
            hideDivider
            title={modalConfigs.title}
            footer={
              <Button
                isLoading={enableDisableLoading}
                onClick={() => toggleUsersStatus(modalConfigs.status)}
              >
                {buttons.confirm}
              </Button>
            }
            body={
              <BulkStatusModalBody
                users={userSelection}
                message={modals.info}
                status={modalConfigs.status}
              />
            }
          >
            <MenuList>
              <span>
                <MenuItem
                  data-testid="set-inactive"
                  onClick={() =>
                    setModalConfigs({
                      title:
                        selectedUsersCount > 1
                          ? modals.inactive.usersTitle
                          : modals.inactive.userTitle,
                      status: inactive,
                    })
                  }
                >
                  {select.inactive}
                </MenuItem>
              </span>
              <span>
                <MenuItem
                  data-testid="set-active"
                  onClick={() =>
                    setModalConfigs({
                      title:
                        selectedUsersCount > 1
                          ? modals.active.usersTitle
                          : modals.active.userTitle,
                      status: active,
                    })
                  }
                >
                  {select.active}
                </MenuItem>
              </span>
            </MenuList>
          </Modal>
        </Menu>
        <DeleteUserModal users={usersToBeDeleted()}>
          <Button
            leftIcon={<MuiIcon>delete</MuiIcon>}
            size="md"
            isDisabled={!selectedUsersCount}
            data-testid="delete-user-button"
          >
            {buttons.delete}
          </Button>
        </DeleteUserModal>
        <Divider orientation="vertical" borderColor="border.1" h="18px" />
        <CreateUserDrawer>
          <Button
            leftIcon={<MuiIcon>add</MuiIcon>}
            data-testid="add-user-button"
          >
            {buttons.add}
          </Button>
        </CreateUserDrawer>
      </ButtonGroup>
    </>
  );
};
