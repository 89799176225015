import {
  HStack,
  Stack,
  VStack,
  Text,
  useDisclosure,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { OperationVariables } from '@apollo/client';
import { ReactNode, useEffect, useState } from 'react';
import { Modal } from '@gamma/overlay';
import { i18n } from '@gamma/investigator/localization';
import { Panel, PanelHeading } from '@gamma/layout';
import { MuiIcon } from '@gamma/icons';
import { CONSTANTS } from '@gamma/investigator/constants';
import { useIDPUploadXMLHandler } from '@gamma/investigator/hooks';

interface ViewConfigurationModalProps {
  children: ReactNode;
  describeData: OperationVariables | undefined;
}

const { title } = i18n.pages.access.viewConfigurationModal;
const { button, form, ssoUrl } = i18n.pages.access;

export const ViewConfigurationModal = ({
  children,
  describeData,
}: ViewConfigurationModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { SSO } = CONSTANTS;

  const [parsedData, setParsedData] = useState<Record<string, string>>();

  const { parseIDPXml } = useIDPUploadXMLHandler({});

  useEffect(() => {
    if (describeData?.provider_details) {
      const xmlString = JSON.parse(describeData?.provider_details);
      const parsedIDPXml = parseIDPXml(xmlString);
      setParsedData(parsedIDPXml);
    }
  }, [describeData?.provider_details]);

  return (
    <Modal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isCentered
      size="xl"
      title={title}
      body={
        <VStack spacing={2}>
          <Panel layerStyle="second">
            <HStack justifyContent="space-between">
              <Stack>
                <PanelHeading>{ssoUrl}</PanelHeading>
                <Text>{SSO.remote_manager_url}</Text>
              </Stack>
              <IconButton
                aria-label="copy"
                icon={<MuiIcon>content_copy</MuiIcon>}
                color="blue.300"
                onClick={() =>
                  navigator.clipboard.writeText(SSO.remote_manager_url)
                }
              />
            </HStack>
          </Panel>
          <Panel layerStyle="second">
            <PanelHeading>{form.ssoUrl.label}</PanelHeading>
            <Text>{parsedData?.signon_url}</Text>
          </Panel>
          <Panel layerStyle="second">
            <PanelHeading>{form.entityId.label}</PanelHeading>
            <Text>{parsedData?.entity_id}</Text>
          </Panel>
          <Panel layerStyle="second">
            <PanelHeading>{form.certificate.label}</PanelHeading>
            <Text>{parsedData?.certificate}</Text>
          </Panel>
        </VStack>
      }
      footer={
        <Button variant="solid" colorScheme="gray" onClick={onClose}>
          {button.close}
        </Button>
      }
    >
      {children}
    </Modal>
  );
};
