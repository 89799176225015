import { i18n } from '@gamma/investigator/localization';

import { PillWrapper } from '.';
import { MuiIcon } from '@gamma/icons';

const { suppressSuccessTitle } = i18n.pages.entityDetails.excludeEntity;

export const EntitySuppressedPill = () => {
  return (
    <PillWrapper label={suppressSuccessTitle}>
      <MuiIcon size="sm">notifications_off</MuiIcon>
    </PillWrapper>
  );
};
