import { ApolloError } from '@apollo/client';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverTrigger,
  Tooltip,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  BellIcon,
  BellOffIcon,
  ChevronDownIcon,
  CircleCheckIcon,
  CircleUserIcon,
  CopyIcon,
  SendToIcon,
} from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionQueryVariables,
  IGetDetectionVariables,
} from '@gamma/investigator/pages/detections';
import {
  Detection,
  IQueryAlertMetadataExcludedEntitiesPaginated,
  useQueryEscalationConfigurationsPublic,
} from '@gamma/investigator/queries';
import { LoadingSpinner } from '@gamma/progress';
import { useContext, useEffect, useState } from 'react';

import {
  AssignUserContent,
  CloseDetectionContent,
  SendToContent,
  SuppressEntityContent,
} from '../PopoverContent';
import { useSearchParams } from 'react-router-dom';
import { MuiIcon } from '@gamma/icons';
import { AddNotePopover } from '../PopoverContent/AddNote/AddNotePopover';
import { SlideDrawerContext } from '@gamma/investigator/context';

const { excludeEntity } = i18n.pages.entityDetails;

const { detectionIsClosed, suppress, unSuppress } = excludeEntity;

const {
  close,
  sendTo,
  controls,
  copiedUrl,
  detectionIsSent,
  copyDetectionUrl,
  SNConfigureAndEnable,
  detection: { addNote },
} = i18n.pages.detections;

const { actions, assignTo } = controls;

interface ActionsMenuProps {
  isRelated?: boolean;
  isRowActions?: boolean;
  detectionData?: Detection;
  refetchQueries: any;
  excludedEntitiesData?: IQueryAlertMetadataExcludedEntitiesPaginated;
  excludedEntitiesError?: ApolloError;
  excludedEntitiesLoading: boolean;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
  userRole?: string;
}

export const ActionsMenuButton = ({
  isRelated,
  isRowActions,
  detectionData,
  refetchQueries,
  excludedEntitiesData,
  excludedEntitiesError,
  excludedEntitiesLoading,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
  userRole,
}: ActionsMenuProps) => {
  const toast = useToast();

  const { isNotesDrawerLoading } = useContext(SlideDrawerContext);
  const [_, setSearchParams] = useSearchParams();
  const { isOpen, onToggle, onClose: closePopover } = useDisclosure();
  const [popoverType, setPopoverType] = useState<null | string>(null);

  const [closeLoading, setCloseLoading] = useState<boolean>(false);
  const [suppressLoading, setSuppressLoading] = useState<boolean>(false);
  const [assignLoading, setAssignLoading] = useState<boolean>(false);
  const [sendToLoading, setSendToLoading] = useState<boolean>(false);

  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [isSuppressed, setIsSuppressed] = useState<boolean>(false);
  const [isSentTo, setIsSentTo] = useState<boolean>(false);

  const { hasCopied, onCopy } = useClipboard(window.location.href);

  const onClose = () => {
    setPopoverType(null);
    closePopover();
  };
  useEffect(() => {
    if (hasCopied) {
      toast({
        position: 'top',
        status: 'success',
        description: copiedUrl,
      });
    }
  }, [hasCopied, toast]);

  const {
    loading: escalationConfigurationsLoading,
    error: escalationConfigurationsError,
    data: escalationConfigurationsData,
  } = useQueryEscalationConfigurationsPublic({});

  const serviceNowConfiguration =
    escalationConfigurationsData?.queryEscalationConfigurations
      ?.escalation_configurations?.[0];

  useEffect(() => {
    setIsClosed(detectionData?.detection_status !== 'open');
    setIsSentTo(!!detectionData?.escalation_data?.escalation_status);
    if (excludedEntitiesData) {
      setIsSuppressed(
        excludedEntitiesData?.queryAlertMetadataExcludedEntitiesPaginated
          ?.excluded_entities?.length > 0,
      );
    }
  }, [detectionData, excludedEntitiesData]);

  const menuDisabled =
    closeLoading ||
    suppressLoading ||
    assignLoading ||
    sendToLoading ||
    isNotesDrawerLoading ||
    escalationConfigurationsLoading;

  return userRole === 'viewer' ? (
    <Button
      onClick={onCopy}
      variant="solid"
      colorScheme="gray"
      data-testid="detection-details-header-copy-button"
    >
      <CopyIcon boxSize={5} />
      {copyDetectionUrl}
    </Button>
  ) : (
    <VStack>
      <Popover
        isOpen={isOpen}
        offset={[0, 10]}
        onClose={onClose}
        placement="bottom-start"
      >
        <Menu>
          <PopoverTrigger>
            <MenuButton
              as={Button}
              variant="solid"
              disabled={menuDisabled}
              colorScheme="gray"
              rightIcon={
                menuDisabled ? (
                  <LoadingSpinner size="sm" />
                ) : (
                  <ChevronDownIcon />
                )
              }
            >
              {actions}
            </MenuButton>
          </PopoverTrigger>
          <MenuList>
            <VStack spacing={0} alignItems="start">
              <MenuItem onClick={onCopy} icon={<CopyIcon boxSize={5} />}>
                {copyDetectionUrl}
              </MenuItem>
              <Tooltip
                offset={[-9, 6]}
                placement="left-start"
                shouldWrapChildren={true}
                label={isClosed ? detectionIsClosed : null}
              >
                <MenuItem
                  isDisabled={isClosed}
                  data-testid="close-detection-trigger-actions-menu-button"
                  onClick={() => {
                    onToggle();
                    setPopoverType('close');
                  }}
                  icon={<CircleCheckIcon boxSize={5} />}
                >
                  {close}
                </MenuItem>
              </Tooltip>
              <MenuItem
                data-testid="add-note-detection-trigger-actions-menu-button"
                onClick={() => {
                  onToggle();
                  setPopoverType('addNote');
                }}
                icon={<MuiIcon>chat</MuiIcon>}
              >
                {addNote}
              </MenuItem>
              <Tooltip
                offset={[-9, 6]}
                placement="left-start"
                shouldWrapChildren={true}
                label={
                  isSentTo
                    ? detectionIsSent
                    : !sendToLoading &&
                        !escalationConfigurationsLoading &&
                        !serviceNowConfiguration?.enabled
                      ? SNConfigureAndEnable
                      : null
                }
              >
                <MenuItem
                  isDisabled={
                    isSentTo ||
                    !serviceNowConfiguration ||
                    !serviceNowConfiguration?.enabled
                  }
                  data-testid="send-to-trigger-actions-menu-button"
                  onClick={() => {
                    onToggle();
                    setPopoverType('sendTo');
                  }}
                  icon={<SendToIcon boxSize={5} />}
                >
                  {sendTo}
                </MenuItem>
              </Tooltip>
              <MenuItem
                data-testid="suppress-entity-trigger-actions-menu-button"
                onClick={() => {
                  onToggle();
                  setPopoverType('suppress');
                }}
                icon={
                  !isSuppressed ? (
                    <BellOffIcon boxSize={5} />
                  ) : (
                    <BellIcon boxSize={5} />
                  )
                }
              >
                {isSuppressed ? unSuppress : suppress}
              </MenuItem>
              <MenuItem
                data-testid="assign-detection-trigger-actions-menu-button"
                onClick={() => {
                  onToggle();
                  setPopoverType('assign');
                }}
                icon={<CircleUserIcon boxSize={5} />}
              >
                {assignTo}
              </MenuItem>
            </VStack>
          </MenuList>
        </Menu>
        {popoverType === 'close' && (
          <CloseDetectionContent
            onClose={onClose}
            setIsClosed={setIsClosed}
            detectionData={detectionData}
            setIsLoading={setCloseLoading}
            refetchQueries={refetchQueries}
            refetchMain={true}
            excludedEntitiesLoading={excludedEntitiesLoading}
            isLoading={closeLoading}
          />
        )}
        {popoverType === 'sendTo' && (
          <SendToContent
            onClose={onClose}
            setIsSentTo={setIsSentTo}
            detectionData={detectionData}
            setIsLoading={setSendToLoading}
            refetchQueries={refetchQueries}
            refetchMain={true}
            serviceNowConfiguration={serviceNowConfiguration}
            excludedEntitiesLoading={excludedEntitiesLoading}
            isLoading={sendToLoading}
          />
        )}
        {popoverType === 'suppress' && (
          <SuppressEntityContent
            onClose={onClose}
            detectionData={detectionData}
            setIsSuppressed={setIsSuppressed}
            setIsLoading={setSuppressLoading}
            refetchMain={true}
            excludedEntitiesData={excludedEntitiesData}
            excludedEntitiesError={excludedEntitiesError}
            isLoading={suppressLoading}
          />
        )}
        {popoverType === 'assign' && (
          <AssignUserContent
            onClose={onClose}
            detectionData={detectionData}
            setIsLoading={setAssignLoading}
            isLoading={assignLoading}
            refetchMain={true}
            getDetectionVariables={getDetectionVariables}
            queryDetectionsPaginatedVariables={
              queryDetectionsPaginatedVariables
            }
          />
        )}
        {popoverType === 'addNote' && (
          <AddNotePopover
            onClose={onClose}
            detectionData={detectionData}
            refetchMain={true}
          />
        )}
      </Popover>
    </VStack>
  );
};
