import { useToast } from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  GET_DETECTIONS,
  QUERY_DETECTIONS_PAGINATED,
  useAssignDetections,
  useListUsers,
} from '@gamma/investigator/queries';
import { ActionPopoverContent } from '@gamma/overlay';
import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DetectionQueryVariables,
  SantizedDetection,
} from '../../../../Detections';
import { filterAssignableUsers } from '../../../../Utils';
import { IGetDetectionVariables } from '../../../DetectionDrawer';

const { controls, toasts } = i18n.pages.detections;

const { assignUser } = controls;

interface AssignUserContentProps {
  onClose?: () => void;
  refetchMain?: boolean;
  detectionData?: Detection | SantizedDetection;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
}

export const AssignUserContent = ({
  onClose,
  refetchMain,
  isLoading,
  setIsLoading,
  detectionData,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
}: AssignUserContentProps) => {
  const showToastMessage = useToast();
  const { user } = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const { assignment_info, detection_id, update_info } = detectionData || {};

  const {
    loading: listUsersLoading,
    error: listUsersError,
    data: { listUsers = [] } = {},
  } = useListUsers({
    skip: !user?.attributes['custom:tenant_id'],
    variables: {
      tenants: [`${user?.attributes['custom:tenant_id']}`],
      groups: ['admin', 'analyst'],
    },
  });

  const refetchQueries = [
    {
      query: GET_DETECTIONS,
      variables: getDetectionVariables,
    },
  ];

  const [assignDetections, { error: assignError }] = useAssignDetections({
    awaitRefetchQueries: true,
    refetchQueries: !queryDetectionsPaginatedVariables
      ? refetchQueries
      : [
          ...refetchQueries,
          {
            query: QUERY_DETECTIONS_PAGINATED,
            variables: queryDetectionsPaginatedVariables,
          },
        ],
    onCompleted: (data) => {
      setIsLoading && setIsLoading(false);
      showToastMessage({
        status: 'success',
        title: toasts.success,
        description: toasts.assigneeSet,
        isClosable: true,
        position: 'bottom-right',
      });
      onClose && onClose();
      if (refetchMain) {
        setSearchParams((params) => {
          params.set('refetch_main', 'true');
          return params;
        });
      }
    },
    onError: (error) => {
      setIsLoading && setIsLoading(false);
      showToastMessage({
        status: 'error',
        title: toasts.error,
        description: toasts.assigneeNotSet,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const assignableUsers = filterAssignableUsers({ user, users: listUsers });

  return (
    <ActionPopoverContent
      withArrow={false}
      title={assignUser}
      onCancel={onClose}
      isSearchable={true}
      data-testid="assign-detection-popover-content"
      value={assignment_info?.assigned_to_username}
      isLoading={isLoading || listUsersLoading}
      options={assignableUsers}
      onApply={(selectedValue) => {
        setIsLoading && setIsLoading(true);
        assignDetections({
          variables: {
            items: [
              {
                assignment_info: {
                  assigned_to_username: selectedValue || null,
                },
                update_info: {
                  last_updated_timestamp: update_info?.last_updated_timestamp,
                },
                detection_id,
                tenant: user?.attributes['custom:tenant_id'],
              },
            ],
          },
        });
      }}
    />
  );
};
