import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IPollQueryJob } from './types';

export const POLL_QUERY_JOB = gql`
  query pollQueryJob($clientSessionId: String!, $queryJobId: String!) {
    pollQueryJob(clientSessionId: $clientSessionId, queryJobId: $queryJobId) {
      cancelled
      done
      events
    }
  }
`;

export const usePollQueryJob = (
  options?: QueryHookOptions<IPollQueryJob, OperationVariables>,
) => {
  return useQuery<IPollQueryJob>(POLL_QUERY_JOB, options);
};
