import { List, ListIcon, ListItem } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';

const ValidationIcon = ({ isValid }: { isValid: boolean }) => {
  return (
    <ListIcon as={MuiIcon} color={isValid ? 'state.success' : 'state.error'}>
      {isValid ? 'check_circle' : 'do_not_disturb_on'}
    </ListIcon>
  );
};

export interface FieldValidationListProps {
  rules: { isValid: boolean; message: string }[];
}

export const FieldValidationList = ({ rules }: FieldValidationListProps) => {
  return (
    <List spacing={2}>
      {rules.map(({ isValid, message }, index) => (
        <ListItem key={index}>
          <ValidationIcon isValid={isValid} />
          {message}
        </ListItem>
      ))}
    </List>
  );
};
