import {
  ButtonGroup,
  HStack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { MuiIcon } from '@gamma/icons';
import { TimestampCell } from '@gamma/investigator/components';
import { DETECTION_AUTO_CLOSE_DURATION } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { AccordionPanel, FlushPanelContent } from '@gamma/layout';
import { useContext, useEffect, useState } from 'react';
import { CellProps } from 'react-table';

import { ResetDetectionAutoCloseConfig } from '../ResetDetectionAutoCloseConfig';
import { UpdateDetectionAutoCloseConfig } from '../UpdateDetectionAutoCloseConfig';
import {
  AuthContext,
  DetectionAutoCloseConfig,
} from '@gamma/investigator/context';

const { title, tableColumns, descriptionValue, tooltip, defaultDays, days } =
  i18n.pages.detectionSettings;

export const DetectionSettingsDashboard = () => {
  const defaultValues = { duration: '7', updated_by: 'N/A', updated_on: 'N/A' };
  const [detectionAutoCloseConfig, setDetectionAutoCloseConfig] = useState<
    Array<DetectionAutoCloseConfig>
  >([defaultValues]);
  const { userLicense, userPrivileges = {} } = useContext(AuthContext);
  const { tenant = '' } = userLicense ?? {};
  const { detection_auto_close_config = defaultValues } =
    userPrivileges?.[tenant] ?? {};

  useEffect(() => {
    setDetectionAutoCloseConfig([detection_auto_close_config]);
  }, [setDetectionAutoCloseConfig, detection_auto_close_config]);

  const dataTableColumns: any = [
    {
      Header: tableColumns.description,
      disableSortBy: true,
      Cell: () => (
        <HStack>
          <Text>{descriptionValue}</Text>
          <Tooltip label={tooltip} placement="top" hasArrow shouldWrapChildren>
            <MuiIcon color="text.primary" size="sm">
              help
            </MuiIcon>
          </Tooltip>
        </HStack>
      ),
    },
    {
      Header: tableColumns.value,
      disableSortBy: true,
      accessor: 'duration',
      Cell: (props: CellProps<DetectionAutoCloseConfig>) =>
        props.value === DETECTION_AUTO_CLOSE_DURATION.toString() ? (
          <Text>{i18n.formatString(defaultDays, props.value)}</Text>
        ) : (
          <Text>{i18n.formatString(days, props.value)}</Text>
        ),
    },
    {
      Header: tableColumns.modifiedDate,
      disableSortBy: true,
      accessor: 'updated_on',
      Cell: (props: CellProps<DetectionAutoCloseConfig>) =>
        props.value != 'N/A' ? (
          <TimestampCell
            timestamp={props.value}
            isFromNow={false}
            format="ll"
          />
        ) : (
          <Text>N/A</Text>
        ),
    },
    {
      Header: tableColumns.modefiedBy,
      disableSortBy: true,
      accessor: 'updated_by',
      Cell: (props: CellProps<DetectionAutoCloseConfig>) =>
        props.value != 'N/A' ? (
          <Tooltip
            label={props.value}
            placement="top"
            isDisabled={props.value.length <= 20}
          >
            <Tag size="sm" colorScheme="purple" variant="solid">
              <MuiIcon color="text.primary">account_circle</MuiIcon>
              <TagLabel ml={2} isTruncated={props.value.length > 20}>
                {props.value}
              </TagLabel>
            </Tag>
          </Tooltip>
        ) : (
          'N/A'
        ),
    },
    {
      Header: tableColumns.actions,
      Cell: (props: CellProps<DetectionAutoCloseConfig>) => (
        <ButtonGroup spacing={2}>
          <UpdateDetectionAutoCloseConfig
            detectionAutoCloseConfig={detectionAutoCloseConfig}
            tenant={tenant}
          />
          <ResetDetectionAutoCloseConfig
            detectionAutoCloseDuration={props.row.original.duration}
            tenant={tenant}
          />
        </ButtonGroup>
      ),
    },
  ];

  return (
    <AccordionPanel
      title={title}
      reduceMotion={true}
      storageKey="detection-settings-panel"
      data-testid="detection-settings-panel"
    >
      <FlushPanelContent>
        <DataTable
          data={detectionAutoCloseConfig}
          columns={dataTableColumns}
          isPaginable={false}
          isLined={true}
        />
      </FlushPanelContent>
    </AccordionPanel>
  );
};
