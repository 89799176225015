import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Flex,
  HStack,
  IconButton,
  useDimensions,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FlushPageContent } from '../FlushPageContent';
import { ScrollContext } from '../UIShell';

export interface IBreadcrumb {
  link?: string;
  label: ReactNode;
}

export interface PageHeaderProps {
  children: ReactNode;
  actions?: ((isStuck: boolean) => ReactNode) | ReactNode;
  subHeader?: ReactNode;
  size?: string;
  variant?: string;
  divider?: boolean;
  prevPage?: string;
  breadcrumbs?: IBreadcrumb[];
  isSticky?: boolean;
}

export const PageHeader = ({
  children,
  actions,
  subHeader,
  size,
  variant,
  divider,
  prevPage,
  breadcrumbs,
  isSticky,
}: PageHeaderProps) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { scrollRef } = useContext(ScrollContext);
  const [subNavStickyTop, setSubNavStickyTop] = useState('25px');
  const [isStuck, setIsStuck] = useState<boolean>(false);
  const scrollDims = useDimensions(scrollRef);
  const stickyDims = useDimensions(stickyRef, true);
  const styles = useMultiStyleConfig('PageHeader', {
    size,
    variant,
    divider,
    isSticky,
  });
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const stickyHeight = stickyDims?.borderBox.height ?? 49;
    const scrollPadding = scrollDims?.padding.top ?? 24;
    const top = stickyHeight - scrollPadding;
    setSubNavStickyTop(`${top}px`);
  }, [stickyDims, isStuck]);

  useEffect(() => {
    const onScroll = (event: Event) => {
      const contentHeight = contentRef.current?.offsetHeight ?? 84;
      const pageScrolledPastContent =
        (event.target as HTMLElement).scrollTop > contentHeight - 24;
      setIsStuck(pageScrolledPastContent);
    };
    if (isSticky) {
      scrollRef.current?.addEventListener('scroll', onScroll);
    }

    return () => {
      scrollRef.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  const actionsCB = useCallback(
    (isSticky: boolean) => {
      const isFunc = typeof actions === 'function';

      if (isFunc) {
        return actions(isSticky);
      }
      return actions;
    },
    [actions],
  );

  return (
    <>
      {breadcrumbs && (
        <Box
          __css={styles.breadcrumbs}
          data-testid="page-header-breadcrumbs"
          ref={stickyRef}
        >
          <Flex justifyContent="space-between" w="full">
            <HStack spacing={0}>
              {prevPage && (
                <IconButton
                  size="box-xs"
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="Back"
                  icon={<MuiIcon size="sm">arrow_back</MuiIcon>}
                  mr={1.5}
                  onClick={() => navigate(prevPage)}
                />
              )}
              <Breadcrumb>
                {breadcrumbs.map(({ link, label }, index) => (
                  <BreadcrumbItem
                    key={index}
                    data-testid={`page-header-breadcrumb-${index}`}
                  >
                    {link ? (
                      <BreadcrumbLink as={Link} to={link}>
                        {label}
                      </BreadcrumbLink>
                    ) : (
                      <>{label}</>
                    )}
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            </HStack>
            {isStuck && actions && (
              <Flex __css={styles.actions}>{actionsCB(isStuck)}</Flex>
            )}
          </Flex>
          <Divider mx={-6} pt={2.5} w="calc(100% + var(--chakra-space-12))" />
        </Box>
      )}
      <Flex __css={styles.container} ref={contentRef} pt={breadcrumbs ? 6 : 0}>
        <Flex __css={styles.header}>
          <Box __css={styles.heading} data-testid="page-header-heading">
            {children}
          </Box>
          {!isStuck && actions && (
            <Flex __css={styles.actions}>{actionsCB(isStuck)}</Flex>
          )}
        </Flex>
      </Flex>
      {subHeader && (
        <FlushPageContent
          my={0}
          py={0}
          mb={4}
          h="auto"
          position="sticky"
          top={subNavStickyTop}
          background="layer.0"
          zIndex="sticky"
          data-testid="sticky-content"
        >
          {subHeader}
        </FlushPageContent>
      )}
    </>
  );
};
