import {
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  useColorModeValue,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@gamma/hooks';
import { forwardRef, ReactNode } from 'react';

import { MuiIcon } from '@gamma/icons';
import { Label, LabelProps } from '../Components';

export interface NumberInputProps
  extends Omit<LabelProps, 'children' | 'htmlFor'> {
  /** The name to give the input field */
  name: string;
  /** The text content to display when the input is valueless */
  placeholder?: string;
  /** The default value to start the input with. Does not create a controlled component */
  defaultValue?: ChakraNumberInputProps['defaultValue'];
  /** The value the input should have. Used in conjunction with onChange to create a controlled component */
  value?: ChakraNumberInputProps['value'];
  /** The min number value the input should allow */
  min?: ChakraNumberInputProps['min'];
  /** The ax number value the input should allow */
  max?: ChakraNumberInputProps['max'];
  /** The flag for if the mouse wheel can be used to adjust the value */
  allowMouseWheel?: boolean;
  /** The flag for specifying if the value should get set to the min/max value if outside of the range on blur */
  clampValueOnBlur?: boolean;
  /** The flag for specifying if the number input should have the stepper icons to the side */
  stepper?: boolean;
  /** The element to place inside the input field to the left */
  leftElement?: ReactNode;
  /** The element to place inside the input field to the right. (Note: Replaces stepper) */
  rightElement?: ReactNode;
  /** Node following the Input */
  afterInput?: ReactNode;
  size?: ChakraNumberInputProps['size'];
  /** The function triggered whenever the input value is updated */
  onChange?: ChakraNumberInputProps['onChange'];
  /** The function triggered whenever the input is focused */
  onFocus?: ChakraNumberInputProps['onFocus'];
  /** The function triggered whenever the input is blurred */
  onBlur?: ChakraNumberInputProps['onBlur'];
  /** The data-testid attribute to attach to the input field for testing */
  ['data-testid']?: string;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      rightElement,
      leftElement,
      stepper,
      name,
      onBlur,
      onChange,
      onFocus,
      allowMouseWheel,
      defaultValue,
      value,
      clampValueOnBlur,
      min,
      max,
      placeholder,
      size = 'md',
      'data-testid': dataTestId,
      afterInput,
      ...rest
    },
    ref,
  ) => {
    const breakpointSize = useBreakpointValue(size) ?? 'md';
    const inputElementColor = useColorModeValue('blue.600', 'blue.300');
    return (
      <Label name={name} {...rest}>
        <Grid templateColumns="1fr min-content" alignItems="center">
          <GridItem as={InputGroup}>
            <ChakraNumberInput
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              allowMouseWheel={allowMouseWheel}
              clampValueOnBlur={clampValueOnBlur}
              min={min}
              max={max}
              defaultValue={defaultValue}
              value={value}
              size={size}
            >
              {leftElement && (
                <InputLeftElement color={inputElementColor}>
                  {leftElement}
                </InputLeftElement>
              )}
              <NumberInputField
                ref={ref}
                data-testid={dataTestId}
                placeholder={placeholder}
              />
              {rightElement && (
                <InputRightElement color={inputElementColor}>
                  {rightElement}
                </InputRightElement>
              )}
              {stepper && (
                <NumberInputStepper>
                  <NumberIncrementStepper data-testid="number-up" flex="0">
                    <MuiIcon size={stepperIconSizeMap.get(breakpointSize)}>
                      arrow_drop_up
                    </MuiIcon>
                  </NumberIncrementStepper>
                  <NumberDecrementStepper data-testid="number-down" flex="0">
                    <MuiIcon size={stepperIconSizeMap.get(breakpointSize)}>
                      arrow_drop_down
                    </MuiIcon>
                  </NumberDecrementStepper>
                </NumberInputStepper>
              )}
            </ChakraNumberInput>
          </GridItem>
          {afterInput && <GridItem pl={4}>{afterInput}</GridItem>}
        </Grid>
      </Label>
    );
  },
);

const stepperIconSizeMap = new Map<string, 'xs' | 'sm' | 'md' | 'lg'>([
  ['xs', 'xs'],
  ['sm', 'xs'],
  ['md', 'sm'],
  ['lg', 'sm'],
]);
