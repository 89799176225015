import {
  Button,
  ButtonGroup,
  Code,
  HStack,
  Link,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column, Panel } from '@gamma/layout';
import { Modal } from '@gamma/overlay';

const { logscaleDetails } = i18n.pages.detections;

export interface LogscaleDetailsModalProps {
  alertsName: string;
  alertsTimestamp: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  onOpen?: () => void;
  data: Record<string, any>;
  title: string;
  showBackToLogFlag?: boolean;
}

export const LogscaleDetailsModal = ({
  alertsName,
  alertsTimestamp,
  isModalOpen,
  onModalClose,
  onOpen,
  data,
  title,
  showBackToLogFlag,
}: LogscaleDetailsModalProps) => {
  return (
    <Modal
      size="xl"
      isOpen={isModalOpen}
      onClose={onModalClose}
      title={`${alertsName} | ${alertsTimestamp}`}
      body={
        <Board padding={3} gap={2} flexDirection="column">
          {showBackToLogFlag && (
            <Column display="flex" alignItems="center" gap={2} p={0}>
              <Button
                variant="ghost"
                size="xs"
                px={0}
                onClick={() => {
                  onModalClose();
                  onOpen && onOpen();
                }}
              >
                <MuiIcon>arrow_back</MuiIcon>
              </Button>
              <Link
                color="text.link"
                data-testid="connection-see-more-button"
                onClick={() => {
                  onModalClose();
                  onOpen && onOpen();
                }}
              >
                {logscaleDetails.backToLog}
              </Link>
            </Column>
          )}
          <Text>
            {title} Raw String | {alertsTimestamp}
          </Text>
          <Panel layerStyle="primary" padding={0}>
            <Code bg="layer.0" overflow="scroll" padding={3} width="inherit">
              <UnorderedList styleType="none" margin={0}>
                <Text>{'{'}</Text>
                {data &&
                  Object.entries(data).map(([key, value]) => (
                    <HStack key={key} alignItems="baseline" pl={3}>
                      <Text color="blue.300">{JSON.stringify(key)}: </Text>
                      <Text color="green.300">{JSON.stringify(value)}</Text>
                    </HStack>
                  ))}
                <Text>{'}'}</Text>
              </UnorderedList>
            </Code>
          </Panel>
        </Board>
      }
      footer={
        <ButtonGroup>
          <Button variant="solid" colorScheme="gray" onClick={onModalClose}>
            {logscaleDetails.close}
          </Button>
        </ButtonGroup>
      }
    />
  );
};
