import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';

import { textStyles } from '../../common';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'header',
  'heading',
  'actions',
  'breadcrumbs',
]);

export const pageHeaderStyles: ComponentStyleConfig =
  helpers.defineMultiStyleConfig({
    baseStyle: (props) => {
      return {
        container: {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexDirection: 'column',
          w: 'full',
          pt: 6,
          ...(props.divider
            ? {
                borderBottom: 'solid 1px',
                borderColor: 'border.layer.1',
              }
            : {}),
        },
        breadcrumbs: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          w: 'full',
          pt: 2.5,
          top: -6,
          mt: -6,
          // borderBottom: 'solid 1px',
          // borderColor: 'border.layer.1',
          ...(props.isSticky
            ? {
                flexDir: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                bgColor: 'layer.0',
                zIndex: 'sticky',
                position: 'sticky',
                top: -6,
                mt: -6,
                pb: 0,
              }
            : {}),
        },
        header: {
          display: 'flex',
          flexDirection: 'row',
          w: 'full',
          py: 6,
        },
        heading: {
          ...textStyles['h3'],
        },
        actions: {
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
        },
      };
    },
    sizes: {
      sm: {
        header: { py: 5 },
        heading: {
          ...textStyles['h4'],
        },
      },
      lg: {
        header: {
          py: 6,
        },
        heading: {
          ...textStyles['h3'],
        },
      },
    },
    variants: {
      stacked: (props) => {
        return {
          header: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
          heading: {
            w: 'full',
          },
          actions: {
            w: 'full',
            mt: 6,
            justifyContent: 'flex-start',
          },
        };
      },
      'stacked-flushed': {
        container: {
          mt: -6,
          mx: -6,
          px: 6,
          w: 'calc(100% + var(--chakra-space-12))',
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        breadcrumbs: {
          mx: -6,
          px: 6,
          w: 'calc(100% + var(--chakra-space-12))',
        },
        header: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        heading: {
          w: 'full',
        },
        actions: {
          w: 'full',
          mt: 6,
          justifyContent: 'flex-start',
        },
      },
      flushed: (props) => ({
        container: {
          ...(props.isSticky || !props.breadcrumbs
            ? undefined
            : {
                '&&': {
                  pt: 6,
                },
              }),
          mx: 0,
          px: 0,
        },
        header: {
          pt: 0,
        },
        breadcrumbs: {
          mx: -6,
          px: 6,
          w: 'calc(100% + var(--chakra-space-12))',
        },
      }),
    },
    defaultProps: {
      size: 'lg',
    },
  });
