import { Box, Flex, Text } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Select } from '@gamma/form-fields';
import { i18n } from '@gamma/investigator/localization';
import { EntityOption } from '@gamma/investigator/pages/detections';
import { Tab, Tabs } from '@gamma/tabs';
import { SingleValue } from 'chakra-react-select';
import { useEffect } from 'react';

const { detection } = i18n.pages.detections;

const { source, destination } = detection;

interface DetectionDrawerEntityHeaderProps {
  activeEntityType: string;
  setActiveEntityType: React.Dispatch<React.SetStateAction<string>>;
  sourceOptions: EntityOption[];
  destinationOptions: EntityOption[];
  totalSourceEntities?: number;
  totalDestinationEntities?: number;
  handleSrcEntityChange: (event: SingleValue<EntityOption>) => void;
  handleDestEntityChange: (event: SingleValue<EntityOption>) => void;
  selectedSrcOption: EntityOption | null;
  selectedDestOption: EntityOption | null;
}

export const DetectionDrawerEntityHeader = ({
  activeEntityType,
  setActiveEntityType,
  sourceOptions,
  destinationOptions,
  selectedSrcOption,
  selectedDestOption,
  handleSrcEntityChange,
  handleDestEntityChange,
  totalSourceEntities,
  totalDestinationEntities,
}: DetectionDrawerEntityHeaderProps) => {
  const tabSelectedStyle = { backgroundColor: 'blue.400' };
  const hasDestination = Boolean(destinationOptions[0].label);
  const hasSource = Boolean(sourceOptions[0].label);
  const numberOfDestinations = hasDestination ? destinationOptions?.length : 0;
  const numberOfSources = hasSource ? sourceOptions?.length : 0;
  const tabIndex: Record<string, number> = { source: 0, destination: 1 };

  useEffect(() => {
    if (!hasSource && !hasDestination) return;
    if (activeEntityType === 'source' && !hasSource) {
      setActiveEntityType('destination');
    } else if (activeEntityType === 'destination' && !hasDestination) {
      setActiveEntityType('source');
    }
  }, [activeEntityType, setActiveEntityType, hasSource, hasDestination]);

  return (
    <>
      <Box width="100%">
        <Tabs size="lg" isFitted index={tabIndex[activeEntityType]}>
          <Tab
            titleText={source}
            title={
              <>
                <MuiIcon>crisis_alert</MuiIcon>
                &nbsp;
                <Text textTransform="uppercase">
                  {`${source}${
                    numberOfSources !== 1 && totalSourceEntities
                      ? ` (${numberOfSources}${totalSourceEntities > numberOfSources ? ` of ${totalSourceEntities}` : ''})`
                      : ''
                  }`}
                </Text>
              </>
            }
            _selected={tabSelectedStyle}
            onClick={() => setActiveEntityType('source')}
            isDisabled={!hasSource}
          ></Tab>
          <Tab
            titleText={
              totalDestinationEntities &&
              totalDestinationEntities > numberOfDestinations
                ? 'Dest.'
                : destination
            }
            title={
              <>
                <MuiIcon>location_on</MuiIcon>
                &nbsp;
                <Text textTransform="uppercase">
                  {`${
                    totalDestinationEntities &&
                    totalDestinationEntities > numberOfDestinations
                      ? 'Dest.'
                      : destination
                  }${
                    numberOfDestinations !== 1 && totalDestinationEntities
                      ? ` (${numberOfDestinations}${totalDestinationEntities > numberOfDestinations ? ` of ${totalDestinationEntities}` : ''})`
                      : ''
                  }`}
                </Text>
              </>
            }
            _selected={tabSelectedStyle}
            onClick={() => setActiveEntityType('destination')}
            isDisabled={!hasDestination}
          ></Tab>
        </Tabs>
      </Box>
      {activeEntityType === 'source' &&
        sourceOptions?.length &&
        sourceOptions?.length > 0 && (
          <Flex alignItems="center" w="100%">
            <Box w="120px" mr={2}>
              <Text
                color="text.300"
                data-testid="entity-panel-entity-type-label"
                textStyle="body-md"
              >
                {source}
              </Text>
            </Box>
            <Box flexGrow={1}>
              {sourceOptions.length === 1 ? (
                <Text>{sourceOptions[0].label}</Text>
              ) : (
                <Select
                  size="sm"
                  label="Entity"
                  name={'entity'}
                  isMulti={false}
                  isLabelHidden={true}
                  options={sourceOptions}
                  value={selectedSrcOption}
                  data-testid="detection-entity"
                  onChange={(event) => {
                    handleSrcEntityChange(event);
                  }}
                />
              )}
            </Box>
          </Flex>
        )}
      {activeEntityType === 'destination' &&
        destinationOptions?.length &&
        destinationOptions?.length > 0 && (
          <Flex alignItems="center" w="100%">
            <Box w="120px" mr={2}>
              <Text
                color="text.300"
                data-testid="entity-panel-entity-type-label"
                textStyle="body-md"
              >
                {destination}
              </Text>
            </Box>
            <Box flexGrow={1}>
              {destinationOptions.length === 1 ? (
                <Text>{destinationOptions[0].label}</Text>
              ) : (
                <Select
                  size="sm"
                  label="Entity"
                  name={'entity'}
                  isMulti={false}
                  isLabelHidden={true}
                  options={destinationOptions}
                  value={selectedDestOption}
                  data-testid="detection-entity"
                  onChange={(event) => {
                    handleDestEntityChange(event);
                  }}
                />
              )}
            </Box>
          </Flex>
        )}
    </>
  );
};
