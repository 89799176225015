import {
  Box,
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  Divider,
  HStack,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ResponsiveValue,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@gamma/hooks';
import { MuiIcon } from '@gamma/icons';
import {
  Children,
  ReactChild,
  ReactElement,
  ReactNode,
  cloneElement,
} from 'react';

export interface ModalProps {
  /** The `id` of the modal */
  id?: string;
  /** The title content for the modal */
  title: ReactNode;
  /** the icon name to be place beside title content */
  titleIcon?: string;
  /** The subtitle content for the modal, centered directly underneath the title */
  subtitle?: ReactNode;
  /** The body content for the modal */
  body: ReactNode;
  /** The footer content for the modal */
  footer?: ReactNode;
  /** The trigger element for the modal */
  children?: ReactNode;
  /** If `true`, the modal will be open */
  isOpen?: boolean;
  /** If `true` the modal will be in the center of the window */
  isCentered?: ChakraModalProps['isCentered'];
  /**
   * The width of the modal when open
   * @default sm
   */
  size?: ResponsiveValue<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'>;
  /**
   * If `true`, the modal will close when the overlay is clicked
   * @default true
   */
  closeOnOverlayClick?: ChakraModalProps['closeOnOverlayClick'];
  /**
   * If `true`, the modal will close when the `Esc` key is pressed
   * @default true
   */
  closeOnEsc?: ChakraModalProps['closeOnEsc'];
  /**
   * If `true`, the close button will be disabled
   * @default false
   */
  isCloseDisabled?: boolean;
  /**
   * If `false`, focus lock will be disabled completely.
   *
   * This is useful in situations where you still need to interact with
   * other surrounding elements.
   *
   * 🚨Warning: We don't recommend doing this because it hurts the
   * accessibility of the modal, based on WAI-ARIA specifications.
   *
   * @default true
   */
  trapFocus?: ChakraModalProps['trapFocus'];
  /**
   * If `true`, the modal will autofocus the first enabled and interactive
   * element within the `ModalContent`
   *
   * @default true
   */
  autoFocus?: ChakraModalProps['autoFocus'];
  /**
   * The `ref` of element to receive focus when the modal opens.
   */
  initialFocusRef?: ChakraModalProps['initialFocusRef'];
  /**
   * The `ref` of element to receive focus when the modal closes.
   */
  finalFocusRef?: ChakraModalProps['finalFocusRef'];
  /**
   * If `true`, the modal will return focus to the element that triggered it when it closes.
   * @default true
   */
  returnFocusOnClose?: ChakraModalProps['returnFocusOnClose'];
  /**
   * Handle zoom/pinch gestures on iOS devices when scroll locking is enabled.
   * Defaults to `true`.
   */
  allowPinchZoom?: boolean;
  /**
   * If `true`, a `padding-right` will be applied to the body element
   * that's equal to the width of the scrollbar.
   *
   * This can help prevent some unpleasant flickering effect
   * and content adjustment when the modal opens
   */
  preserveScrollBarGap?: boolean;
  /**
   * Overflow scrolling behavior: 'inside' or 'outside' the modal contents.
   * @default 'outside'
   */
  scrollBehavior?: ChakraModalProps['scrollBehavior'];
  /**
   * Callback fired when the overlay is clicked.
   */

  hideBody?: boolean;
  /**
   * the flag for if content divider lines between header, body, and footer should be visible
   */
  hideDivider?: boolean;
  /** If `true` the body and its wrapper will be removed */
  onOverlayClick?: ChakraModalProps['onOverlayClick'];
  /**
   * Callback fired when the escape key is pressed and focus is within modal
   */
  onEsc?: ChakraModalProps['onEsc'];
  /**
   * Callback invoked to open the modal.
   */
  onOpen?: () => void;
  /**
   * Callback invoked to close the modal.
   */
  onClose?: ChakraModalProps['onClose'];
  afterHeader?: ReactNode;
  afterFooter?: ReactChild;
  beforeFooter?: ReactNode;
  ['data-testid']?: string;
}

export const Modal = ({
  children,
  title,
  subtitle,
  titleIcon,
  size,
  body,
  afterHeader,
  footer,
  afterFooter,
  beforeFooter,
  isCloseDisabled = false,
  isOpen: propsIsOpen,
  onClose: propsOnClose,
  onOpen: propsOnOpen,
  scrollBehavior = 'inside',
  hideBody = false,
  hideDivider = false,
  'data-testid': dataTestId = 'gamma-modal',
  ...rest
}: ModalProps) => {
  const { isOpen, onClose, getButtonProps } = useDisclosure({
    isOpen: propsIsOpen,
    onClose: propsOnClose,
    onOpen: propsOnOpen,
  });

  const breakpointSize = useBreakpointValue(size ?? 'sm');

  const footerBgColor = useColorModeValue('gray.50', 'gray.800');

  const child = children && (Children.only(children) as ReactElement);

  const trigger = child && cloneElement(child, getButtonProps(child.props));

  return (
    <>
      {trigger}
      <ChakraModal
        {...rest}
        allowPinchZoom={rest.allowPinchZoom ?? true}
        size={breakpointSize}
        scrollBehavior={scrollBehavior}
        isOpen={isOpen}
        onClose={onClose}
        motionPreset={
          process.env.JEST_WORKER_ID !== undefined ? 'none' : undefined
        }
      >
        <ModalOverlay data-testid={`${dataTestId}-overlay`} />
        <ModalContent data-testid={dataTestId}>
          <ModalHeader pb={hideBody ? 8 : !hideDivider ? 0 : undefined}>
            <ModalCloseButton
              isDisabled={isCloseDisabled}
              data-testid={`${dataTestId}-close-button`}
            >
              <MuiIcon>close</MuiIcon>
            </ModalCloseButton>
            <Stack spacing={1}>
              <HStack>
                {titleIcon && <MuiIcon size="lg">{titleIcon}</MuiIcon>}
                <Heading
                  textStyle="h4"
                  fontWeight="normal"
                  data-testid={`${dataTestId}-title`}
                >
                  {title}
                </Heading>
              </HStack>
              {subtitle && (
                <Text
                  data-testid={`${dataTestId}-subtitle`}
                  textStyle="body-md"
                  color="text.secondary"
                >
                  {subtitle}
                </Text>
              )}
            </Stack>
            {!hideDivider && (
              <Divider
                pb={0}
                pt={4}
                mx={-4}
                w="calc(100% + var(--chakra-space-8))"
              />
            )}
          </ModalHeader>
          {afterHeader && (
            <Box bg={footerBgColor} data-testid={`${dataTestId}-after-header`}>
              {afterHeader}
            </Box>
          )}
          {!hideBody && (
            <ModalBody
              data-testid={`${dataTestId}-body`}
              overflow={afterFooter ? 'none' : 'auto'}
            >
              {body}
            </ModalBody>
          )}
          {beforeFooter && (
            <Box bg={footerBgColor} data-testid={`${dataTestId}-before-footer`}>
              {beforeFooter}
            </Box>
          )}
          {footer && (
            <ModalFooter
              data-testid={`${dataTestId}-footer`}
              pt={!hideDivider ? 0 : undefined}
            >
              {!hideDivider && (
                <Divider
                  pt={0}
                  mb={4}
                  mx={-4}
                  w="calc(100% + var(--chakra-space-8))"
                />
              )}
              {footer}
            </ModalFooter>
          )}
          {afterFooter && (
            <Box bg={footerBgColor} data-testid={`${dataTestId}-after-footer`}>
              {afterFooter}
            </Box>
          )}
        </ModalContent>
      </ChakraModal>
    </>
  );
};
