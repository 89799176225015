import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Center,
  Heading,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Input, InputProps } from '@gamma/form-fields';
import { useControlledProp } from '@gamma/hooks';
import { MuiIcon } from '@gamma/icons';
import { PERFORMANCE_TIMEOUT } from '@gamma/theme';
import Fuse from 'fuse.js';
import {
  ChangeEvent,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface ActionPopoverOption {
  value: string;
  label: string;
}
export interface ActionPopoverContentProps {
  options: ActionPopoverOption[];
  title: ReactNode;
  subtitle?: ReactNode;
  noOptionsMessage?: ReactNode;
  value?: string;
  isLoading?: boolean;
  withArrow?: boolean;
  isSearchable?: boolean;
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
  applyButtonProps?: Omit<ButtonProps, 'onClick'>;
  cancelText?: ReactNode;
  applyText?: ReactNode;
  searchInputProps?: InputProps;
  footer?: ReactNode;
  onCancel?: () => void;
  onApply: (value: string) => void;
  searchRef?: RefObject<HTMLInputElement>;
  radioRef?: RefObject<HTMLInputElement>;
  ['data-testid']?: string;
}
const keys = ['value', 'label'];
export const ActionPopoverContent = ({
  title,
  subtitle,
  value,
  isLoading,
  options,
  withArrow = true,
  isSearchable,
  cancelButtonProps,
  cancelText = 'Cancel',
  applyButtonProps,
  applyText = 'Apply',
  searchInputProps,
  footer,
  noOptionsMessage = 'No Options Found',
  onCancel,
  onApply,
  searchRef,
  radioRef,
  'data-testid': dataTestId = 'action-popover-content',
}: ActionPopoverContentProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [search, setSearch] = useState<string>('');
  const [filteredOptions, setFilteredOptions] =
    useState<ActionPopoverOption[]>(options);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    value ?? undefined,
  );
  const fuse = useRef(new Fuse<ActionPopoverOption>(options, { keys }));
  useControlledProp(value, setSelectedValue);
  // useControlledProp(options, setFilteredOptions);
  const isDirty = useMemo(() => {
    if (selectedValue !== undefined && value !== selectedValue) {
      return true;
    }
    return false;
  }, [value, selectedValue]);
  const handleCancelClick = () => {
    onCancel?.();
  };
  const handleSelection = (value: string) => {
    setSelectedValue(value);
  };
  const handleApplyClick = useCallback(() => {
    if (selectedValue !== undefined) {
      onApply(selectedValue);
    }
  }, [onApply, selectedValue]);

  useEffect(() => {
    fuse.current = new Fuse(options, { keys });
  }, [options]);
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setSearch(event.target.value ?? '');
    }, PERFORMANCE_TIMEOUT);
  };
  useEffect(() => {
    if (search) {
      const result = fuse.current.search(search);
      const _filteredOptions = result.map(({ item }) => {
        return item;
      });
      setFilteredOptions(_filteredOptions);
    } else {
      setFilteredOptions(options);
    }
  }, [options, search]);
  return (
    <Portal>
      <PopoverContent minW="320px" data-testid={dataTestId}>
        {withArrow && <PopoverArrow />}
        <PopoverCloseButton top={2}>
          <MuiIcon size="sm">close</MuiIcon>
        </PopoverCloseButton>
        <PopoverHeader>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Heading textStyle="body-md-bold">{title}</Heading>
              {subtitle && <Text textStyle="body-sm">{subtitle}</Text>}
            </Stack>
            {isSearchable && (
              <Input
                name="search"
                label="Search"
                placeholder="Search"
                leftElement={<MuiIcon>search</MuiIcon>}
                onChange={handleSearchChange}
                isLabelHidden
                {...searchInputProps}
                ref={searchRef}
              />
            )}
          </Stack>
        </PopoverHeader>
        <PopoverBody maxH="155px" overflowY="auto" position="relative">
          {isLoading ? (
            <Center h="155px">
              <Spinner color="blue.500" />
            </Center>
          ) : filteredOptions.length > 0 ? (
            <RadioGroup
              onChange={handleSelection}
              value={selectedValue}
              data-testid="action-popover-options"
              maxH="100%"
            >
              <Stack>
                {filteredOptions.map(({ value, label }, index) => {
                  return (
                    <Radio
                      value={value}
                      ref={index === 0 ? radioRef : undefined}
                      key={value}
                    >
                      {label}
                    </Radio>
                  );
                })}
              </Stack>
            </RadioGroup>
          ) : (
            <Center>{noOptionsMessage}</Center>
          )}
        </PopoverBody>
        <PopoverFooter
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>{footer}</Box>
          <ButtonGroup variant="solid" size="md">
            <Button
              colorScheme="gray"
              onClick={handleCancelClick}
              {...cancelButtonProps}
            >
              {cancelText}
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleApplyClick}
              data-testid="action-popover-apply"
              isDisabled={!isDirty || isLoading}
              {...applyButtonProps}
            >
              {applyText}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Portal>
  );
};
