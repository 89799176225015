import { Box, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { ChatGPTPromptCloudPanel } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import {
  CrowdStrikeConfiguration,
  Detection,
  useAnalystGetEDRConfiguration,
  useGetAlertMetadata,
  useGetAlertMetadataSuricataRuleDescription,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '@gamma/investigator/context';
import { DetectionDetailsGrid } from '../../Components/DetectionDetailsGrid';
import { MitreTechniquesGrid } from '../../Components/MitreTechniquesGrid';
import { DetectionQueryVariables, SantizedDetection } from '../../Detections';
import {
  DetectionDrawerEntityEDRPanel,
  DetectionDrawerEntityPanel,
  SummaryPanel,
} from './Panels';

const { detection: detectionText } = i18n.pages.detections;
const { chatGPT } = i18n.pages.integrations;

interface DetectionDrawerProps {
  isRelated?: boolean;
  disableBorders?: boolean;
  item: Detection | SantizedDetection | undefined;
  setDetectionData?: React.Dispatch<
    React.SetStateAction<Detection | SantizedDetection | undefined>
  >;
  queryDetectionsPaginatedVariables: DetectionQueryVariables;
}

export const DetectionDrawer = ({
  item,
  isRelated,
  setDetectionData,
  disableBorders = false,
  queryDetectionsPaginatedVariables,
}: DetectionDrawerProps) => {
  const { userPrivileges } = useContext(AuthContext);

  const disclosure = useDisclosure();
  const [panelDescription, setPanelDescription] = useState<string>('');

  const { detection_id, tenant_info } = item || {};
  const { tenant_id = '' } = tenant_info || {};

  const isGPTEnabled = Boolean(userPrivileges?.[tenant_id]?.chatgpt);
  const isCustomSeverityScore = item?.rank?.is_custom_severity;

  const { alertCategory, significance, mitreTechniques } = detectionText;

  const getDetectionVariables = {
    detection_ids: [
      {
        detection_id,
        tenant: tenant_info?.tenant_id,
      },
    ],
  };

  const {
    rank,
    alert_info,
    update_info,
    assignment_info,
    detection_status,
    total_alert_count,
    earliest_start_timestamp,
    latest_start_timestamp,
  } = item || {};

  const {
    loading: metadataLoading,
    data: metadataData,
    error: metadataError,
  } = useGetAlertMetadata({
    skip: !tenant_info?.tenant_id || !alert_info?.content_id,
    variables: {
      items: [
        {
          content_id: alert_info?.content_id,
          tenant: tenant_info?.tenant_id,
        },
      ],
    },
  });

  const {
    description,
    content_doc,
    mitre_mappings: metadata_mitre_mappings,
    rule,
  } = metadataData?.getAlertMetadata?.metadata[0] || {};

  const {
    data: suricataAlertDescriptionData,
    loading: suricataAlertDescriptionLoading,
    error: suricataAlertDescriptionError,
  } = useGetAlertMetadataSuricataRuleDescription({
    skip:
      !isGPTEnabled ||
      !tenant_info?.tenant_id ||
      !alert_info?.content_id ||
      metadataLoading ||
      alert_info?.alert_type !== 'suricata_corelight',
    variables: {
      items: [
        {
          content_id: alert_info?.content_id,
          tenant: tenant_info?.tenant_id,
        },
      ],
    },
  });

  const {
    loading: edrConfigLoading,
    error: edrConfigError,
    data: edrConfigData,
  } = useAnalystGetEDRConfiguration({
    variables: {
      provider_name: 'crowdstrike',
      tenant: tenant_info?.tenant_id,
    },
  });

  const edrConfig =
    edrConfigData?.getEDRConfiguration as CrowdStrikeConfiguration;

  useEffect(() => {
    if (
      alert_info?.alert_type === 'suricata_corelight' &&
      suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]
        ?.chatbot_chats?.suri_describe_rule
    ) {
      setPanelDescription(
        suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]
          ?.chatbot_chats?.suri_describe_rule ?? '',
      );
    }

    if (alert_info?.alert_type !== 'suricata_corelight' && description) {
      setPanelDescription(description ?? '');
    }
  }, [
    suricataAlertDescriptionData,
    metadataData,
    alert_info?.alert_type,
    description,
  ]);

  useEffect(() => {
    if (detection_id) {
      disclosure.onOpen();
    }
  }, [detection_id, disclosure]);

  useEffect(() => {
    if (setDetectionData) {
      setDetectionData(item);
    }
  }, [item, setDetectionData]);

  const hasMetaContent = !!(description || content_doc?.significance);

  const isLoading = metadataLoading || edrConfigLoading;

  const showLeft = Boolean(
    (alert_info?.alert_type === 'suricata_corelight' && rule) ||
      (alert_info?.alert_type !== 'suricata_corelight' && description) ||
      (hasMetaContent && content_doc?.significance),
  );

  const sourceEntities = item?.paginated_source_entities?.alert_entities;
  const totalSourceEntities = item?.paginated_source_entities?.total_items;
  const destinationEntities =
    item?.paginated_destination_entities?.alert_entities;
  const totalDestinationEntities =
    item?.paginated_destination_entities?.total_items;

  return (
    <Box w="100%">
      {isLoading || metadataError || edrConfigError ? (
        <GraphQLReqStatus
          loading={isLoading}
          error={metadataError || edrConfigError}
        />
      ) : (
        <VStack width="100%" spacing={!disableBorders ? 2 : 0}>
          {/* hide the left column if notice because there's no content to display */}
          {showLeft && (
            <VStack
              w="100%"
              spacing={!disableBorders ? 2 : 0}
              divider={
                disableBorders ? (
                  <Box h="1px" w="100%" backgroundColor="border.layer.2" />
                ) : undefined
              }
              borderBottomWidth={disableBorders ? '1px' : undefined}
              borderBottomColor={disableBorders ? 'border.layer.2' : undefined}
            >
              {/* Ask GPT for suricata alerts */}
              {alert_info?.alert_type === 'suricata_corelight' && rule && (
                <ChatGPTPromptCloudPanel
                  isAccordion={true}
                  isDescriptionQuickView={true}
                  titleText={chatGPT.description}
                  disableBorders={disableBorders}
                  descriptionText={panelDescription}
                  isLoadingDescription={
                    !panelDescription && suricataAlertDescriptionLoading
                  }
                  isGPTEnabled={Boolean(userPrivileges?.[tenant_id]?.chatgpt)}
                  loadingDescriptionError={suricataAlertDescriptionError}
                  storageKey="gpt-prompt-panel-suri-details"
                  showGPTDisabled={true}
                />
              )}
              {alert_info?.alert_type !== 'suricata_corelight' &&
                description && (
                  <AccordionPanel
                    w="100%"
                    layerStyle="first"
                    reduceMotion={true}
                    title={chatGPT.description}
                    disableBorders={disableBorders}
                    storageKey="gpt-prompt-cloud-panel"
                  >
                    <Text
                      data-testid="detection-description"
                      textStyle="body-md"
                    >
                      {description}
                    </Text>
                  </AccordionPanel>
                )}
              {hasMetaContent && content_doc?.significance && (
                <AccordionPanel
                  w="100%"
                  layerStyle="first"
                  reduceMotion={true}
                  title={significance}
                  disableBorders={disableBorders}
                  storageKey="detection-significance-panel"
                >
                  <Text
                    data-testid="detection-significance"
                    textStyle="body-md"
                  >
                    {content_doc?.significance}
                  </Text>
                </AccordionPanel>
              )}
            </VStack>
          )}
          <VStack
            w="100%"
            spacing={!disableBorders ? 2 : 0}
            divider={
              disableBorders ? (
                <Box h="1px" w="100%" backgroundColor="border.layer.2" />
              ) : undefined
            }
            borderBottomWidth={disableBorders ? '1px' : undefined}
            borderBottomColor={disableBorders ? 'border.layer.2' : undefined}
          >
            {item && assignment_info && detection_id && update_info && (
              <SummaryPanel
                detectionInfo={item}
                refetchMain={!!isRelated}
                disableBorders={disableBorders}
                assignmentInfo={assignment_info}
                detectionStatus={detection_status}
                getDetectionVariables={getDetectionVariables}
                queryDetectionsPaginatedVariables={
                  queryDetectionsPaginatedVariables
                }
                total_alert_count={total_alert_count}
                earliest_start_timestamp={earliest_start_timestamp}
                latest_start_timestamp={latest_start_timestamp}
              />
            )}
            {edrConfig && edrConfig?.enabled ? (
              <DetectionDrawerEntityEDRPanel
                isRelated={isRelated}
                edrConfig={edrConfig}
                refetchMain={!!isRelated}
                detectionInfo={item}
                disableBorders={disableBorders}
                sourceEntities={sourceEntities}
                totalSourceEntities={totalSourceEntities}
                destinationEntities={destinationEntities}
                totalDestinationEntities={totalDestinationEntities}
              />
            ) : (
              <DetectionDrawerEntityPanel
                refetchMain={!!isRelated}
                detectionInfo={item}
                disableBorders={disableBorders}
                sourceEntities={sourceEntities}
                totalSourceEntities={totalSourceEntities}
                destinationEntities={destinationEntities}
                totalDestinationEntities={totalDestinationEntities}
              />
            )}
            <AccordionPanel
              w="full"
              layerStyle="first"
              reduceMotion={true}
              title={alertCategory}
              disableBorders={disableBorders}
              storageKey="detection-alert-category-panel"
            >
              <DetectionDetailsGrid
                alert_info={alert_info}
                rank={rank}
                isCustomSeverityScore={isCustomSeverityScore}
              />
            </AccordionPanel>
            {!!metadata_mitre_mappings?.tactics?.length && (
              <AccordionPanel
                w="full"
                layerStyle="first"
                reduceMotion={true}
                title={mitreTechniques}
                disableBorders={disableBorders}
                storageKey="detection-mitre-techs-panel"
              >
                <MitreTechniquesGrid
                  tactics={metadata_mitre_mappings?.tactics}
                />
              </AccordionPanel>
            )}
          </VStack>
        </VStack>
      )}
    </Box>
  );
};
