import {
  Heading,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Portal,
} from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { MuiIcon } from '@gamma/icons';
import {
  AddNoteContent,
  AddNoteContentProps,
} from '../../../DetectionNotes/AddNote';

const {
  detection: { addNote },
} = i18n.pages.detections;

export const AddNotePopover = ({
  onClose,
  refetchMain,
  detectionData,
}: AddNoteContentProps) => {
  return (
    <Portal>
      <PopoverContent w="320px" data-testid="add-note-popover-content">
        <PopoverCloseButton top={1}>
          <MuiIcon size="sm">close</MuiIcon>
        </PopoverCloseButton>
        <PopoverHeader>
          <Heading textStyle="body-md-bold">{addNote}</Heading>
        </PopoverHeader>
        <PopoverBody>
          <AddNoteContent
            onClose={onClose}
            detectionData={detectionData}
            refetchMain={refetchMain}
          />
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
};
