import {
  Divider,
  Flex,
  Grid,
  HStack,
  IconButton,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Navbar, Sidebar } from '@gamma/navigation';
import { gammaContext } from '@gamma/theme';
import { motion } from 'framer-motion';
import moment from 'moment';
import {
  ReactNode,
  RefObject,
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SlideDrawerPortalContext } from '../hooks';

export interface UIShellProps {
  /** The component to render as the AppHeader logo */
  logo?: ReactNode;
  /** The Navbar items to use in the AppHeader navigation */
  nav?: ReactNode;
  /** The Sidebar items to use in the Sidebar navigation. If not set, no sidebar will render */
  sidebar?: ReactNode;
  /** ReactNode that sits above the collapse button of the sidebar */
  sidebarFooter?: ReactNode;
  /** App Footer */
  footer?: ReactNode;
  /** The copyright text to put in the app footer */
  copyrightText?: ReactNode;
  /** The flag for controlling sidebar pinning behavior */
  autoCloseSidebar?: boolean;
  /** The app content */
  children: ReactNode;
}

const storageKey = 'gamma-sidebar-expanded';

const getSidebarExpandedState = () => {
  const storageValue = localStorage.getItem(storageKey);
  const noSavedValue = storageValue === null;
  if (noSavedValue) {
    localStorage.setItem(storageKey, 'true');
  }
  return noSavedValue || storageValue === 'true';
};

export const ScrollContext = createContext<{
  scrollRef: RefObject<HTMLDivElement>;
}>({ scrollRef: createRef<HTMLDivElement>() });

export const UIShell = ({
  logo,
  nav,
  sidebar,
  children,
  sidebarFooter,
  footer,
  copyrightText = <DefaultCopy />,
  autoCloseSidebar = true,
}: UIShellProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const slideDrawerPortalRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    autoCloseSidebar ? false : getSidebarExpandedState(),
  );

  useEffect(() => {
    if (!autoCloseSidebar) {
      const storedExpandedState = getSidebarExpandedState();
      setSidebarOpen(Boolean(storedExpandedState));
    }
  }, [autoCloseSidebar]);

  const handleSidebarToggle = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem(storageKey, `${!sidebarOpen}`);
  }, [sidebarOpen]);

  useOutsideClick({
    enabled: autoCloseSidebar,
    ref: sidebarRef,
    handler: (event) => {
      if (
        (event.target as HTMLElement) !== toggleRef.current &&
        !toggleRef.current?.contains(event.target as HTMLElement)
      ) {
        setSidebarOpen(false);
      }
    },
  });

  return (
    <Grid
      gridTemplateAreas="'header header header' 'sidebar main drawer'"
      gridTemplateRows="auto 1fr"
      gridTemplateColumns="auto 1fr auto"
      transition={{ ease: 'easeInOut' }}
      h="100%"
    >
      <Flex
        gridArea="header"
        alignItems="center"
        px="2"
        py="2"
        bg="layer.1"
        borderBottom="solid 1px"
        borderColor="border.layer.1"
        w="100%"
        h="12"
        minH="12"
        data-testid="gamma-appheader"
        position="sticky"
        top="0"
        zIndex={2}
        ref={headerRef}
        boxShadow="sm"
        justifyContent="space-between"
      >
        <HStack spacing={2} h={5}>
          <IconButton
            variant="ghost"
            colorScheme="gray"
            size="box-md"
            aria-label={`${sidebarOpen ? 'Close' : 'Open'} Menu`}
            icon={<MuiIcon>{sidebarOpen ? 'close' : 'menu'}</MuiIcon>}
            onClick={handleSidebarToggle}
            ref={toggleRef}
          />
          {logo}
        </HStack>
        {nav}
      </Flex>
      {sidebar && (
        <MotionFlex
          gridArea="sidebar"
          initial={false}
          animate={{ width: sidebarOpen ? '240px' : '48px' }}
        >
          <Sidebar
            footer={sidebarFooter}
            contentRef={scrollRef}
            isOpen={sidebarOpen}
            autoCloseSidebar={autoCloseSidebar}
            setIsOpen={setSidebarOpen}
            ref={sidebarRef}
          >
            {sidebar}
          </Sidebar>
        </MotionFlex>
      )}
      <ScrollContext.Provider value={{ scrollRef }}>
        <SlideDrawerPortalContext.Provider value={{ slideDrawerPortalRef }}>
          <Flex
            height="100%"
            gridArea="main"
            isolation="isolate"
            flexDirection="column"
            justifyContent="space-between"
            overflowX="hidden"
            overflowY="auto"
            ref={scrollRef}
            tabIndex={0}
            _focus={{ outline: 'none' }}
            p={6}
          >
            {children}
            {footer && (
              <Flex
                mt={5}
                alignItems="center"
                w="100%"
                h="max-content"
                data-testid="gamma-appfooter"
                ref={footerRef}
                as="footer"
              >
                <Text textStyle="body-md">{copyrightText}</Text>
                <Divider
                  borderColor={'gray.500'}
                  mx="4"
                  h={4}
                  orientation="vertical"
                />
                <Navbar style={{ justifyContent: 'flex-start' }}>
                  {footer}
                </Navbar>
              </Flex>
            )}
          </Flex>
          <Grid
            gridArea="drawer"
            ref={slideDrawerPortalRef}
            id="slideDrawerPortal"
            overflowX="hidden"
            overflowY="scroll"
          />
        </SlideDrawerPortalContext.Provider>
      </ScrollContext.Provider>
    </Grid>
  );
};

const MotionFlex = motion(Flex);

const DefaultCopy = () => {
  const currentYear = moment().format('YYYY');
  const { i18n } = useContext(gammaContext);
  return <>&copy; {i18n.formatString(i18n.texts.copyright, currentYear)}</>;
};
