import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DashboardMenu } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';

const { moreDashboard } = i18n.navigation;

export const MoreDashboards = () => {
  const { onOpen: onOpenPopUpMenu } = useDisclosure();

  return (
    <Popover
      trigger="hover"
      placement="bottom-start"
      onOpen={onOpenPopUpMenu}
      closeOnBlur={false}
      data-testid="more-dashboards-menu"
    >
      <PopoverTrigger>
        <Text>{moreDashboard}</Text>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <DashboardMenu />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
