import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useLazyQuery,
} from '@apollo/client';

export interface GetTenantPrivileges {
  getTenantPrivileges: string;
}

export const GET_TENANT_PRIVILEGES = gql`
  query getTenantPrivileges($tenant_id: String) {
    getTenantPrivileges(tenant_id: $tenant_id)
  }
`;

export const useGetTenantPrivilegesLazy = (
  options?: QueryHookOptions<GetTenantPrivileges, OperationVariables>,
) => {
  return useLazyQuery(GET_TENANT_PRIVILEGES, options);
};
