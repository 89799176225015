import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';

import {
  ChatGPTSettingsCard,
  CrowdstrikeSettingsCard,
  ServiceNowSettingsCard,
} from '../General/Components';
import { UserPrivileges } from '@gamma/investigator/context';

const { integrations } = i18n.pages;
const { learnMore, additionalIntegrations, exploreIntegrations } = integrations;

export interface AppConfigProps {
  getAppConfigurationsLoading: boolean;
  getAppConfigurationsData?: string | null;
  tenantPrivileges: UserPrivileges;
}

export const IntegrationsConfig = ({
  getAppConfigurationsData,
  getAppConfigurationsLoading,
  tenantPrivileges,
}: AppConfigProps) => {
  const { chatgpt, chatgpt_private_data } = tenantPrivileges;

  const isLLMSummary = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags.llm_summary
    : false;

  return (
    <VStack spacing={4} alignItems="start">
      <Panel>
        <HStack
          h="200px"
          backgroundSize="535px"
          backgroundPosition="right"
          backgroundRepeat="no-repeat"
          backgroundImage="/assets/images/ig-evidence-based-security.png"
        >
          <VStack ml={6} maxW="550px" alignItems="start">
            <Text textStyle="h4">{additionalIntegrations}</Text>
            <Text mb={2} textStyle="body-md">
              {exploreIntegrations}
            </Text>
            <Button
              as={RouterLink}
              target="_blank"
              variant="solid"
              colorScheme="blue"
              to={`/docs/settings/service-now-settings.html`}
            >
              {learnMore}
            </Button>
          </VStack>
        </HStack>
      </Panel>
      <HStack spacing={4}>
        {isLLMSummary && (
          <ChatGPTSettingsCard
            isPrivateDataCard
            isEnabled={chatgpt_private_data}
          />
        )}
        <ChatGPTSettingsCard isEnabled={chatgpt} />
        <ServiceNowSettingsCard />
        <CrowdstrikeSettingsCard
          getAppConfigurationsLoading={getAppConfigurationsLoading}
        />
      </HStack>
    </VStack>
  );
};
