import {
  InputGroup,
  InputLeftAddon,
  Tag,
  TagCloseButton,
  TagLabel,
  useTheme,
  useToken,
} from '@chakra-ui/react';
import { getColor, transparentize } from '@chakra-ui/theme-tools';
import { usePrevious } from '@gamma/hooks';
import { MuiIcon } from '@gamma/icons';
import {
  ChakraStylesConfig,
  SelectComponentsConfig,
  Size,
  TagVariant,
  chakraComponents,
} from 'chakra-react-select';
import { isArray } from 'lodash';
import {
  FocusEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ActionMeta,
  MenuPlacement,
  OnChangeValue,
  Options,
} from 'react-select';
import { Weekday } from 'rrule';
import { getLabelProps, omitLabelProps } from '../../util';

export type OptionType = {
  [key: string]: any;
  value: string | number | Weekday | boolean;
  label: ReactNode;
  isDisabled?: boolean;
  colorScheme?: string;
  variant?: string;
};

export type SelectOnChange<IsMulti extends boolean, Option = OptionType> = (
  newValue: OnChangeValue<Option, IsMulti>,
  actionMeta?: ActionMeta<Option>,
) => void;

export interface UseSelectParams<
  IsMulti extends boolean,
  Option extends OptionType = OptionType,
> {
  /** The options that the select should contain */
  readonly options: Option[];
  /** Specify if the select is a multi-select */
  isMulti?: IsMulti;
  /** Specify if the select is searchable */
  isSearchable?: boolean;
  /** The id to use for the select input */
  id?: string;
  /** The name to use for the select input */
  name: string;
  /** The text to show when no option is selected */
  placeholder?: string;
  /** The text to show when there are no options available */
  noOptionsMessage?: ReactNode;
  /**
   * The default tag color scheme
   * @default blue
   */
  tagColorScheme?: string;
  /**
   * The default tag variant
   * @default outline
   */
  tagVariant?: TagVariant;
  /** The value that the select should have, used with onChange to create a controlled input */
  value?: Option | Option[] | null;
  /** The default values the select should mount with */
  defaultValue?: Option | Option[];
  /** Function triggered when the select is blurred */
  onBlur?: FocusEventHandler;
  /** Function triggered when the select is focused */
  onFocus?: FocusEventHandler;
  /** Function triggered when the select's value is changed */
  onChange?: SelectOnChange<IsMulti, Option>;
  /** Function triggered when the select's menu is opened */
  onMenuOpen?: () => void;
  /** Function triggered when the select's menu is closed */
  onMenuClose?: () => void;
  /** Shows loading indicator */
  isLoading?: boolean;
  /** Node following the Input */
  afterInput?: ReactNode;
  /** Function to disable options */
  isOptionDisabled?: (option: Option, selectValue: Options<Option>) => boolean;
  /** The width to set the select's grid wrapper to be */
  width?: string;
  /** The min width for the select's grid wrapper */
  minWidth?: string;
  /** Flag for if options menu should render as a portal */
  isMenuPortal?: boolean;
  /** Styles to be passed into ChakraSelect */
  styles?: ChakraStylesConfig<Option, IsMulti, any>;
  /** The background color key to set the input to */
  bg?: string;
  /** The configuration object for setting the select's components */
  components?: SelectComponentsConfig<Option, IsMulti, any>;
  /** Whether to show the select clearing 'x' icon */
  isClearable?: boolean;
  /** The element to place to the left of the select container */
  leftElement?: ReactNode;
  /** the size of the select input */
  size?: Size;
  /** flag for if menu should be closed when item is selected */
  closeMenuOnSelect?: boolean;
  /** flag for if pills for selected value should be shown */
  controlShouldRenderValue?: boolean;
  /**Flag for if selectedo options should be removed from the menu */
  hideSelectedOptions?: boolean;
  /**
   * Flag for if options menu should be re-ordered with selected options
   * on top.
   * **Must be a controlled select** (uses `value` prop)
   */
  selectedOptionsToTop?: boolean;
  /** flag for if multi-selects should combine all the selected value tags into one count */
  combineValueTags?: boolean;
  /** flag for if backspace key should remove last selected value */
  backspaceRemovesValue?: boolean;
  /** The aria-label for the select */
  ['aria-label']?: string;
  ['data-testid']?: string;
}

export const useSelect = <
  IsMulti extends boolean = false,
  Option extends OptionType = OptionType,
  Props extends UseSelectParams<IsMulti, Option> = UseSelectParams<
    IsMulti,
    Option
  >,
>(
  props: Props,
) => {
  const {
    styles = {},
    bg,
    components,
    isClearable,
    width,
    minWidth,
    leftElement,
    name,
    id,
    tagColorScheme,
    afterInput,
    isMenuPortal,
    noOptionsMessage,
    'data-testid': dataTestId,
    size = 'md',
    value,
    defaultValue,
    options,
    selectedOptionsToTop,
    isMulti,
    combineValueTags,
    onMenuClose,
    ...other
  } = props;

  const [bgColor] = useToken('colors', [bg || '']);
  const theme = useTheme();

  const compositeStyles = useMemo<ChakraStylesConfig<Option, IsMulti, any>>(
    () => ({
      ...styles,
      dropdownIndicator: (provided, state) => ({
        ...provided,
        height: dropdownIndicatorSizeHeightMap.get(size),
        bg: 'transparent',
        px: 2,
        ...styles.dropdownIndicator?.(provided, state),
      }),
      placeholder: (provided, state) => ({
        ...provided,
        whiteSpace: 'nowrap',
        ...styles.placeholder?.(provided, state),
      }),
      menu: (provided, state) => ({
        ...provided,
        zIndex: 'dropdown',
        ...styles.menu?.(provided, state),
      }),
      groupHeading: (provided, state) => ({
        ...provided,
        bg: 'transparent',
        px: 3,
        py: 2,
        fontSize: 'sm',
        fontWeight: 'normal',
        color: 'state.disabled',
      }),
      multiValue: (provided, state) => ({
        ...provided,
        ...styles.multiValue?.(provided, state),
        py: 0,
      }),
      control: (provided, state) => {
        return {
          ...provided,
          minH: provided.height ?? 'none',
          height: state.hasValue ? 'auto' : controlSizeHeightMap.get(size),
          alignItems: 'center',
          ...(bg && {
            bg: bgColor,
            bgColor: bgColor,
          }),
          ...styles.control?.(provided, state),
        };
      },
      menuList: (provided, state) => ({
        ...provided,
        minWidth: 'none',
        ...styles.menuList?.(provided, state),
      }),
      valueContainer: (provided, state) => {
        return {
          ...provided,
          px: 2,
          py: 0,
          ...(bg
            ? {
                bg: bgColor,
                bgColor: bgColor,
              }
            : {}),
          ...styles.valueContainer?.(provided, state),
        };
      },
      option: (provided, state) => {
        return {
          ...provided,
          position: 'relative',
          paddingLeft: '36px',
          display: 'flex',
          alignItems: 'center',
          ...styles.option?.(provided, state),
          ...(state.isFocused && {
            bg: transparentize(getColor(theme, 'blue.500'), 0.1)(theme),
          }),
          ...(state.isSelected && {
            bg: transparentize(getColor(theme, 'gray.400'), 0.1)(theme),
            color: 'text.primary',
            _hover: {
              bg: transparentize(getColor(theme, 'blue.500'), 0.1)(theme),
            },
            _after: {
              // MUI font styles
              content: '"check"',
              fontFamily: 'icon',
              fontVariationSettings: "'GRAD' -25, 'opsz' 20",
              textRendering: 'optimizeLegibility',
              MozOsxFontSmoothing: 'grayscale',
              WebkitFontSmoothing: 'antialiased',
              fontSmoothing: 'antialiased',
              display: 'inline-flex',
              lineHeight: '1',
              overflow: 'visible',
              whiteSpace: 'pre',
              cursor: 'inherit',
              textDecoration: 'none !important',
              fontSize: '20px',
              width: '20px',
              height: '20px',
              fontWeight: 'bold',
              // positioning styles
              position: 'absolute',
              // top: '7px',
              left: 2,
            },
          }),
        };
      },
      clearIndicator: (provided, state) => ({
        ...provided,
        height: clearIndicatorSizeHeightMap.get(size),
      }),
      ...(bg && {
        indicatorsContainer: (provided, state) => ({
          ...provided,
          bg: bgColor,
          bgColor: bgColor,
          ...styles.indicatorsContainer?.(provided, state),
        }),
      }),
    }),
    [bg, bgColor, styles, size],
  );

  const customComponents = useMemo<
    SelectComponentsConfig<Option, IsMulti, any>
  >(
    () => ({
      IndicatorSeparator: null,
      // renders the selected value list & input
      ValueContainer: (props) => {
        // props.children[0] is always the values (multi === [{}], single === {})
        // props.children[1] is always the input
        // have to cast as an array because ReactNode _can_ be an array ...
        // but doesn't have to be. In this case it's always an array, so casting
        const [valueChildren, input] = props.children as ReactNode[];
        const values = props.getValue();

        // if the select is a multi-select, has more than one value selected,
        // and the combineValueTags is true, then we should show a single tag
        // for all selected values instead of individuals
        const shouldCombineTags =
          isMulti && values.length > 1 && combineValueTags;

        return (
          <chakraComponents.ValueContainer {...props}>
            {shouldCombineTags ? (
              <Tag colorScheme="blue" variant="solid">
                <TagLabel>{values.length}</TagLabel>
                <TagCloseButton onClick={props.clearValue} />
              </Tag>
            ) : (
              valueChildren
            )}
            {input}
          </chakraComponents.ValueContainer>
        );
      },
      Control: CustomSelectControl({ leftElement, size }),
      ClearIndicator: (props) => (
        <chakraComponents.ClearIndicator {...props}>
          <MuiIcon>close</MuiIcon>
        </chakraComponents.ClearIndicator>
      ),
      ...components,
    }),
    [components, isClearable, leftElement, size],
  );

  const wrapperProps = useMemo(
    () => ({
      ...getLabelProps(other),
      afterInput,
      width,
      minWidth,
      name,
      id,
      'data-testid': dataTestId,
    }),
    [other, afterInput, width, minWidth, name, id],
  );

  /**
   * since react-select & chakra-react-select don't expose a way for us to
   * easily determine what options are selected, we have to go off of the
   * value prop
   */

  const optionsWithSelectedFirst = useMemo(() => {
    const { selectedOptions, unselectedOptions } = options.reduce<{
      selectedOptions: Option[];
      unselectedOptions: Option[];
    }>(
      (result, current) => {
        const isSelected = isArray(value)
          ? value?.some((value) => value.value === current.value)
          : current.value === value?.value;
        if (isSelected) {
          result.selectedOptions.push(current);
        } else {
          result.unselectedOptions.push(current);
        }
        return result;
      },
      { selectedOptions: [], unselectedOptions: [] },
    );
    return [...selectedOptions, ...unselectedOptions];
  }, [value, defaultValue, options, isMulti]);

  const [visibleOptions, setVisibleOptions] = useState<Option[]>(
    selectedOptionsToTop ? optionsWithSelectedFirst : options,
  );

  // update visible options when options list changes (async selects)
  const prevOptions = usePrevious(options);
  useEffect(() => {
    if (prevOptions?.length !== options.length) {
      setVisibleOptions(
        selectedOptionsToTop ? optionsWithSelectedFirst : options,
      );
    }
  }, [prevOptions, options, optionsWithSelectedFirst, selectedOptionsToTop]);

  const selectProps = useMemo(
    () => ({
      isClearable,
      name,
      id,
      colorScheme: tagColorScheme,
      chakraStyles: compositeStyles,
      components: customComponents,
      menuPortalTarget: isMenuPortal ? document.body : undefined,
      classNamePrefix: 'select',
      menuPlacement: 'auto' as MenuPlacement,
      noOptionsMessage: () => noOptionsMessage,
      size,
      value,
      defaultValue,
      isMulti,
      options: visibleOptions,
      onMenuClose: () => {
        onMenuClose?.();
        setVisibleOptions(
          selectedOptionsToTop ? optionsWithSelectedFirst : options,
        );
      },
      ...omitLabelProps(other),
    }),
    [
      isClearable,
      name,
      id,
      tagColorScheme,
      compositeStyles,
      customComponents,
      isMenuPortal,
      other,
      noOptionsMessage,
    ],
  );

  return useMemo(
    () => ({
      selectProps,
      wrapperProps,
    }),
    [selectProps, wrapperProps],
  );
};

export interface CustomSelectControlProps {
  leftElement?: ReactNode;
  size: Size;
}

export const CustomSelectControl =
  ({ leftElement, size }: CustomSelectControlProps) =>
  ({ children, ...rest }: any) => {
    return (
      <chakraComponents.Control {...rest}>
        <InputGroup display="flex" alignItems="center" justifyContent="center">
          {leftElement && (
            <InputLeftAddon
              py={InputAddonPYSizeMap.get(size)}
              bg="transparent"
              pl={2}
              pr={0}
              h="100%"
              border="none"
            >
              {leftElement}
            </InputLeftAddon>
          )}
          {children}
        </InputGroup>
      </chakraComponents.Control>
    );
  };

// i don't like this ... but these components all need to have custom values
// shoved in at specific sizes to ensure that they aren't too tall and forcing
// the select to be larger than a regular input.
const dropdownIndicatorSizeHeightMap = new Map<Size, string>([
  ['sm', '26px'],
  ['md', '30px'],
  ['lg', '34px'],
]);
const controlSizeHeightMap = new Map<Size, string>([
  ['sm', '28px'],
  ['md', '32px'],
  ['lg', '40px'],
]);
const clearIndicatorSizeHeightMap = new Map<Size, string | undefined>([
  ['sm', undefined], // no issue with the component at this size
  ['md', undefined], // no issue with the component at this size
  ['lg', '38px'],
]);
const InputAddonPYSizeMap = new Map<Size, number>([
  ['sm', 0.5],
  ['md', 1],
  ['lg', 2],
]);
