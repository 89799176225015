import {
  Center,
  Divider,
  HStack,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { RadarIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import { formatNumber } from '@gamma/investigator/utilities';
import { Panel } from '@gamma/layout';

interface StatisticPanelProps {
  heading: string;
  iconBgColor: string;
  current?: number;
  previous?: number;
  pct_chg?: number;
  infoLabelTestId?: string;
}

export const StatisticPanel = ({
  heading,
  iconBgColor,
  current,
  previous,
  pct_chg,
  infoLabelTestId,
}: StatisticPanelProps) => {
  const { up, down, includesOpenClosed } =
    i18n.pages.securityOverview.panels.entitiesWithDetections;
  return (
    <Panel>
      <HStack mb={4} spacing={4}>
        <Center bg={iconBgColor} borderRadius={12} boxSize="12">
          <RadarIcon opacity="50%" />
        </Center>
        <Stack spacing={0}>
          <HStack>
            <Text textStyle="h6" fontWeight="normal" data-testid="">
              {heading}
            </Text>
            <Tooltip label={includesOpenClosed} shouldWrapChildren>
              <MuiIcon color="text.secondary" data-testid={infoLabelTestId}>
                info
              </MuiIcon>
            </Tooltip>
          </HStack>
          <Text textStyle="h4" data-testid="statistic-panel-count">
            {formatNumber(current)}
          </Text>
        </Stack>
      </HStack>
      <Divider mb={2} />
      {previous ? (
        <HStack alignItems="center" spacing={2}>
          <MuiIcon color="blue.300">
            {pct_chg && pct_chg > 0 ? 'arrow_upward' : 'arrow_downward'}
          </MuiIcon>
          <Text textStyle="body-md" mb={0}>
            {pct_chg && Math.round(pct_chg * 100)}%{' '}
            {pct_chg && pct_chg > 0 ? up : down}
          </Text>
        </HStack>
      ) : (
        i18n.pages.securityOverview.na
      )}
    </Panel>
  );
};
