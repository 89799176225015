import {
  Box,
  Button,
  Center,
  chakra,
  HStack,
  IconButton,
  Menu,
  MenuDivider,
  MenuItem,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Input } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { LogScaleContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { useGetHumioDashboards } from '@gamma/investigator/queries';
import { PopUpMenuLinksProps } from '@gamma/navigation';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

const { dashboardsMenu, dashboards, findDashboard, noResultsFound } =
  i18n.navigation;
const { dashboards: dashboardsRoute } = ROUTES;
const StyledLink = chakra(Link);

export const DashboardMenu = () => {
  const {
    iframeKey,
    setIframeKey,
    sessionIdTokenCorelight,
    setLogScaleDashboardsLoading,
    setLogScaleDashboardsError,
    setLogScaleDashboardsData,
    setLogScaleDashboardParams,
  } = useContext(LogScaleContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isOpen: isPopUpMenuOpen } = useDisclosure();

  const { loading, error, data } = useGetHumioDashboards({
    skip: !sessionIdTokenCorelight,
    variables: { humioSessionToken: sessionIdTokenCorelight || '' },
  });

  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    setLogScaleDashboardsLoading?.(loading);
  }, [loading, setLogScaleDashboardsLoading]);

  useEffect(() => {
    if (error) {
      setLogScaleDashboardsError?.(error);
    }
  }, [error, setLogScaleDashboardsError]);

  useEffect(() => {
    if (data?.getHumioDashboards) {
      setLogScaleDashboardsData?.(data);
    }
  }, [data, setLogScaleDashboardsData]);

  // Derive dashboard menu links from the fetched data.
  const dashboardMenuLinks = useMemo<PopUpMenuLinksProps[]>(() => {
    if (!data?.getHumioDashboards) return [];

    const allDashLink: PopUpMenuLinksProps = {
      to: dashboardsRoute(),
      label: dashboardsMenu,
      isHidden: true,
    };

    const dashLinks = [...data.getHumioDashboards.searchDomain.dashboards]
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map((link) => ({
        to: dashboardsRoute(),
        label: link.name,
        menuItemProps: {
          'data-dashboard-name': link.name,
          'data-dashboard-id': link.id,
        },
      }));

    return [allDashLink, ...dashLinks];
  }, [data, dashboardsMenu]);

  const filteredDashboardMenuLinks = useMemo(() => {
    if (!searchText) return dashboardMenuLinks;
    return dashboardMenuLinks.filter((link) =>
      link.label.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [dashboardMenuLinks, searchText]);

  const clearSearch = () => setSearchText('');

  const handleDashboardsButton = () => {
    localStorage.removeItem('dashboard_name');
    localStorage.removeItem('dashboard_id');
    setLogScaleDashboardParams?.({ name: '', dashboardId: '' });
    navigate(dashboardsRoute());
    if (setIframeKey && iframeKey !== undefined) {
      setIframeKey(iframeKey + 1);
    }
  };

  const storeLocalStorageDashboard = (dashName: string, dashId: string) => {
    localStorage.setItem('dashboard_name', dashName);
    localStorage.setItem('dashboard_id', dashId);
  };

  const handleLinkOnClick = (dashName: string, dashId: string) => {
    storeLocalStorageDashboard(dashName, dashId);
    setLogScaleDashboardParams?.({ name: dashName, dashboardId: dashId });
    setIframeKey?.((prev) => (prev != null ? prev + 1 : 1));
  };

  return (
    <Menu isOpen={isPopUpMenuOpen}>
      <VStack
        w="300px"
        h="500px"
        spacing={0}
        overflowY="auto"
        alignItems="stretch"
        _last={{ pb: 1 }}
        _first={{ pt: 0 }}
      >
        <HStack
          p={2}
          alignItems="start"
          borderBottomWidth="1px"
          borderBottomColor="border.1"
          backgroundColor="layer.2"
        >
          <Box>
            <Input
              size="sm"
              name="search"
              value={searchText}
              isLabelHidden={true}
              label={findDashboard}
              placeholder={findDashboard}
              leftElement={<MuiIcon>search</MuiIcon>}
              onChange={(e) => setSearchText(e.target.value)}
              rightElement={
                <IconButton
                  data-testid="search-term-clear"
                  aria-label="clear"
                  onClick={clearSearch}
                  variant="link"
                  colorScheme="gray"
                >
                  <MuiIcon>close</MuiIcon>
                </IconButton>
              }
            />
          </Box>
          <Box>
            <Button
              px={2}
              size="sm"
              w="112px"
              variant="solid"
              rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
              onClick={handleDashboardsButton}
            >
              {dashboards}
            </Button>
          </Box>
        </HStack>

        <Box flex="1 1 auto">
          {loading ? (
            <Center w="100%" data-testid="loading-indicator">
              <GraphQLReqStatus loading={loading} error={error} />
            </Center>
          ) : error ? (
            <Box p={4} data-testid="error-message">
              <Text textStyle="h6" color="gray.500">
                Error fetching dashboards
              </Text>
            </Box>
          ) : filteredDashboardMenuLinks &&
            filteredDashboardMenuLinks.length > 0 ? (
            filteredDashboardMenuLinks.map((link, index) => {
              const {
                to,
                label,
                menuItemProps,
                isHidden = false,
                hasDivider = false,
              } = link;

              const dashName = menuItemProps?.['data-dashboard-name'] ?? label;
              const dashId = menuItemProps?.['data-dashboard-id'] ?? '';
              const isActive = Boolean(matchPath(to, pathname));

              return (
                <StyledLink
                  key={index}
                  to={to}
                  borderRadius={5}
                  style={{ textDecoration: 'none' }}
                  onContextMenu={() =>
                    storeLocalStorageDashboard(dashName, dashId)
                  }
                >
                  {hasDivider && <MenuDivider />}
                  <MenuItem
                    onClick={() => handleLinkOnClick(dashName, dashId)}
                    color={
                      isActive &&
                      dashName === localStorage.getItem('dashboard_name')
                        ? 'state.selected'
                        : 'inherit'
                    }
                    bgColor="transparent"
                    display={isHidden ? 'none' : 'flex'}
                    {...menuItemProps}
                  >
                    <Text>{label}</Text>
                  </MenuItem>
                </StyledLink>
              );
            })
          ) : (
            <Box p={4}>
              <Text textStyle="h6" color="gray.500">
                {noResultsFound}
              </Text>
            </Box>
          )}
        </Box>
      </VStack>
    </Menu>
  );
};
