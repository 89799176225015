import {
  Button,
  Flex,
  Heading,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Text,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionQueryVariables,
  IGetDetectionVariables,
  SantizedDetection,
} from '@gamma/investigator/pages/detections';
import {
  Detection,
  GET_DETECTIONS,
  IDetectionUpdateInfoInput,
  QUERY_DETECTIONS_PAGINATED,
  useSetStatusOnDetections,
} from '@gamma/investigator/queries';
import { Alert } from '@gamma/overlay';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const { excludeEntity } = i18n.pages.entityDetails;

const {
  setStatusOnDetectionsSuccessMessage,
  setStatusOnDetectionsErrorMessage,
  success,
  error,
} = excludeEntity;

const { cannotBeReverted, close, closeDetection } = i18n.pages.detections;

interface CloseDetectionContentProps {
  onClose: () => void;
  refetchQueries?: any;
  refetchMain?: boolean;
  excludedEntitiesLoading?: boolean;
  detectionData?: Detection | SantizedDetection;
  setIsClosed?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setPopoverType?: React.Dispatch<React.SetStateAction<string | null>>;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
  isLoading?: boolean;
}

export const CloseDetectionContent = ({
  onClose,
  setIsClosed,
  refetchMain,
  setIsLoading,
  detectionData,
  setPopoverType,
  refetchQueries,
  excludedEntitiesLoading,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
  isLoading,
}: CloseDetectionContentProps) => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const refetchQueriesLocal = [
    {
      query: GET_DETECTIONS,
      variables: getDetectionVariables,
    },
  ];

  const { detection_id, tenant_info } = detectionData || {};

  const variables = {
    items: [
      {
        detection_id,
        // This may need to be updated in the future to allow for re-activating the detection
        detection_status: 'closed',
        update_info: {
          last_updated_timestamp: detectionData?.update_info
            ?.last_updated_timestamp as IDetectionUpdateInfoInput,
        },
        tenant: tenant_info?.tenant_id,
      },
    ],
  };

  const [setStatusOnDetections, { data: setStatusOnDetectionsData }] =
    useSetStatusOnDetections({
      variables,
      refetchQueries: refetchQueries
        ? refetchQueries
        : !queryDetectionsPaginatedVariables
          ? refetchQueriesLocal
          : [
              ...refetchQueriesLocal,
              {
                query: QUERY_DETECTIONS_PAGINATED,
                variables: queryDetectionsPaginatedVariables,
              },
            ],
      onCompleted: () => {
        setIsLoading && setIsLoading(false);
        toast({
          title: success,
          description: i18n.formatString(
            setStatusOnDetectionsSuccessMessage,
            detectionData?.alert_info?.alert_name as string,
          ),
          status: 'success',
          position: 'bottom-right',
          isClosable: true,
        });
        setPopoverType && setPopoverType(null);
        onClose();
        if (refetchMain) {
          setSearchParams((params) => {
            params.set('refetch_main', 'true');
            return params;
          });
        }
      },
      onError: () => {
        setIsLoading && setIsLoading(false);
        toast({
          title: error,
          description: i18n.formatString(
            setStatusOnDetectionsErrorMessage,
            detectionData?.alert_info?.alert_name as string,
          ),
          status: 'error',
          position: 'bottom-right',
          isClosable: true,
        });
      },
    });

  const handleCloseDetection = () => {
    setIsLoading && setIsLoading(true);
    setStatusOnDetections();
  };

  const isStatusOpen = useMemo(() => {
    return detectionData?.detection_status === 'open';
  }, [detectionData]);

  useEffect(() => {
    setIsClosed && setIsClosed(!isStatusOpen);
  }, [isStatusOpen, setIsClosed]);

  return (
    <Portal>
      <PopoverContent w="320px" data-testid="close-detection-popover-content">
        <PopoverCloseButton top={1}>
          <MuiIcon size="sm">close</MuiIcon>
        </PopoverCloseButton>
        <PopoverHeader>
          <Heading textStyle="body-md-bold">{close}</Heading>
        </PopoverHeader>
        <PopoverBody>
          <Text mb={2}>{closeDetection}</Text>
          <Alert variant="subtle" status="warning">
            {cannotBeReverted}
          </Alert>
        </PopoverBody>
        <PopoverFooter alignItems="end">
          <Flex justifyContent="end">
            <Button
              variant="solid"
              colorScheme="blue"
              data-testid="detection-close-button"
              onClick={() => {
                handleCloseDetection();
              }}
              isDisabled={!isStatusOpen}
              isLoading={excludedEntitiesLoading || isLoading}
            >
              {close}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Portal>
  );
};
