import { Box, chakra, Flex, HStack, Tag, Text } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { detailsProps } from '@gamma/investigator/queries';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { LoadingSpinner } from '@gamma/progress';
import { Key, useEffect, useState } from 'react';

import { ChatGPTToolTip } from '../ChatGPT';
import {
  SuricataAlertDetailsModal,
  valueProps as modalDataProps,
} from '../SuricataAlertDetailsModal';

interface AlertDetailsLayoutProps {
  modalData?: modalDataProps;
  label: string;
  input?: any;
  linesToTruncate?: number;
  message?: string;
  options?: Omit<detailsProps, 'label' | 'input'>;
  isInModal?: boolean;
  isGPTEnabled?: boolean;
  isLoadingGPT?: boolean;
  isLoadingPrivileges?: boolean;
  isLoadingSuricataDescription?: boolean;
}

export const SuricataAlertDetailsLayout = ({
  modalData,
  linesToTruncate = 2,
  label,
  input,
  options,
  isInModal,
  isLoadingGPT,
  isGPTEnabled,
  isLoadingPrivileges,
  isLoadingSuricataDescription,
}: AlertDetailsLayoutProps) => {
  const isObject = typeof input === 'object';
  const stringyfied = !isObject ? input : undefined;
  const suricataModal = i18n.pages.alertDetails.suricata.modal;
  const [descriptionInput, setDescriptionInput] = useState<string>('');

  useEffect(() => {
    setDescriptionInput(input);
  }, [input]);

  const Body = () => (
    <Flex
      flexDir={isInModal ? 'column' : 'row'}
      mt={isInModal ? 2 : 0}
      display={
        isLoadingSuricataDescription
          ? 'flex'
          : label === 'Description'
            ? 'block'
            : 'flex'
      }
      alignItems="baseline"
    >
      <Text
        w={
          isLoadingSuricataDescription && label === 'Description'
            ? '100px'
            : '45%'
        }
        textStyle="body-md"
        mr={2}
        whiteSpace={'nowrap'}
        as={'span'}
        mt={2}
      >
        {label === 'Description' ? (
          modalData?.alert_type === 'suricata_corelight' &&
          !isLoadingGPT &&
          isGPTEnabled &&
          !isLoadingPrivileges &&
          descriptionInput ? (
            <HStack>
              <ChatGPTToolTip />
              <chakra.span>
                {i18n.pages.integrations.chatGPT.description}:
              </chakra.span>
            </HStack>
          ) : (
            <Text
              as={!descriptionInput ? 'span' : 'p'}
              mr={8}
              textStyle="body-md"
            >
              {i18n.pages.integrations.chatGPT.description + ':'}
            </Text>
          )
        ) : (
          label + ':'
        )}
      </Text>

      {!options?.truncate && input && (
        <Text
          w="100%"
          textStyle="body-md"
          mr={8}
          mt={!options?.lineBreak?.[0] ? 2 : -4}
          as={'span'}
        >
          {!options?.truncate && input === 'suricata_corelight' && (
            <Text>{options?.stringify && stringifyAlertType(stringyfied)}</Text>
          )}
          {!options?.stringify &&
            isObject &&
            input?.map((detail: string | number, index: Key) => {
              return (
                <>
                  <Text key={index}>
                    {detail} {input?.length - 1 !== index && ' - '}
                  </Text>
                </>
              );
            })}
          {!options?.stringify && !isObject && input}
        </Text>
      )}
      {!input && (
        <Text w="100%" textStyle="body-md" mr={8} mt={2} as={'span'}>
          {' - '}
        </Text>
      )}
      {options?.truncate && input && (
        <Flex mt={-4}>
          {isLoadingSuricataDescription ? (
            <LoadingSpinner size="xs" />
          ) : (
            <SuricataAlertDetailsModal
              isGPTEnabled={isGPTEnabled}
              value={{
                ...modalData,
                description: isLoadingGPT ? '' : input,
              }}
            >
              {
                <Text
                  as={'span'}
                  color={'text.link'}
                  noOfLines={linesToTruncate}
                  _hover={{ cursor: 'pointer' }}
                >
                  {suricataModal.viewAll}
                </Text>
              }
            </SuricataAlertDetailsModal>
          )}
        </Flex>
      )}
    </Flex>
  );

  const LineBreak = () => (
    <Box mt={1}>
      <Body />
    </Box>
  );

  return options?.lineBreak?.[1] ? <LineBreak /> : <Body />;
};
