import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IQueryAlertsPaginated, NoticeAlert } from './types';

export const QUERY_ALERTS_NOTICE_SUMMARY = gql`
  query queryAlertsSuricataPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
    $log_search_client_session_id: String!
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on NoticeAlert {
          session_summary(
            log_search_client_session_id: $log_search_client_session_id
          ) {
            ioc
            alert_summary
            alert_beacons
            unusual_findings
            attack_tactics
          }
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const useQueryAlertsNoticeSummary = (
  options: QueryHookOptions<
    IQueryAlertsPaginated<NoticeAlert>,
    OperationVariables
  >,
) => {
  return useQuery<IQueryAlertsPaginated<NoticeAlert>>(
    QUERY_ALERTS_NOTICE_SUMMARY,
    options,
  );
};
