import { Button, HStack, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { StatusStrings } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  GET_DETECTIONS,
  QUERY_DETECTIONS_PAGINATED,
  useQueryAlertMetadataExcludedEntitiesPaginated,
} from '@gamma/investigator/queries';
import { RouterLink } from '@gamma/navigation';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DetectionQueryVariables } from '../../../../Detections';
import { treatEscalationName } from '../../../../Utils';
import { ActionsMenu, ActionsMenuButton } from '../../../DetectionActions';
import { AuthContext } from '@gamma/investigator/context';

const { suppressedMsg } = i18n.pages.entityDetails.excludeEntity;

const { viewDetection, detection: detectionText } = i18n.pages.detections;

export interface DetectionDrawerActionsProps {
  detection?: Detection;
  isRelated?: boolean;
  isRowActions?: boolean;
  isRowActionsExpanded?: boolean;
  isDetailsPage?: boolean;
  setIsSlideDrawerOpen?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  relatedEntitiesVariables?: DetectionQueryVariables;
  relatedDetectionsVariables?: DetectionQueryVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
  setIsExcluded?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DetectionDrawerActions = ({
  detection,
  isRelated,
  isRowActions,
  isRowActionsExpanded,
  isDetailsPage,
  setIsExcluded,
  setIsSlideDrawerOpen,
  relatedEntitiesVariables,
  relatedDetectionsVariables,
  queryDetectionsPaginatedVariables,
}: any) => {
  const { userRole } = useContext(AuthContext);

  if (!detection) {
    return null;
  }
  const { detection_id, tenant_info } = detection || {};

  const getDetectionVariables = {
    detection_ids: [
      {
        detection_id,
        tenant: tenant_info?.tenant_id,
      },
    ],
  };

  const excludedEntitiesQueryVariables = {
    query:
      detection?.alert_entity?.entity_type === 'DOMAIN'
        ? `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detection?.alert_info?.content_id
          }"}}, {"term": {"entity_name.keyword": "${
            detection?.alert_entity?.entity_name
          }"}}, {"term": {"entity_type": "${
            detection?.alert_entity?.entity_type
          }"}}${detection?.tenant_info?.tenant_id ? ',' : ''}${
            detection?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detection?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`
        : `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detection?.alert_info?.content_id
          }"}}, {"term": {"entity_type": "${
            detection?.alert_entity?.entity_type
          }"}}, {"bool": {"should": [{"term": {"entity_ip": "${
            detection?.alert_entity?.entity_name
          }"}}, {"range": {"entity_ip_range": {"from": "${
            detection?.alert_entity?.entity_name
          }", "to": "${detection?.alert_entity?.entity_name}"}}}]}}${
            detection?.tenant_info?.tenant_id ? ',' : ''
          }${
            detection?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detection?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`,
    offset: 0,
    size: 10,
    sort: [
      {
        sort_by: 'entity_name.keyword',
        sort_dir: 'desc',
      },
    ],
  };

  const {
    loading: excludedEntitiesLoading,
    data: excludedEntitiesData,
    error: excludedEntitiesError,
  } = useQueryAlertMetadataExcludedEntitiesPaginated({
    skip:
      !detection?.tenant_info?.tenant_id ||
      !detection?.alert_info?.content_id ||
      !detection?.alert_entity?.entity_name ||
      !detection?.alert_entity?.entity_type ||
      isRowActions,
    variables: excludedEntitiesQueryVariables,
  });

  const excludedEntities =
    excludedEntitiesData?.queryAlertMetadataExcludedEntitiesPaginated
      ?.excluded_entities;

  if (setIsExcluded && excludedEntities) {
    setIsExcluded(excludedEntities && excludedEntities?.length > 0);
  }

  const refetchQueries = [
    ...(getDetectionVariables
      ? [
          {
            query: GET_DETECTIONS,
            variables: getDetectionVariables,
          },
        ]
      : []),
    ...(relatedEntitiesVariables
      ? [
          {
            query: QUERY_DETECTIONS_PAGINATED,
            variables: relatedEntitiesVariables,
          },
        ]
      : []),
    ...(relatedDetectionsVariables
      ? [
          {
            query: QUERY_DETECTIONS_PAGINATED,
            variables: relatedDetectionsVariables,
          },
        ]
      : []),
    ...(queryDetectionsPaginatedVariables
      ? [
          {
            query: QUERY_DETECTIONS_PAGINATED,
            variables: queryDetectionsPaginatedVariables,
          },
        ]
      : []),
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const detailsTo = useMemo(() => {
    const searchDetectionId = searchParams.get('detection_id');
    if (searchDetectionId) {
      searchParams.delete('detection_id');
    }
    if (detection) {
      return {
        pathName: `${ROUTES.detections}/${detection.detection_id}/details`,
        search: searchParams.toString(),
      };
    }
    return undefined;
  }, [searchParams, detection]);

  const excludedEntitiesMessage =
    excludedEntities && excludedEntities?.length > 0
      ? `${i18n.formatString(
          suppressedMsg,
          detection?.alert_entity?.entity_name ?? '',
          excludedEntities[0]?.created_by_alias,
          moment(excludedEntities[0]?.created_timestamp * 1000).format('lll'),
        )}`
      : null;

  const treatedEscalationName =
    treatEscalationName(detection?.escalation_data?.escalation_service_name) ||
    '';

  const escalatedMessage = detection?.escalation_data?.escalation_status
    ? `${i18n.formatString(
        detectionText.sentToMsg,
        treatedEscalationName,
        detection?.escalation_data?.escalated_by_user_alias,
        moment(detection?.escalation_data?.escalated_timestamp * 1000).format(
          'lll',
        ),
      )}`
    : null;

  const statusStrings = [];
  excludedEntitiesMessage !== null &&
    statusStrings.push(excludedEntitiesMessage);
  escalatedMessage !== null && statusStrings.push(escalatedMessage);

  return (
    <VStack w="100%" spacing={4}>
      <HStack w="100%" justifyContent="space-between">
        {!isRowActions ? (
          <ActionsMenuButton
            isRelated={isRelated}
            detectionData={detection}
            isRowActions={isRowActions}
            refetchQueries={refetchQueries}
            excludedEntitiesData={excludedEntitiesData}
            excludedEntitiesError={excludedEntitiesError}
            excludedEntitiesLoading={excludedEntitiesLoading}
            getDetectionVariables={getDetectionVariables}
            queryDetectionsPaginatedVariables={
              queryDetectionsPaginatedVariables
            }
            userRole={userRole}
          />
        ) : (
          <ActionsMenu
            isRelated={isRelated}
            detectionData={detection}
            isRowActions={isRowActions}
            isRowActionsExpanded={isRowActionsExpanded}
            refetchQueries={refetchQueries}
            excludedEntitiesData={excludedEntitiesData}
            excludedEntitiesError={excludedEntitiesError}
            excludedEntitiesLoading={excludedEntitiesLoading}
            getDetectionVariables={getDetectionVariables}
            queryDetectionsPaginatedVariables={
              queryDetectionsPaginatedVariables
            }
            userRole={userRole}
          />
        )}
        {!isRowActions && (
          <Button
            size="sm"
            as={RouterLink}
            variant="solid"
            colorScheme="blue"
            rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
            to={`${detailsTo?.pathName}?${detailsTo?.search}`}
          >
            {viewDetection}
          </Button>
        )}
      </HStack>
      {!isRowActions && statusStrings?.length > 0 && (
        <StatusStrings statusStrings={statusStrings} />
      )}
    </VStack>
  );
};
