import {
  IconButton,
  Popover,
  PopoverTrigger,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import { Detection } from '@gamma/investigator/queries';

import { IGetDetectionVariables } from '..';
import { DetectionQueryVariables, SantizedDetection } from '../..';
import { AssignUserContent } from '../DetectionActions';

const { controls } = i18n.pages.detections;

const { editAssignee } = controls;

interface DetectionAssigneePopoverProps {
  refetchMain?: boolean;
  detectionInfo: Detection | SantizedDetection;
  isAssigneeHovered: boolean;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
}

export const DetectionAssigneePopover = ({
  refetchMain,
  detectionInfo,
  isAssigneeHovered,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
}: DetectionAssigneePopoverProps) => {
  const [isEditing, setIsEditing] = useBoolean();

  return (
    <Popover
      closeOnEsc={true}
      closeOnBlur={true}
      offset={[-145, 10]}
      isOpen={isEditing}
      onOpen={setIsEditing.on}
      onClose={setIsEditing.off}
    >
      <>
        {isEditing || isAssigneeHovered ? (
          <PopoverTrigger>
            <span>
              <Tooltip textStyle="body-md" label={editAssignee}>
                <IconButton
                  w="24px"
                  h="24px"
                  size="box-md"
                  variant="solid"
                  colorScheme="gray"
                  aria-label={editAssignee}
                  data-testid="suppress-entity-popover-content-trigger"
                >
                  <MuiIcon size="sm">edit</MuiIcon>
                </IconButton>
              </Tooltip>
            </span>
          </PopoverTrigger>
        ) : null}
        <AssignUserContent
          refetchMain={refetchMain}
          onClose={setIsEditing.off}
          detectionData={detectionInfo}
          getDetectionVariables={getDetectionVariables}
          queryDetectionsPaginatedVariables={queryDetectionsPaginatedVariables}
        />
      </>
    </Popover>
  );
};
