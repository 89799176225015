import { LicenseInfo } from '@gamma/investigator/queries';
import { AuthState, CognitoUserInterface } from '@gamma/shared/types';
import { Dispatch, SetStateAction, createContext } from 'react';

export type UserRole = 'admin' | 'analyst' | 'viewer' | undefined;

export interface DetectionAutoCloseConfig {
  duration: string;
  updated_on: string;
  updated_by: string;
}

export type UserPrivileges = {
  [key: string]: boolean;
} & {
  detection_auto_close_config?: DetectionAutoCloseConfig;
};

export interface AmplifyUserContext {
  authState?: AuthState;
  setAuthState: (state?: AuthState) => void;
  user?: CognitoUserInterface;
  setUser: (user?: CognitoUserInterface) => void;
  userAlias?: string;
  setUserAlias?: Dispatch<SetStateAction<string | undefined>>;
  userRole?: UserRole;
  setUserRole: Dispatch<SetStateAction<UserRole>>;
  userPrivileges?: Record<string, UserPrivileges>;
  userPrivilegesLoading?: boolean;
  setUserPrivilegesLoading?: Dispatch<SetStateAction<boolean>>;
  setUserPrivileges: (userPrivileges: Record<string, UserPrivileges>) => void;
  userLicense?: LicenseInfo;
  setUserLicense: (userLicence: LicenseInfo | undefined) => void;
  userLicenseLoading?: boolean;
  setUserLicenseLoading?: Dispatch<SetStateAction<boolean>>;
  isFederatedOrgTenant?: boolean;
  setIsFederatedOrgTenant?: Dispatch<SetStateAction<boolean>>;
  isOrgTenant?: boolean;
  setIsOrgTenant?: Dispatch<SetStateAction<boolean>>;
  isPOVTenant?: boolean;
  setIsPOVTenant: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<AmplifyUserContext>({
  authState: undefined,
  setAuthState: () => null,
  user: undefined,
  setUser: () => null,
  userAlias: undefined,
  setUserAlias: () => null,
  userRole: undefined,
  setUserRole: () => null,
  userPrivileges: undefined,
  setUserPrivileges: () => null,
  userPrivilegesLoading: undefined,
  setUserPrivilegesLoading: () => null,
  userLicense: undefined,
  setUserLicense: () => null,
  userLicenseLoading: undefined,
  setUserLicenseLoading: () => null,
  isFederatedOrgTenant: undefined,
  setIsFederatedOrgTenant: () => null,
  isOrgTenant: undefined,
  setIsOrgTenant: () => null,
  isPOVTenant: undefined,
  setIsPOVTenant: () => null,
});
