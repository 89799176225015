import { Text, Tooltip } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { expirationDatesLicenseCompare } from '@gamma/investigator/utilities';
import { SidebarContext, SidebarFooterItem } from '@gamma/navigation';
import moment from 'moment';
import { useContext, useMemo } from 'react';

const { systemSettingsGeneral } = ROUTES;
interface WarningContentProps {
  daysLeft?: number;
  timestamp: number;
  expired: boolean;
}

export const WarningContent = ({ timestamp, expired }: WarningContentProps) => {
  const { licenseExpiring } = i18n.navigation;
  const format = 'll';
  const time = moment.unix(timestamp);
  const formattedTime = time.format(format);

  if (expired) {
    return (
      <Text noOfLines={1} fontSize="sm">
        {licenseExpiring.descExpired}
      </Text>
    );
  }

  return (
    <Text noOfLines={2} fontSize="sm">
      {i18n.formatString(licenseExpiring.descExpiresOn, <b>{formattedTime}</b>)}
    </Text>
  );
};

export const LicenseExpireWarning = () => {
  const { userLicense, userRole } = useContext(AuthContext);
  const { isOpen } = useContext(SidebarContext);
  const timestamp = userLicense?.end || 0;
  const userLicenseType = userLicense?.license_type;
  const expired = !!userLicense?.expired;
  const { licenseExpiring } = i18n.navigation;

  const expirationMeta = useMemo(
    () => expirationDatesLicenseCompare(timestamp, userLicenseType, expired),
    [timestamp, expired, userLicenseType],
  );

  const statusCheck = expirationMeta.status === 'success';

  if (userRole !== 'admin' || statusCheck) {
    return null;
  }

  return (
    <Tooltip
      label={licenseExpiring.descViewInfo}
      shouldWrapChildren
      isDisabled={!isOpen} // since there's already a tooltip when closed, disable it
    >
      <SidebarFooterItem
        icon={<MuiIcon color={`${expirationMeta.color}.300`}>warning</MuiIcon>}
        href={systemSettingsGeneral}
        target="_self"
        data-testid={`license-${expired ? 'expired' : 'expiring'}-warning`}
      >
        <WarningContent
          daysLeft={expirationMeta.daysLeft}
          expired={expired}
          timestamp={timestamp}
        />
      </SidebarFooterItem>
    </Tooltip>
  );
};
