import { Button, Flex, HStack, useColorMode, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Input, OptionType, Select } from '@gamma/form-fields';
import { EntityTypeIcon } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { escapeSpecialChars } from '@gamma/investigator/utilities';
import { Panel } from '@gamma/layout';
import { chakraComponents, MultiValue, OptionProps } from 'chakra-react-select';
import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

export interface TypeOption {
  value: string;
  label: string;
  type: ReactNode;
}

interface AlertCatalogForm {
  wildcard: string;
  type: string;
}

interface ExcludedEntitiesSearchProps {
  content_id: string | undefined;
  setQuery: (query: string | null) => void;
  setOffsetReset: Dispatch<SetStateAction<boolean>>;
}

const { noValueSet, search, type } = i18n.pages.system.alertCatalog;

export const ExcludedEntitiesSearch = ({
  setQuery,
  content_id,
  setOffsetReset,
}: ExcludedEntitiesSearchProps) => {
  const { colorMode } = useColorMode();

  const { control, register, handleSubmit, setValue } =
    useForm<AlertCatalogForm>({
      mode: 'onSubmit',
      defaultValues: {
        wildcard: '',
        type: '',
      },
    });

  const typeOptions: TypeOption[] = [
    {
      value: 'DOMAIN',
      label: 'Domain',
      type: <EntityTypeIcon type={'domain'} boxSize={5} m={-1} mr={2} />,
    },
    {
      value: 'IP',
      label: 'IP',
      type: <EntityTypeIcon type={'ip'} boxSize={5} m={-1} mr={2} />,
    },
    {
      value: 'IP_RANGE',
      label: 'CIDR',
      type: <EntityTypeIcon type={'ip_range'} boxSize={5} m={-1} mr={2} />,
    },
  ];

  const handleSearchSubmit = (formData: AlertCatalogForm) => {
    let queries = content_id
      ? `{"match": {"content_id": "${content_id}"}}`
      : '';

    if (formData?.type?.length) {
      const queryString = generateQueryString(
        formData?.type,
        typeOptions,
        (option) => `{"match": { "entity_type": "${option?.value}"}}`,
      );
      const activeQuery = `${queries.length ? ',' : ''}${queryString}`;
      queries += activeQuery;
    }

    if (formData?.wildcard) {
      const query = escapeSpecialChars({
        chars: formData?.wildcard,
        unescapePeriods: true,
      });

      queries += `${
        queries.length ? ',' : ''
      }{"dis_max":{"queries":[{"match": {"entity_name": "${query}"}}, {"wildcard": {"entity_name": {"value": "*${query}*", "case_insensitive": true}}}], "tie_breaker": 0.5}}`;
    }

    if (queries.length) {
      setQuery(queries);
    } else {
      setQuery(null);
    }

    setOffsetReset(true);
  };

  useEffect(() => {
    setQuery(`{
      "match": {
        "content_id": "${content_id}"
      }
    }`);
  }, [content_id, setQuery]);

  return (
    <Panel data-testid="excluded-entities-search">
      <VStack spacing={4}>
        <HStack
          w="100%"
          as="form"
          borderRadius="base"
          onSubmit={handleSubmit(handleSearchSubmit)}
        >
          <Input
            {...register('wildcard')}
            label="Search"
            isLabelHidden
            data-testid="excluded-entities-search-input"
            placeholder={i18n.pages.system.alertCatalog.search}
            leftElement={<MuiIcon>search</MuiIcon>}
            rightElement={
              <MuiIcon
                as="button"
                onClick={() => {
                  setValue('wildcard', '');
                  handleSubmit(handleSearchSubmit)();
                }}
              >
                close
              </MuiIcon>
            }
          />
          <Button
            type="submit"
            variant="solid"
            alignSelf="flex-start"
            data-testid="excluded-entities-search-button"
          >
            {search}
          </Button>
        </HStack>
        <Flex w="100%">
          <HStack>
            {/* @ts-ignore */}
            <Controller
              control={control}
              name={'type'}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <Select
                    isMulti
                    name={name}
                    label={name}
                    value={getSelectedValues(value, typeOptions)}
                    onChange={(values) => {
                      onChange(createOptionString(values));
                      handleSubmit(handleSearchSubmit)();
                    }}
                    isSearchable={false}
                    isDisabled={false}
                    isLabelHidden={true}
                    options={typeOptions}
                    leftElement={type}
                    data-testid="excluded-entities-search-select"
                    bg={colorMode === 'dark' ? 'gray.800' : ''}
                    minWidth="200px"
                    width="fit-content"
                    tagVariant="solid"
                    placeholder={noValueSet}
                    components={customOption}
                  />
                );
              }}
            />
          </HStack>
        </Flex>
      </VStack>
    </Panel>
  );
};

const CustomSelectOption = <
  IsMulti extends boolean,
  Option extends OptionType,
>({
  children,
  ...rest
}: OptionProps<Option, IsMulti, any>) => (
  <chakraComponents.Option {...rest}>
    {rest.data.type} {children}
  </chakraComponents.Option>
);

const customOption = {
  Option: CustomSelectOption,
};

const createOptionString = <T extends OptionType>(options: MultiValue<T>) => {
  const values = options.map(({ value }) => value);
  return values.join(', ');
};

const getSelectedValues = <T extends OptionType>(
  values: string,
  options: T[],
) => {
  const splitString = values.split(', ');
  const optionsArray = splitString.map((string) => {
    return options.find((option) => option.value.toString() === string);
  });
  const filteredArray = optionsArray.filter((option) => option);
  return filteredArray as T[];
};

const generateQueryString = <T extends OptionType>(
  value: string,
  options: T[],
  callback: (option: T) => string,
) => {
  let queryValues = '';
  const selectedOptions = getSelectedValues(value, options);

  selectedOptions.forEach((option, index) => {
    queryValues += selectedOptions.length > 1 && index === 0 ? `[` : '';
    queryValues += selectedOptions.length > 1 && index !== 0 ? ',' : '';
    queryValues += callback(option);
    queryValues +=
      selectedOptions.length > 1 && index === selectedOptions.length - 1
        ? `]`
        : '';
  });
  const activeQuery = `{"bool": {"should": ${queryValues}}}`;
  return activeQuery;
};
