import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { ParentInfo } from './useGetCommentsForParentPaginated';

export interface CreateComment {
  comment: string;
  parent: ParentInfo;
  tenant: string;
}

export interface CreateCommentVariables {
  comment: CreateComment;
}

export interface Reply {
  offset: number;
  size: number;
  sort: Record<string, string>[];
}

export interface CreateCommentData {
  comment_id: string;
  parent: ParentInfo;
  created_at: string;
  updated_at: string;
  author_alias: string;
  comment: string;
  replies: Reply;
}

export interface CreateCommentResponse {
  createComment: CreateCommentData;
}

export const CREATE_COMMENT = gql`
  mutation createComment($comment: CommentInput) {
    createComment(comment: $comment) {
      comment_id
      parent {
        parent_object_id
        parent_object_type
      }
      created_at
      updated_at
      author_alias
      comment
      replies {
        offset
        size
        sort {
          direction
        }
      }
    }
  }
`;

export const useCreateComment = (
  options: MutationHookOptions<CreateCommentResponse, CreateCommentVariables>,
) => {
  return useMutation<CreateCommentResponse, CreateCommentVariables>(
    CREATE_COMMENT,
    options,
  );
};
