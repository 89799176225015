import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { ChatGPTToolTip } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { Modal } from '@gamma/overlay';
import { ErrorMessage, GraphQLReqStatus } from '@gamma/progress';
import { SessionSummaryHeaders } from '@gamma/investigator/queries';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components/DetailsGrid';
import { MuiIcon } from '@gamma/icons';
import { SessionSummaryData } from '@gamma/investigator/queries';

export interface SessionSummaryProps {
  sessionSummaryData?: SessionSummaryData;
  error?: ErrorMessage;
  loading: boolean;
}

export const SessionSummary = ({
  sessionSummaryData,
  error,
  loading,
}: SessionSummaryProps) => {
  const { detection } = i18n.pages.detections;
  const { sessionSummary } = i18n.pages.alertDetails;

  const {
    isOpen: sessionSummaryIsOpen,
    onOpen: sessionSummaryOnOpen,
    onClose: sessionSummaryOnClose,
  } = useDisclosure();

  const headerIcons: Record<SessionSummaryHeaders, string> = {
    ioc: 'flag',
    alert_summary: 'report',
    unusual_findings: 'search',
    attack_tactics: 'handyman',
    alert_beacons: 'e911_emergency',
  };

  return error || loading ? (
    <GraphQLReqStatus error={error} loading={loading} />
  ) : (
    <>
      <Button
        px={2}
        marginTop={2}
        height="auto"
        variant="pill"
        w="fit-content"
        colorScheme="gray"
        whiteSpace="initial"
        data-testid="activity-summary-modal-button"
        size="sm"
        leftIcon={<ChatGPTToolTip boxSize={4} />}
        onClick={sessionSummaryOnOpen}
      >
        {sessionSummary.analyzeActivities}
      </Button>
      <Modal
        size="xl"
        isOpen={sessionSummaryIsOpen}
        onClose={sessionSummaryOnClose}
        title={
          <Box display="flex" flexDir="row">
            <ChatGPTToolTip boxSize={4} />
            {'  '}
            <Text marginLeft="1">{sessionSummary.alertConnectionInsights}</Text>
          </Box>
        }
        body={
          sessionSummaryData ? (
            <DetailsGrid templateColumns="220px 1fr">
              {Object.values(SessionSummaryHeaders).map((header) => {
                return (
                  <DetailsGridRow
                    title={
                      <Box>
                        <MuiIcon size="xs" verticalAlign="middle">
                          {headerIcons[header] ?? 'flag'}
                        </MuiIcon>
                        {'  '}
                        {sessionSummary.headers[header]}
                      </Box>
                    }
                    key={header}
                  >
                    <Box>
                      {sessionSummaryData[header].map((text: string, i) => (
                        <p key={`${header}-${i}`}>{text}</p>
                      ))}
                    </Box>
                  </DetailsGridRow>
                );
              })}
            </DetailsGrid>
          ) : (
            <p>{sessionSummary.dataUnavailable}</p>
          )
        }
        footer={
          <Button
            variant="solid"
            colorScheme="gray"
            onClick={sessionSummaryOnClose}
          >
            {detection.close}
          </Button>
        }
      />
    </>
  );
};
