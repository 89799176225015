import { ComponentStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../common';

export const ButtonStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => {
    return {
      borderRadius: 'base',
      outlineOffset: 0,
      border: 'solid 1px',
      borderColor: 'transparent',
      _focus: {
        outlineColor: 'blue.400',
        boxShadow: 'none',
      },
    };
  },
  sizes: {
    lg: {
      ...textStyles['body-md'],
      fontWeight: '500',
      py: 2.5,
      px: 4,
      h: 10,
    },
    md: {
      ...textStyles['body-md'],
      fontWeight: '500',
      h: 8,
      py: 2,
      px: 2,
    },
    sm: {
      ...textStyles['body-sm'],
      fontWeight: '500',
      h: 7,
      py: 1.5,
      px: 2,
    },
    xs: {
      ...textStyles['body-sm'],
      fontWeight: '500',
      h: 6,
      py: 1,
      px: 2,
    },
    'box-xs': {
      w: 6,
      h: 6,
      p: '5px',
      fontSize: '12px',
    },
    'box-sm': {
      w: 7,
      h: 7,
      p: '7px',
      fontSize: '12px',
    },
    'box-md': {
      w: 8,
      h: 8,
      p: 2,
      fontSize: '14px',
    },
    'box-lg': {
      w: 10,
      h: 10,
      p: 3,
      fontSize: '14px',
    },
  },
  variants: {
    solid: (props) => {
      const cs = props.colorScheme;
      const color = `${cs}.50`;
      const bg = `${cs}.500`;
      const bgHover = `${cs}.600`;
      const bgPressed = `${cs}.600`;
      const bgActive = `${cs}.700`;

      return {
        border: 'solid 1px',
        borderColor: 'whiteAlpha.300',
        bgColor: bg,
        color,
        _hover: {
          bgColor: bgHover,
        },
        _active: {
          bgColor: bgActive,
        },
        _pressed: {
          bgColor: bgPressed,
        },
        _disabled: {
          '&&': {
            bgColor: bg,
            color: color,
            opacity: 0.5,
          },
        },
      };
    },
    outline: (props: {
      colorMode?: 'light' | 'dark';
      colorScheme?: string;
    }) => {
      const cs = props.colorScheme;
      const dark = props.colorMode === 'dark';
      const bg = 'transparent';
      const color = `${cs}.${(() => {
        switch (true) {
          case dark && cs === 'gray':
            return 50;
          case dark && cs === 'blue':
            return 300;
          default:
            return 400;
        }
      })()}`;
      const borderColor = 'currentColor';
      const filledColor = `${cs}.50`;
      const bgHover = `${cs}.${cs === 'gray' && dark ? 700 : 600}`;
      const bgPressed = `${cs}.${cs === 'gray' && dark ? 800 : 600}`;
      const bgActive = `${cs}.${cs === 'gray' && dark ? 800 : 700}`;

      return {
        color,
        bg,
        border: 'solid 1px',
        borderColor,
        _hover: {
          color: filledColor,
          bgColor: bgHover,
          borderColor: 'whiteAlpha.300',
        },
        _active: {
          color: filledColor,
          bgColor: bgActive,
          borderColor: 'whiteAlpha.300',
        },
        _pressed: {
          color: filledColor,
          bgColor: bgPressed,
          borderColor: 'whiteAlpha.300',
        },
        _disabled: {
          '&&': {
            color,
            bg,
            borderColor,
            opacity: 0.5,
          },
        },
      };
    },
    ghost: (props: any) => {
      const cs = props.colorScheme;
      const color = `${cs}.400`;
      const bg = 'transparent';
      const borderColor = 'transparent';
      const filledColor = `${cs}.50`;
      const bgHover = `${cs}.600`;
      const bgPressed = `${cs}.600`;
      const bgActive = `${cs}.700`;

      return {
        color,
        bg,
        border: 'solid 1px',
        borderColor,
        _hover: {
          color: filledColor,
          bgColor: bgHover,
          borderColor: 'whiteAlpha.300',
        },
        _active: {
          color: filledColor,
          bgColor: bgActive,
          borderColor: 'whiteAlpha.300',
        },
        _pressed: {
          color: filledColor,
          bgColor: bgPressed,
          borderColor: 'whiteAlpha.300',
        },
        _disabled: {
          '&&': {
            color,
            bg,
            borderColor,
            opacity: 0.5,
          },
        },
      };
    },
    link: (props: { colorScheme?: string }) => {
      const cs = props.colorScheme;
      const color = cs === 'blue' ? 'text.link' : `${cs}.400`;
      const bg = 'transparent';
      const borderColor = 'transparent';
      const colorHover = cs === 'blue' ? 'text.link.hover' : `${cs}.300`;
      const colorPressed = cs === 'blue' ? 'text.link.action' : `${cs}.200`;
      const textUnderline = {
        textDecoration: 'underline',
        '.mui-icon': {
          overflow: 'hidden',
        },
      };

      return {
        color,
        bg,
        border: 'solid 1px',
        borderColor,
        minWidth: 'unset',
        _hover: {
          color: colorHover,
          bg: 'whiteAlpha.50',
          ...textUnderline,
        },
        _active: {
          color: colorPressed,
          bg: 'whiteAlpha.50',
          ...textUnderline,
        },
        _pressed: {
          color: colorPressed,
          bg: 'whiteAlpha.50',
          ...textUnderline,
        },
        _disabled: {
          '&&': {
            color,
            bg,
            borderColor,
            opacity: 0.5,
            textDecoration: 'none',
          },
        },
      };
    },
    pill: (props: any) => {
      const cs = props.colorScheme;
      const color = `${cs}.50`;
      const bg = `${cs}.500`;
      const borderColor = 'whiteAlpha.300';
      const bgHover = `${cs}.600`;
      const bgPressed = `${cs}.600`;
      const bgActive = `${cs}.700`;

      return {
        border: 'solid 1px',
        borderColor,
        bgColor: bg,
        color,
        _hover: {
          bgColor: bgHover,
        },
        _active: {
          bgColor: bgActive,
        },
        _pressed: {
          bgColor: bgPressed,
        },
        _disabled: {
          '&&': {
            bgColor: bg,
            color,
            borderColor,
            opacity: 0.5,
          },
        },
        borderRadius: 'full',
      };
    },
    tab: {
      py: 5,
      borderRadius: 'base',
      _hover: {
        bg: 'state.selected.bg',
        color: 'selected',
      },
      _disabled: {
        cursor: 'default',
        opacity: 0.3,
        _hover: {
          bg: 'transparent',
        },
      },
    },
    dataTablePanelSelectedAction: {
      color: 'gray.50',
      borderColor: 'gray.50',
      _active: {
        color: 'gray.50',
        borderColor: 'gray.50',
      },
      _hover: {
        color: 'gray.50',
        borderColor: 'gray.50',
      },
    },
    unstyled: {
      display: 'flex',
    },
  },
  defaultProps: {
    variant: 'unstyled',
    colorScheme: 'blue',
  },
};
