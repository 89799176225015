import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  useGetCommentsForParentPaginated,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import { SantizedDetection } from '../../Detections';
import { Box, Button, HStack, VStack, Text } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import moment from 'moment';
import { useContext, useState } from 'react';
import { SlideDrawerContext } from '@gamma/investigator/context';

interface DetectionNotesDrawerProps {
  disableBorders?: boolean;
  item: Detection | SantizedDetection | undefined;
  setDetectionData?: React.Dispatch<
    React.SetStateAction<Detection | SantizedDetection | undefined>
  >;
}

interface CommentBoxProps {
  comment: string;
  author: string;
  created_at: number;
}

const {
  detection: { noNotes, added, note, loadMore },
} = i18n.pages.detections;

export const CommentBox = ({
  comment,
  author,
  created_at,
}: CommentBoxProps) => (
  <HStack alignItems="start" padding={2} width="100%">
    <MuiIcon color="gray">chat</MuiIcon>
    <VStack alignItems="start">
      <h2>
        <strong>{author} </strong>
        {added}
        <strong> {note} </strong>
        <span style={{ color: 'gray' }}>
          {String(moment.unix(created_at).format('MMMM Do, h:mma'))}
        </span>
      </h2>
      <Text wordBreak="break-all">{comment}</Text>
    </VStack>
  </HStack>
);

export const DetectionNotesDrawer = ({ item }: DetectionNotesDrawerProps) => {
  const COMMENT_BATCH_SIZE = 10;
  const [currentSize, setCurrentSize] = useState(COMMENT_BATCH_SIZE);

  const { isNotesDrawerLoading } = useContext(SlideDrawerContext);
  const {
    data: getCommentsForParentData,
    loading: getCommentsForParentLoading,
    error: getCommentsForParentError,
    refetch: getCommentsForParentRefetch,
  } = useGetCommentsForParentPaginated({
    skip: !item?.detection_id,
    variables: {
      parent: {
        parent_object_type: 'detection',
        parent_object_id: item?.detection_id ?? '',
      },
      size: currentSize,
      sort: [{ field: 'created_at', direction: 'desc' }],
    },
  });

  const loadMoreComments = () => {
    getCommentsForParentRefetch({
      parent: {
        parent_object_type: 'detection',
        parent_object_id: item?.detection_id ?? '',
      },
      size: currentSize,
    });
  };

  const { comments, total_count = 0 } =
    getCommentsForParentData?.getCommentsForParentPaginated || {};

  return (
    <Box w="100%">
      {getCommentsForParentLoading ||
      isNotesDrawerLoading ||
      getCommentsForParentError ? (
        <GraphQLReqStatus
          loading={getCommentsForParentLoading || !!isNotesDrawerLoading}
          error={getCommentsForParentError}
        />
      ) : (
        <VStack w="100%" spacing={3}>
          {!comments || !comments.length ? (
            <p style={{ marginTop: '20px' }}>{noNotes}</p>
          ) : (
            <>
              {comments.map(({ comment, author_alias, created_at }) => (
                <CommentBox
                  comment={comment}
                  author={author_alias}
                  created_at={created_at}
                  key={`${author_alias}-${created_at}`}
                />
              ))}
              {total_count > comments.length && (
                <Button
                  variant="solid"
                  colorScheme="gray"
                  width="90%"
                  margin={2}
                  onClick={() => {
                    setCurrentSize(currentSize + COMMENT_BATCH_SIZE);
                    loadMoreComments();
                  }}
                >
                  {loadMore}
                </Button>
              )}
            </>
          )}
        </VStack>
      )}
    </Box>
  );
};
