import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Spinner,
  usePrevious,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ROUTES } from '@gamma/investigator/constants';
import { useManualPagination } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertAndDetectionTenantInfo,
  AlertEntity,
  AlertInfo,
  Detection,
  DetectionAssignmentInfo,
  DetectionEscalationData,
  DetectionRank,
  DetectionUpdateInfo,
  IQueryDetectionsPaginated,
  PaginatedAlertEntityName,
} from '@gamma/investigator/queries';
import {
  Board,
  Column,
  FlushPanelContent,
  Panel,
  PanelHeader,
  PanelHeading,
} from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SlideDrawerContext } from '@gamma/investigator/context';
import { createPortal } from 'react-dom';
import {
  DetectionDrawer,
  DetectionDrawerActions,
  DetectionsFilters,
  DetectionsListView,
  DetectionsPageHeader,
  DetectionsSort,
  DetectionsTableView,
} from './Components';

export const formatSort = (value: string) => {
  if (value === 'latest_start_timestamp.desc') {
    return [{ id: 'latest_start_timestamp', desc: true }];
  } else if (value === 'latest_start_timestamp.asc') {
    return [{ id: 'latest_start_timestamp', desc: false }];
  } else if (value === 'rank.severity.desc') {
    return [{ id: 'rank.severity', desc: true }];
  } else if (value === 'rank.severity.asc') {
    return [{ id: 'rank.severity', desc: false }];
  }
  return [{ id: 'latest_start_timestamp', desc: true }];
};

interface ApiSortOption {
  sort_by: string;
  sort_dir: string;
}

export interface EntityOption {
  value: string;
  label: string;
  type: string;
}

export interface DetectionQueryVariables {
  query: string;
  size: number;
  offset: number;
  sort: ApiSortOption[];
}

const { controls } = i18n.pages.detections;

const { detections } = ROUTES;

export interface DetectionsProps {
  getAppConfigurationsLoading: boolean;
}

export type SantizedDetection = {
  tenant: string;
  tenant_info: AlertAndDetectionTenantInfo;
  rank: DetectionRank;
  detection_id: string;
  alert_info: AlertInfo;
  detection_status: string;
  total_alert_count: number;
  alert_entity: AlertEntity;
  latest_start_timestamp: number;
  earliest_start_timestamp: number;
  update_info: DetectionUpdateInfo;
  escalation_data: DetectionEscalationData;
  assignment_info: DetectionAssignmentInfo;
  'rank.severity': number;
  'alert_info.alert_name': string;
  'alert_info.alert_type': string;
  'alert_entity.entity_name': string;
  'rank.is_custom_severity': boolean;
  'tenant_info.tenant_display_name': string;
  'assignment_info.assigned_to_user_alias': string;
  'escalation_data.escalation_status': boolean | undefined;
  paginated_source_entities: PaginatedAlertEntityName;
  paginated_destination_entities: PaginatedAlertEntityName;
};

export const Detections = ({
  getAppConfigurationsLoading,
}: DetectionsProps) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const {
    portalType,
    setPortalType,
    slideDrawerTitle,
    slideDrawerBody,
    slideDrawerActions,
    setIsSlideDrawerOpen,
  } = useContext(SlideDrawerContext);

  const active_view = params.get('view');
  const detection_id = params.get('detection_id');
  const sort_by = params.get('sort_by');

  const [isExcluded, setIsExcluded] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    Detection | SantizedDetection | undefined
  >();

  const [detectionsData, setDetectionsData] =
    useState<IQueryDetectionsPaginated>();
  const [detectionsError, setDetectionsError] = useState<
    ApolloError | undefined
  >();
  const [detectionsLoading, setDetectionsLoading] = useState<boolean>(true);

  const [detectionData, setDetectionData] = useState<
    Detection | SantizedDetection
  >();

  const [sortByParam, setSortByParam] = useState<string>(
    sort_by || 'latest_start_timestamp.desc',
  );

  const [activeView, setActiveView] = useState<string | null>(
    active_view || 'list',
  );

  useEffect(() => {
    if (detection_id) {
      setSelectedItem(
        detectionsData?.queryDetectionsPaginated?.detections?.find(
          (detection: Detection) => detection.detection_id === detection_id,
        ) ?? detectionsData?.queryDetectionsPaginated?.detections?.[0],
      );
    }
  }, [detection_id, detectionsData]);

  const prevSelectedItem = usePrevious(selectedItem);

  useEffect(() => {
    if (prevSelectedItem !== selectedItem) {
      selectedItem && params.set('detection_id', selectedItem.detection_id);
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [navigate, params, prevSelectedItem, selectedItem]);

  const [queryVariables, setQueryVariables] = useState<any>();

  const {
    offset,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
  } = useManualPagination<any>({
    sortBy: formatSort(queryVariables?.sortBy),
  });

  const handleFetchData = useCallback(
    (pageIndex: number, pageSize: number, sortBy?: any) => {
      if (sortBy?.length) {
        if (activeView === 'table') {
          setSortByParam(
            `${sortBy[0]?.id}.${sortBy[0]?.desc ? 'desc' : 'asc'}`,
          );
        }
      }
      onFetchData(pageIndex, pageSize, sortBy);
    },
    [activeView],
  );

  useEffect(() => {
    activeView === 'list' && setIsSlideDrawerOpen?.(false);
  }, [activeView, setIsSlideDrawerOpen]);

  useEffect(() => {
    setPortalType?.('detection');
  }, [setPortalType]);

  useEffect(() => {
    if (detectionsData?.queryDetectionsPaginated?.total_items === 0) {
      setIsSlideDrawerOpen?.(false);
    }
  }, [detectionsData, setIsSlideDrawerOpen]);

  if (getAppConfigurationsLoading) {
    return (
      <ErrorBoundary page="Detections" styleClass="main">
        <Panel>
          <GraphQLReqStatus loading={getAppConfigurationsLoading} />
        </Panel>
      </ErrorBoundary>
    );
  }

  if (detectionsError) {
    return (
      <GraphQLReqStatus
        isBackground={true}
        error={detectionsError}
        loading={detectionsLoading}
      />
    );
  }

  return (
    <ErrorBoundary page="Detections" styleClass="main">
      <Box mb={0} data-testid="detections-wrapper">
        <DetectionsPageHeader
          isLoading={detectionsLoading}
          queryVariables={queryVariables}
        />
        <Board>
          <Column data-testid="detections-filters">
            <DetectionsFilters
              size={pageSize}
              offset={offset}
              formatSort={formatSort}
              getPageCount={getPageCount}
              getSortsForAPI={getSortsForAPI}
              setQueryVariables={setQueryVariables}
              setDetectionsData={setDetectionsData}
              setDetectionsError={setDetectionsError}
              setDetectionsLoading={setDetectionsLoading}
            />
          </Column>
          <Column>
            <Panel>
              <PanelHeader>
                <PanelHeading>
                  <HStack
                    divider={
                      activeView === 'list' ? <Box height="24px" /> : <></>
                    }
                  >
                    <ButtonGroup isAttached>
                      <Button
                        size="sm"
                        variant="solid"
                        onClick={() => setActiveView('list')}
                        leftIcon={<MuiIcon>view_list</MuiIcon>}
                        colorScheme={activeView === 'list' ? 'blue' : 'gray'}
                      >
                        {controls.list}
                      </Button>
                      <Button
                        size="sm"
                        variant="solid"
                        leftIcon={<MuiIcon>table</MuiIcon>}
                        onClick={() => setActiveView('table')}
                        colorScheme={activeView === 'table' ? 'blue' : 'gray'}
                      >
                        {controls.table}
                      </Button>
                    </ButtonGroup>
                    <DetectionsSort
                      params={params}
                      navigate={navigate}
                      activeView={activeView}
                      sortByParam={sortByParam}
                    />
                  </HStack>
                </PanelHeading>
              </PanelHeader>
              <FlushPanelContent>
                {activeView === 'list' && (
                  <DetectionsListView
                    offset={offset}
                    params={params}
                    navigate={navigate}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    formatSort={formatSort}
                    selectedItem={selectedItem}
                    detectionData={detectionData}
                    setIsExcluded={setIsExcluded}
                    detectionsData={detectionsData}
                    queryVariables={queryVariables}
                    getSortsForAPI={getSortsForAPI}
                    handleFetchData={handleFetchData}
                    setSelectedItem={setSelectedItem}
                    setDetectionData={setDetectionData}
                    detectionsLoading={detectionsLoading}
                  />
                )}
                {activeView === 'table' && (
                  <DetectionsTableView
                    offset={offset}
                    params={params}
                    navigate={navigate}
                    sort_by={sort_by}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    formatSort={formatSort}
                    setIsExcluded={setIsExcluded}
                    detectionsData={detectionsData}
                    queryVariables={queryVariables}
                    getSortsForAPI={getSortsForAPI}
                    handleFetchData={handleFetchData}
                    setSelectedItem={setSelectedItem}
                    detectionsLoading={detectionsLoading}
                  />
                )}
              </FlushPanelContent>
            </Panel>
          </Column>
        </Board>
      </Box>
      {portalType === 'detection' &&
        slideDrawerTitle &&
        createPortal(
          selectedItem ? (
            <Box __css={{ wordWrap: 'anywhere' }}>
              {`${selectedItem?.alert_info?.alert_name} | ${selectedItem?.alert_entity?.entity_name}`}
            </Box>
          ) : (
            <Center paddingTop={2} paddingLeft={6}>
              <Spinner size="xs" />
            </Center>
          ),
          slideDrawerTitle,
        )}
      {portalType === 'detection' &&
        slideDrawerBody &&
        createPortal(
          selectedItem ? (
            <DetectionDrawer
              item={selectedItem}
              disableBorders={true}
              setDetectionData={setDetectionData}
              queryDetectionsPaginatedVariables={{
                query: queryVariables?.query,
                size: queryVariables?.size,
                offset: queryVariables?.offset,
                sort: getSortsForAPI(formatSort(queryVariables?.sortBy)),
              }}
            />
          ) : (
            <Center paddingTop={4}>
              <Spinner />
            </Center>
          ),
          slideDrawerBody,
        )}
      {portalType === 'detection' &&
        slideDrawerActions &&
        createPortal(
          queryVariables &&
            !detectionsLoading &&
            (detectionData ? (
              <Box w="100%" my={2}>
                <DetectionDrawerActions
                  detection={detectionData}
                  setIsExcluded={setIsExcluded}
                  onPreviewClose={() => setIsSlideDrawerOpen?.(false)}
                  queryDetectionsPaginatedVariables={{
                    query: queryVariables.query,
                    size: queryVariables.size,
                    offset: queryVariables.offset,
                    sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
                  }}
                />
              </Box>
            ) : (
              <Center paddingTop={4}>
                <Spinner />
              </Center>
            )),
          slideDrawerActions,
        )}
    </ErrorBoundary>
  );
};
