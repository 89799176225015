import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Spinner,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IconButton } from '@gamma/buttons';
import { DataTable } from '@gamma/data-table';
import { TextDivider } from '@gamma/display';
import { Select } from '@gamma/form-fields';
import { CrowdStrikeSmall, MuiIcon } from '@gamma/icons';
import { AuthContext } from '@gamma/investigator/context';
import {
  useDateRangeQueryString,
  useManualPagination,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertEntity,
  CrowdStrikeConfiguration,
  Detection,
  EntityEdrStatusWithTimestamp,
  ExcludedEntity,
  NICHistory,
  useGetEDRPayload,
  useGetEntityEdrStatus,
  useGetNicHistoryForEndpointDeviceEntity,
  useGetUserHistoryForEndpointDeviceEntity,
  usePerformEdrAction,
  usePullEntityEdrStatus,
  UserHistory,
} from '@gamma/investigator/queries';
import { AccordionPanel, FlushPanelContent, Panel } from '@gamma/layout';
import { Alert, Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Column as TableColumn } from 'react-table';

const { entityEDR } = i18n.pages.detections;
const { headings, edrAction } = entityEDR;

const { entity, entityType } = i18n.pages.entityDetails.excludeEntity;

const dataTableInterfaceColumns: TableColumn<NICHistory>[] = [
  {
    Header: entityEDR.tableHeaders.ipAddress,
    accessor: 'ip_address',
    disableSortBy: true,
  },
  {
    Header: entityEDR.macAddress,
    accessor: 'mac_address',
    disableSortBy: true,
  },
  {
    Header: entityEDR.timestamp,
    accessor: 'obsts',
    Cell: (props) => {
      return <>{moment.unix(props.value).format('MMMM Do, h:mma')}</>;
    },
  },
];

const dataTableUserColumns: TableColumn<UserHistory>[] = [
  {
    Header: entityEDR.tableHeaders.user,
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: entityEDR.tableHeaders.lastLogin,
    accessor: 'obsts',
    Cell: (props) => {
      return <>{moment.unix(props.value).format('MMMM Do, h:mma')}</>;
    },
  },
];

export interface DetectionEntityEDRPanelProps {
  isRelated?: boolean;
  refetchMain?: boolean;
  entityInfo: AlertEntity;
  entities?: AlertEntity[];
  detectionInfo?: Detection;
  excludeEntitiesData?: ExcludedEntity[];
  excludeEntitiesError?: ApolloError;
  edrConfig: CrowdStrikeConfiguration;
  setActiveEntity?: React.Dispatch<React.SetStateAction<AlertEntity | null>>;
  queryVariables?: {
    query: string;
    offset: number;
    size: number;
    sort: {
      sort_by: string;
      sort_dir: string;
    }[];
  };
  disableBorders?: boolean;
}

interface SelectedEntityFormData {
  entity: EntityOption;
}

interface EntityOption {
  value: string;
  label: string;
}

export interface DetectionEntityPanelRowProps {
  heading: string;
  children?: ReactNode;
  disableBorders?: boolean;
}

export const DetectionEntityPanelRow = ({
  heading,
  children,
  disableBorders,
}: DetectionEntityPanelRowProps) => (
  <Flex alignItems="center" w="100%">
    <Box w="120px" mr={2}>
      <Text
        color="text.300"
        data-testid="entity-panel-entity-type-label"
        textStyle="body-md"
      >
        {heading}
      </Text>
    </Box>
    <Box flexGrow={1} wordBreak={disableBorders ? 'break-all' : 'normal'}>
      <Text data-testid="entity-panel-entity-type-value" textStyle="body-md">
        {children ? children : '---'}
      </Text>
    </Box>
  </Flex>
);

export const DetectionEntityEDRPanel = ({
  entities,
  edrConfig,
  isRelated,
  entityInfo,
  detectionInfo,
  setActiveEntity,
  disableBorders = false,
}: DetectionEntityEDRPanelProps) => {
  const { userRole } = useContext(AuthContext);

  const showToastMessage = useToast();

  const entityOptions: EntityOption[] = entities?.length
    ? entities?.map((entity: AlertEntity) => {
        return {
          value: entity.entity_id,
          label: entity.entity_name,
        };
      })
    : [
        {
          value: '',
          label: '',
        },
      ];

  const defaultEntityValue = useMemo(() => {
    return {
      entity: entityInfo
        ? {
            value: entityInfo?.entity_id,
            label: entityInfo?.entity_name,
          }
        : {},
    };
  }, [entityInfo]);

  const { control, setValue } = useForm<SelectedEntityFormData>({
    mode: 'onChange',
    defaultValues: defaultEntityValue,
  });

  const fillForm = useCallback(() => {
    setValue('entity', {
      value: entityInfo?.entity_id || '',
      label: entityInfo?.entity_name || '',
    });
  }, [setValue, entityInfo]);

  useEffect(() => {
    fillForm();
  }, [entityInfo, fillForm]);

  const handleEntityChange = async (event: any, onChange: any) => {
    const value = event.value;
    onChange(value);
    setActiveEntity &&
      setActiveEntity(
        entities?.find((entity) => entity.entity_id === value) || null,
      );
  };

  const {
    isOpen: interfaceHistoryIsOpen,
    onOpen: interfaceHistoryOnOpen,
    onClose: interfaceHistoryOnClose,
  } = useDisclosure();

  const {
    isOpen: userHistoryIsOpen,
    onOpen: userHistoryOnOpen,
    onClose: userHistoryOnClose,
  } = useDisclosure();

  const {
    isOpen: edrActionIsOpen,
    onOpen: edrActionOnOpen,
    onClose: edrActionOnClose,
  } = useDisclosure();

  const { detection_id } = useParams();

  const { start, end } = useDateRangeQueryString();

  const { latest_start_timestamp, tenant_info, alert_entity } =
    detectionInfo || {};
  const { entity_name, entity_type } = entityInfo || alert_entity || {};
  const { tenant_id } = tenant_info || {};

  const {
    loading: edrPayloadLoading,
    error: edrPayloadError,
    data: edrPayloadData,
  } = useGetEDRPayload({
    skip: !tenant_id || !entity_name || !entity_type || !latest_start_timestamp,
    variables: {
      tenant: tenant_id,
      alert_entity_info: {
        entity_name,
        entity_type,
      },
      as_of: latest_start_timestamp,
    },
  });

  const {
    ad_domain_name,
    ad_organizational_unit_name,
    cloud_account_id,
    cloud_instance_id,
    cloud_provider,
    data_source,
    device_id,
    entity_id,
    external_ips,
    host_names,
    last_updated_timestamp,
    mac_addresses,
    nic_history,
    os,
    platform,
    platform_type,
    sensor_version,
    user_history,
  } = edrPayloadData?.getEdrPayload || {};

  const [nicInitialFetch, nicSetInitialFetch] = useState<boolean>(false);

  const {
    sortBy: nicSortBy,
    offset: nicOffset,
    pageSize: nicPageSize,
    pageIndex: nicPageIndex,
    pageCount: nicPageCount,
    onFetchData: nicOnFetchData,
    getPageCount: nicGetPageCount,
    getSortsForAPI: nicGetSortsForAPI,
  } = useManualPagination<NICHistory>({
    initialFetch: nicInitialFetch,
    sortBy: [{ id: 'obsts', desc: true }],
  });

  const [
    getNicHistoryForEndpointDeviceEntity,
    {
      loading: getNicHistoryLoading,
      data: getNicHistoryData,
      error: getNicHistoryError,
    },
  ] = useGetNicHistoryForEndpointDeviceEntity({
    variables: {
      as_of: latest_start_timestamp,
      entity: {
        entity_id,
        entity_type: 'endpoint_device',
      },
      tenant: tenant_id,
      offset: nicOffset,
      size: nicPageSize,
      sort: nicGetSortsForAPI(nicSortBy),
    },
    onCompleted: ({ getNicHistoryForEndpointDeviceEntity }) => {
      nicSetInitialFetch(false);
      nicGetPageCount(getNicHistoryForEndpointDeviceEntity);
    },
  });

  const [edrStatus, setEdrStatus] = useState<
    EntityEdrStatusWithTimestamp | undefined
  >();

  const { entity_edr_status } = edrStatus || {};

  const entityEdrIsolated =
    entity_edr_status === 'isolated' ||
    entity_edr_status === 'lift_isolation_pending';

  const edrStatusAlert = useMemo(() => {
    if (entity_edr_status) {
      switch (entity_edr_status) {
        case 'normal':
          return {
            colorScheme: 'green',
            statusText: edrAction.normal,
          };
        case 'isolated':
          return {
            colorScheme: 'red',
            statusText: edrAction.isolated,
          };
        case 'isolation_pending':
          return {
            colorScheme: 'yellow',
            statusText: edrAction.pendingIsolation,
          };
        case 'lift_isolation_pending':
          return {
            colorScheme: 'yellow',
            statusText: edrAction.pendingLift,
          };
      }
    }
    return undefined;
  }, [edrStatus]);

  const {
    loading: getEdrStatusLoading,
    data: getEdrStatusData,
    error: getEdrStatusError,
  } = useGetEntityEdrStatus({
    skip: !entity_id || !tenant_id,
    fetchPolicy: 'network-only',
    variables: {
      entity_id,
      tenant: tenant_id,
    },
    onCompleted({ getEntityEdrStatus }) {
      const { entity_edr_status, tenant, updated_timestamp } =
        getEntityEdrStatus;
      setEdrStatus({
        tenant,
        entity_edr_status,
        updated_timestamp,
      });
    },
  });

  const [
    pullEntityEdrStatus,
    {
      loading: pullEdrStatusLoading,
      data: pullEdrStatusData,
      error: pullEdrStatusError,
    },
  ] = usePullEntityEdrStatus({
    variables: {
      entity_id,
      tenant: tenant_id,
    },
    onCompleted({ pullEntityEdrStatus }) {
      const { tenant, entity_edr_status, updated_timestamp } =
        pullEntityEdrStatus;
      setEdrStatus({
        tenant,
        entity_edr_status,
        updated_timestamp,
      });
      showToastMessage({
        status: 'success',
        title: edrAction.toasts.success,
        description: edrAction.toasts.refreshSuccessful,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError(error) {
      showToastMessage({
        status: 'error',
        title: edrAction.toasts.error,
        description: edrAction.toasts.refreshFailed,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const [
    performEdrAction,
    {
      loading: performEdrActionLoading,
      data: performEdrActionData,
      error: performEdrActionError,
    },
  ] = usePerformEdrAction({
    variables: {
      tenant: tenant_id,
      action: !entityEdrIsolated ? 'isolate' : 'lift_isolation',
      edr_device_input: {
        entity_id,
        detection_id: detectionInfo?.detection_id,
      },
    },
    onCompleted(data) {
      edrActionOnClose();
      showToastMessage({
        status: 'success',
        title: edrAction.toasts.success,
        description: edrAction.toasts.requestSubmitted,
        isClosable: true,
        position: 'bottom-right',
      });
      setEdrStatus({
        tenant: data?.performEdrAction?.tenant,
        entity_edr_status: data?.performEdrAction?.entity_edr_status,
        updated_timestamp: data?.performEdrAction?.updated_timestamp,
      });
    },
    onError(error) {
      showToastMessage({
        status: 'error',
        title: edrAction.toasts.error,
        description: edrAction.toasts.requestFailed,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const [userInitialFetch, userSetInitialFetch] = useState<boolean>(false);

  const {
    sortBy: userSortBy,
    offset: userOffset,
    pageSize: userPageSize,
    pageIndex: userPageIndex,
    pageCount: userPageCount,
    onFetchData: userOnFetchData,
    getPageCount: userGetPageCount,
    getSortsForAPI: userGetSortsForAPI,
  } = useManualPagination<UserHistory>({
    initialFetch: userInitialFetch,
    sortBy: [{ id: 'obsts', desc: true }],
  });

  const [
    getUserHistoryForEndpointDeviceEntity,
    {
      loading: getUserHistoryLoading,
      data: getUserHistoryData,
      error: getUserHistoryError,
    },
  ] = useGetUserHistoryForEndpointDeviceEntity({
    variables: {
      tenant: tenant_id,
      as_of: latest_start_timestamp,
      entity: {
        entity_id,
        entity_type: 'endpoint_device',
      },
      offset: userOffset,
      size: userPageSize,
      sort: userGetSortsForAPI(userSortBy),
    },
    onCompleted: ({ getUserHistoryForEndpointDeviceEntity }) => {
      userSetInitialFetch(false);
      userGetPageCount(getUserHistoryForEndpointDeviceEntity);
    },
  });

  useEffect(() => {
    nicSetInitialFetch(true);
    userSetInitialFetch(true);
  }, [start, end]);

  const handleEdrActionEvent = () => {
    if (entity_id && tenant_id && detectionInfo?.detection_id) {
      performEdrAction();
    }
  };

  return (
    <Box w="100%" data-testid="detection-drawer-entity-edr-panel">
      <AccordionPanel
        w="100%"
        title={entity}
        layerStyle="first"
        disableBorders={disableBorders}
        data-testid="entity-panel-heading"
        storageKey="detection-entity-panel"
      >
        {edrPayloadLoading || edrPayloadError ? (
          <GraphQLReqStatus
            error={edrPayloadError}
            loading={edrPayloadLoading}
          />
        ) : (
          <VStack spacing={2.5} alignItems="start">
            {entities?.length && entities?.length > 0 && setActiveEntity && (
              <Flex alignItems="center" w="100%">
                <Box w="120px" mr={2}>
                  <Text
                    color="text.300"
                    data-testid="entity-panel-entity-type-label"
                    textStyle="body-md"
                  >
                    {entity}
                  </Text>
                </Box>
                <Box flexGrow={1}>
                  {entities.length === 1 ? (
                    <Text>{entities[0].entity_name}</Text>
                  ) : (
                    <Controller
                      name={'entity'}
                      control={control}
                      render={({ field: { onChange, name, value } }) => {
                        return (
                          <Select
                            size="sm"
                            name={name}
                            label="Sort"
                            isMulti={false}
                            isLabelHidden={true}
                            options={entityOptions}
                            data-testid="detection-entity"
                            onChange={(event) => {
                              handleEntityChange(event, onChange);
                            }}
                            value={entityOptions.find(
                              (option) => option.value === value.value,
                            )}
                          />
                        );
                      }}
                    />
                  )}
                </Box>
              </Flex>
            )}
            <DetectionEntityPanelRow heading={entityType}>
              {entity_type}
            </DetectionEntityPanelRow>
            {entity_id && (
              <>
                {data_source && (
                  <DetectionEntityPanelRow heading={entityEDR.dataSource}>
                    {data_source === 'Crowdstrike' ? (
                      <HStack>
                        <CrowdStrikeSmall />
                        <Box>{data_source}</Box>
                      </HStack>
                    ) : (
                      data_source
                    )}
                  </DetectionEntityPanelRow>
                )}
                {edrConfig?.edr_action_enabled && (
                  <Flex w="100%" data-testid="entity-edr-status">
                    <DetectionEntityPanelRow heading={edrAction.entityStatus}>
                      <HStack justifyContent="space-between">
                        <HStack>
                          {edrStatusAlert && (
                            <Tag
                              size="md"
                              variant="solid"
                              colorScheme={edrStatusAlert?.colorScheme}
                            >
                              {edrStatusAlert?.statusText}
                            </Tag>
                          )}
                          <IconButton
                            aria-label={edrAction.refreshEntityStatus}
                            onClick={() => pullEntityEdrStatus()}
                            isDisabled={
                              getEdrStatusLoading ||
                              pullEdrStatusLoading ||
                              userRole === 'viewer'
                            }
                            icon={
                              getEdrStatusLoading || pullEdrStatusLoading ? (
                                <Box w="20px" h="20px">
                                  <Spinner size="xs" color="white" />
                                </Box>
                              ) : (
                                <MuiIcon>refresh</MuiIcon>
                              )
                            }
                          />
                        </HStack>
                        {detection_id && (
                          <Tooltip
                            w="120px"
                            placement="top"
                            label={
                              userRole !== 'admin'
                                ? edrAction.requestPermissions
                                : undefined
                            }
                          >
                            <Button
                              size="xs"
                              variant="solid"
                              colorScheme="gray"
                              onClick={edrActionOnOpen}
                              isDisabled={
                                userRole !== 'admin' ||
                                getEdrStatusLoading ||
                                pullEdrStatusLoading ||
                                entity_edr_status === 'isolation_pending' ||
                                entity_edr_status === 'lift_isolation_pending'
                              }
                            >
                              {entityEdrIsolated
                                ? edrAction.liftIsolation
                                : edrAction.isolateEntity}
                            </Button>
                          </Tooltip>
                        )}
                      </HStack>
                    </DetectionEntityPanelRow>
                  </Flex>
                )}
                {/* <DetectionEntityPanelRow heading={entityEDR.lastSync}>
                {last_updated_timestamp
                  ? moment
                      .unix(last_updated_timestamp)
                      .format('MMMM D, YYYY h:mma')
                  : undefined}
                </DetectionEntityPanelRow> */}
                <TextDivider textStyle="h5" color="text.secondary">
                  {headings.hostInformation}
                </TextDivider>
                <DetectionEntityPanelRow heading={entityEDR.timestamp}>
                  {last_updated_timestamp
                    ? moment
                        .unix(last_updated_timestamp)
                        .format('MMMM D, YYYY h:mma')
                    : undefined}
                </DetectionEntityPanelRow>
                <DetectionEntityPanelRow heading={entityEDR.macAddress}>
                  {mac_addresses?.join(', ')}
                </DetectionEntityPanelRow>
                <DetectionEntityPanelRow heading={entityEDR.hostName}>
                  {host_names?.join(', ')}
                </DetectionEntityPanelRow>
                <DetectionEntityPanelRow heading={entityEDR.platform}>
                  {platform}
                </DetectionEntityPanelRow>
                <DetectionEntityPanelRow heading={entityEDR.platformType}>
                  {platform_type}
                </DetectionEntityPanelRow>
                <DetectionEntityPanelRow heading={entityEDR.os}>
                  {os}
                </DetectionEntityPanelRow>
                <DetectionEntityPanelRow heading={entityEDR.externalIp}>
                  {external_ips?.join(', ')}
                </DetectionEntityPanelRow>
                {!isRelated && detection_id && (
                  <>
                    <DetectionEntityPanelRow
                      heading={entityEDR.deviceId}
                      disableBorders={disableBorders}
                    >
                      {device_id}
                    </DetectionEntityPanelRow>
                    <DetectionEntityPanelRow heading={entityEDR.sensorVersion}>
                      {sensor_version}
                    </DetectionEntityPanelRow>
                    <DetectionEntityPanelRow heading={entityEDR.cloudAccountId}>
                      {cloud_account_id}
                    </DetectionEntityPanelRow>
                    <DetectionEntityPanelRow
                      heading={entityEDR.cloudInstanceId}
                    >
                      {cloud_instance_id}
                    </DetectionEntityPanelRow>
                    <DetectionEntityPanelRow heading={entityEDR.cloudProvider}>
                      {cloud_provider}
                    </DetectionEntityPanelRow>
                    <DetectionEntityPanelRow heading={entityEDR.adDomainName}>
                      {ad_domain_name}
                    </DetectionEntityPanelRow>
                    <DetectionEntityPanelRow
                      heading={entityEDR.adOrganizationalUnitName}
                    >
                      {ad_organizational_unit_name}
                    </DetectionEntityPanelRow>
                    {nic_history && nic_history?.nic_histories?.length > 0 && (
                      <>
                        <TextDivider>{headings.interfaceHistory}</TextDivider>
                        <Box w={disableBorders ? '365px' : '100%'}>
                          <DataTable
                            isLined
                            size="sm"
                            isSortable={false}
                            isPaginable={false}
                            data={nic_history?.nic_histories}
                            columns={dataTableInterfaceColumns}
                          />
                        </Box>
                        {nic_history?.total_items > 5 && (
                          <Button
                            w="100%"
                            size="xs"
                            variant="solid"
                            colorScheme="gray"
                            onClick={() => {
                              getNicHistoryForEndpointDeviceEntity();
                              interfaceHistoryOnOpen();
                            }}
                          >
                            {entityEDR.viewRemainingInterfaceHistory}
                          </Button>
                        )}
                      </>
                    )}
                    {user_history &&
                      user_history?.user_histories?.length > 0 && (
                        <>
                          <TextDivider>{headings.userHistory}</TextDivider>
                          <Box w={disableBorders ? '365px' : '100%'}>
                            <DataTable
                              isLined
                              size="sm"
                              isSortable={false}
                              isPaginable={false}
                              data={user_history?.user_histories}
                              columns={dataTableUserColumns}
                            />
                          </Box>
                          {user_history?.total_items > 5 && (
                            <Button
                              w="100%"
                              size="xs"
                              variant="solid"
                              colorScheme="gray"
                              onClick={() => {
                                getUserHistoryForEndpointDeviceEntity();
                                userHistoryOnOpen();
                              }}
                            >
                              {entityEDR.viewRemainingUserHistory}
                            </Button>
                          )}
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </VStack>
        )}
      </AccordionPanel>
      <Modal
        size="xl"
        isOpen={interfaceHistoryIsOpen}
        onClose={interfaceHistoryOnClose}
        title={headings.interfaceHistory}
        body={
          getNicHistoryLoading || getNicHistoryError ? (
            <GraphQLReqStatus
              error={getNicHistoryError}
              loading={getNicHistoryLoading}
            />
          ) : (
            <Panel>
              <FlushPanelContent>
                <DataTable
                  isLined
                  pageSize={nicPageSize}
                  pageCount={nicPageCount}
                  autoResetPage={false}
                  columns={dataTableInterfaceColumns}
                  autoResetSortBy={false}
                  isPaginationManual={true}
                  onFetchData={nicOnFetchData}
                  itemCount={
                    getNicHistoryData?.getNicHistoryForEndpointDeviceEntity
                      ?.total_items || 0
                  }
                  initialState={{
                    pageIndex: nicPageIndex,
                    sortBy: nicSortBy,
                  }}
                  data={
                    getNicHistoryData?.getNicHistoryForEndpointDeviceEntity
                      ?.nic_histories || []
                  }
                  isPaginable={Boolean(
                    !getNicHistoryLoading &&
                      !getNicHistoryError &&
                      getNicHistoryData?.getNicHistoryForEndpointDeviceEntity
                        ?.nic_histories?.length,
                  )}
                />
              </FlushPanelContent>
            </Panel>
          )
        }
        footer={
          <ButtonGroup>
            <Button
              variant="solid"
              colorScheme="gray"
              onClick={interfaceHistoryOnClose}
            >
              {entityEDR.close}
            </Button>
          </ButtonGroup>
        }
      />

      <Modal
        size="xl"
        isOpen={userHistoryIsOpen}
        onClose={userHistoryOnClose}
        title={headings.userHistory}
        body={
          getUserHistoryLoading || getUserHistoryError ? (
            <GraphQLReqStatus
              error={getUserHistoryError}
              loading={getUserHistoryLoading}
            />
          ) : (
            <Panel>
              <FlushPanelContent>
                <DataTable
                  isLined
                  pageSize={userPageSize}
                  pageCount={userPageCount}
                  autoResetPage={false}
                  columns={dataTableUserColumns}
                  autoResetSortBy={false}
                  isPaginationManual={true}
                  onFetchData={userOnFetchData}
                  itemCount={
                    getUserHistoryData?.getUserHistoryForEndpointDeviceEntity
                      ?.total_items || 0
                  }
                  initialState={{
                    pageIndex: userPageIndex,
                    sortBy: userSortBy,
                  }}
                  data={
                    getUserHistoryData?.getUserHistoryForEndpointDeviceEntity
                      ?.user_histories || []
                  }
                  isPaginable={Boolean(
                    !getUserHistoryLoading &&
                      !getUserHistoryError &&
                      getUserHistoryData?.getUserHistoryForEndpointDeviceEntity
                        ?.user_histories?.length,
                  )}
                />
              </FlushPanelContent>
            </Panel>
          )
        }
        footer={
          <ButtonGroup>
            <Button
              variant="solid"
              colorScheme="gray"
              onClick={userHistoryOnClose}
            >
              {entityEDR.close}
            </Button>
          </ButtonGroup>
        }
      />
      <Modal
        isOpen={edrActionIsOpen}
        onClose={edrActionOnClose}
        title={`${
          entityEdrIsolated
            ? edrAction.liftEntityIsolation
            : edrAction.isolateEntity
        } in CrowdStrike`}
        body={
          entityEdrIsolated ? (
            <Text>
              <Text as="span" textStyle="body-md-bold">
                {entity_name}
              </Text>{' '}
              {edrAction.willBeAdded}
            </Text>
          ) : (
            <Alert variant="subtle" status="warning">
              <Text>
                <Text as="span" textStyle="body-md-bold">
                  {entity_name}
                </Text>{' '}
                {edrAction.willBeIsolated}
              </Text>
            </Alert>
          )
        }
        footer={
          <HStack>
            <Button
              variant="solid"
              colorScheme="gray"
              onClick={edrActionOnClose}
            >
              {edrAction.cancel}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={handleEdrActionEvent}
              isLoading={performEdrActionLoading}
              isDisabled={performEdrActionLoading}
            >
              {entity_edr_status === 'normal' ||
              entity_edr_status === 'isolation_pending'
                ? edrAction.isolateEntity
                : edrAction.liftIsolation}
            </Button>
          </HStack>
        }
      />
    </Box>
  );
};
