import { HStack, IconButton, Link, Stack, Text } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { CONSTANTS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Panel, PanelHeading } from '@gamma/layout';

export const ConfigureIDP = () => {
  const { idpConfigurationModal, audienceUrl, acsUrl } = i18n.pages.access;
  const { SSO } = CONSTANTS;

  return (
    <Stack spacing={6}>
      <Stack spacing={3}>
        <Panel layerStyle="second">
          <HStack justifyContent="space-between">
            <Stack>
              <PanelHeading>{acsUrl}</PanelHeading>
              <Text>{SSO.remote_manager_sso_url}</Text>
            </Stack>
            <IconButton
              aria-label="copy"
              icon={<MuiIcon>content_copy</MuiIcon>}
              color="blue.300"
              onClick={() =>
                navigator.clipboard.writeText(SSO.remote_manager_sso_url)
              }
            />
          </HStack>
        </Panel>
        <Panel layerStyle="second">
          <HStack justifyContent="space-between">
            <Stack>
              <PanelHeading>{audienceUrl}</PanelHeading>
              <Text>{SSO.remote_manager_url}</Text>
            </Stack>
            <IconButton
              aria-label="copy"
              icon={<MuiIcon>content_copy</MuiIcon>}
              color="blue.300"
              onClick={() =>
                navigator.clipboard.writeText(SSO.remote_manager_url)
              }
            />
          </HStack>
        </Panel>
      </Stack>
      <Stack>
        <Text>{idpConfigurationModal.configure.help}</Text>
        <Link
          isExternal
          color="blue.400"
          href="/docs/settings/users/saml-sso-users.html"
          width="fit-content"
        >
          {idpConfigurationModal.configure.helpTextSSO}
        </Link>
      </Stack>
    </Stack>
  );
};
