import {
  Button,
  Flex,
  Heading,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Text,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionQueryVariables,
  IGetDetectionVariables,
  SantizedDetection,
} from '@gamma/investigator/pages/detections';
import {
  Detection,
  GET_DETECTIONS,
  QUERY_DETECTIONS_PAGINATED,
  ServiceNowEscalationConfiguration,
  useEscalateDetections,
} from '@gamma/investigator/queries';
import { Alert } from '@gamma/overlay';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const { excludeEntity } = i18n.pages.entityDetails;

const { success, error } = excludeEntity;

const {
  sent,
  closed,
  closeAndSend,
  cannotBeReverted,
  sendToAndClose,
  sendDetectionToServiceNow,
  escalateDetectionSuccess,
  escalateDetectionError,
} = i18n.pages.detections;

interface CloseDetectionContentProps {
  onClose: () => void;
  refetchQueries?: any;
  refetchMain?: boolean;
  excludedEntitiesLoading?: boolean;
  detectionData?: Detection | SantizedDetection;
  serviceNowConfiguration?: ServiceNowEscalationConfiguration;
  setIsSentTo?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setPopoverType?: React.Dispatch<React.SetStateAction<string | null>>;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
  isLoading?: boolean;
}

export const SendToContent = ({
  onClose,
  setIsSentTo,
  refetchMain,
  setIsLoading,
  detectionData,
  setPopoverType,
  refetchQueries,
  serviceNowConfiguration,
  excludedEntitiesLoading,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
  isLoading,
}: CloseDetectionContentProps) => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const { escalation_configuration_id } = serviceNowConfiguration || {};

  const refetchQueriesLocal = [
    {
      query: GET_DETECTIONS,
      variables: getDetectionVariables,
    },
  ];

  const variables = {
    escalate_detections_info: [
      {
        escalation_configuration_id,
        detection_id: detectionData?.detection_id,
        tenant: detectionData?.tenant_info?.tenant_id,
      },
    ],
  };

  const [
    escalateDetections,
    { data: escalateDetectionsData, error: escalateDetectionsError },
  ] = useEscalateDetections({
    variables,
    refetchQueries: refetchQueries
      ? refetchQueries
      : !queryDetectionsPaginatedVariables
        ? refetchQueriesLocal
        : [
            ...refetchQueriesLocal,
            {
              query: QUERY_DETECTIONS_PAGINATED,
              variables: queryDetectionsPaginatedVariables,
            },
          ],
    onCompleted: () => {
      setIsLoading && setIsLoading(false);
      toast({
        title: success,
        description: escalateDetectionSuccess,
        status: 'success',
        position: 'bottom-right',
        isClosable: true,
      });
      setPopoverType && setPopoverType(null);
      onClose();
      if (refetchMain) {
        setSearchParams((params) => {
          params.set('refetch_main', 'true');
          return params;
        });
      }
    },
    onError: () => {
      setIsLoading && setIsLoading(false);
      toast({
        title: error,
        description: escalateDetectionError,
        status: 'error',
        position: 'bottom-right',
        isClosable: true,
      });
    },
  });

  const handleSendTo = () => {
    if (
      detectionData?.detection_id &&
      detectionData?.tenant_info?.tenant_id &&
      escalation_configuration_id
    ) {
      escalateDetections();
    }
  };

  const isSentTo = useMemo(() => {
    return !!detectionData?.escalation_data?.escalation_status;
  }, [detectionData]);

  useEffect(() => {
    setIsSentTo && setIsSentTo(isSentTo);
  }, [isSentTo, setIsSentTo]);

  return (
    <Portal>
      <PopoverContent w="320px" data-testid="send-to-popover-content">
        <PopoverCloseButton top={1}>
          <MuiIcon size="sm">close</MuiIcon>
        </PopoverCloseButton>
        <PopoverHeader>
          <Heading textStyle="body-md-bold">
            {sendDetectionToServiceNow}
          </Heading>
        </PopoverHeader>
        <PopoverBody>
          <Text mb={2}>
            {i18n.formatString(sendToAndClose, {
              closed: <Text as="strong">{closed}</Text>,
              sent: <Text as="strong">{sent}</Text>,
            })}
          </Text>
          <Alert variant="subtle" status="warning">
            {cannotBeReverted}
          </Alert>
        </PopoverBody>
        <PopoverFooter alignItems="end">
          <Flex justifyContent="end">
            <Button
              variant="solid"
              colorScheme="blue"
              data-testid="send-to-button"
              onClick={() => {
                handleSendTo();
              }}
              isDisabled={isSentTo || !escalation_configuration_id}
              isLoading={excludedEntitiesLoading || isLoading}
            >
              {closeAndSend}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Portal>
  );
};
