import { useApolloClient } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { RefreshWithCircleIcon } from '@gamma/icons';
import { DETECTION_AUTO_CLOSE_DURATION } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_TENANT_PRIVILEGES,
  useUpdatePrivileges,
} from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { useContext } from 'react';

interface ResetDetectionAutoCloseConfigProps {
  detectionAutoCloseDuration: string;
  tenant: string;
}

const { buttons, resetModal, toast } = i18n.pages.detectionSettings;

export const ResetDetectionAutoCloseConfig = ({
  detectionAutoCloseDuration,
  tenant,
}: ResetDetectionAutoCloseConfigProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const showToast = useToast();
  const { setUserPrivileges } = useContext(AuthContext);
  const client = useApolloClient();

  const [updatePrivileges, { loading: updatePrivilegesLoading }] =
    useUpdatePrivileges({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_TENANT_PRIVILEGES,
          variables: { tenant_id: tenant },
        },
      ],
      onCompleted: async () => {
        onClose();
        showToast({
          title: toast.success.title,
          description: i18n.formatString(
            toast.success.description,
            DETECTION_AUTO_CLOSE_DURATION.toString(),
          ),
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
        if (tenant) {
          const {
            data: { getTenantPrivileges },
          } = await client.query({
            query: GET_TENANT_PRIVILEGES,
            variables: { tenant_id: tenant },
            fetchPolicy: 'network-only',
          });
          setUserPrivileges({ [tenant]: JSON.parse(getTenantPrivileges) });
        }
      },
      onError: async () => {
        onClose();
        showToast({
          title: toast.error.title,
          description: toast.error.description,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  return (
    <>
      <Tooltip label="Reset" placement="top" hasArrow>
        <IconButton
          data-testid="reset-auto-close-detection"
          size="box-md"
          variant="solid"
          colorScheme="gray"
          icon={<RefreshWithCircleIcon boxSize={4} />}
          aria-label="reset auto close detection button"
          isDisabled={
            detectionAutoCloseDuration ===
            DETECTION_AUTO_CLOSE_DURATION.toString()
          }
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnEsc={!updatePrivilegesLoading}
        closeOnOverlayClick={!updatePrivilegesLoading}
        isCloseDisabled={updatePrivilegesLoading}
        isCentered
        size="md"
        title={resetModal.title}
        body={resetModal.body}
        footer={
          <ButtonGroup>
            <Button
              variant="solid"
              colorScheme="gray"
              onClick={onClose}
              isDisabled={updatePrivilegesLoading}
            >
              {buttons.cancel}
            </Button>
            <Button
              data-test-id="update-privileges-button"
              variant="solid"
              colorScheme="blue"
              isDisabled={updatePrivilegesLoading}
              isLoading={updatePrivilegesLoading}
              onClick={() =>
                updatePrivileges({
                  variables: {
                    input: {
                      detection_auto_close_duration:
                        DETECTION_AUTO_CLOSE_DURATION,
                    },
                  },
                })
              }
            >
              {buttons.save}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};
