import { CopyrightPrivacyPolicy } from './CopyrightPrivacyPolicy';
import { LicenseExpireWarning } from './LicenseExpireWarning';

export const SidebarFooterLinks = () => {
  return (
    <>
      <LicenseExpireWarning />
      <CopyrightPrivacyPolicy />
    </>
  );
};
