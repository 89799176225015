import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { ICreateQueryJob } from './types';

export const CREATE_QUERY_JOB = gql`
  mutation createQueryJob(
    $clientSessionId: String!
    $queryInputJob: QueryInputJob!
    $size: Int
    $sort: [SortParameterInput]
  ) {
    createQueryJob(
      clientSessionId: $clientSessionId
      queryInputJob: $queryInputJob
      size: $size
      sort: $sort
    ) {
      query_job_id
    }
  }
`;

export const useCreateQueryJob = (
  options?: MutationHookOptions<ICreateQueryJob, OperationVariables>,
) => {
  return useMutation<ICreateQueryJob>(CREATE_QUERY_JOB, options);
};
