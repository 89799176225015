import {
  Box,
  Center,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { TimestampCell } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { LicenseInfo } from '@gamma/investigator/queries';
import { Column, Panel, PanelHeader } from '@gamma/layout';

interface LicenseInformationProps {
  data?: {
    getLicenseInfo: LicenseInfo;
  };
}

export const LicenseInformation = ({ data }: LicenseInformationProps) => {
  const { licenseInformation } = i18n.pages.licenseDashboard;

  const {
    start = 0,
    end = 0,
    tenant_location,
    primary_contact,
  } = data?.getLicenseInfo ?? {};
  const {
    title: informationTitle,
    startDate,
    endDate,
    tenantLocation,
    primaryContact,
  } = licenseInformation;
  const { learnMore } = licenseInformation.buttons;

  return (
    <Column col={{ base: 12, xl: 6 }}>
      <Panel layerStyle="second" h="full" display="flex" flexDirection="column">
        <PanelHeader>
          <HStack>
            <Box borderRadius="base" boxSize={8}>
              <Center h="full">
                <MuiIcon color="text.primary">info</MuiIcon>
              </Center>
            </Box>
            <Text textStyle="h5">{informationTitle}</Text>
          </HStack>
        </PanelHeader>
        <Flex
          justifyContent="space-between"
          flexDirection="column"
          flexGrow={1}
        >
          <Box w={{ base: 'full', md: '80%' }}>
            <SimpleGrid templateColumns="1fr 1fr" rowGap={3} mb={2}>
              <Text textStyle={'body-md'} mr={2}>
                {startDate}
              </Text>
              <Text>
                <TimestampCell
                  timestamp={start}
                  isFromNow={false}
                  format="ll"
                />
              </Text>
              <Text textStyle={'body-md'} mr={2}>
                {endDate}
              </Text>
              <Text>
                <TimestampCell timestamp={end} isFromNow={false} format="ll" />
              </Text>
              {tenant_location && (
                <>
                  <Text textStyle={'body-md'} mr={2}>
                    {tenantLocation}
                  </Text>
                  <Text>{tenant_location}</Text>
                </>
              )}
              {primary_contact && (
                <>
                  <Text textStyle={'body-md'} mr={2}>
                    {primaryContact}
                  </Text>
                  <Stack spacing={1}>
                    <Text textStyle={'body-lg-bold'}>
                      {primary_contact?.name}
                    </Text>
                    <Text>{primary_contact?.email}</Text>
                  </Stack>
                </>
              )}
            </SimpleGrid>
          </Box>
          <Center>
            <Link
              mb={3}
              isExternal
              color={'text.link'}
              fontWeight="600"
              textStyle={'body-md'}
              href="/docs/licensing.html"
            >
              {learnMore}
            </Link>
          </Center>
        </Flex>
      </Panel>
    </Column>
  );
};
