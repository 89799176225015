import { ComponentStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../common';

const outlineColor = 'blue.400';

export const tagStyles: Partial<ComponentStyleConfig> = {
  baseStyle: (props) => ({
    container: {
      '--tag-stroke-color': 'transparent',
      boxShadow: 'inset 0 0 0 1px var(--tag-stroke-color)',
      borderRadius: 'calc(var(--tag-min-height) / 2)',
      px: 2,
      outline: '2px solid transparent',
      outlineOffset: 0,
      display: 'inline-flex',
      bgColor: 'transparent',
      color: 'inherit',
      _focusVisible: {
        outlineColor,
      },
      ':has(:focus-visible)': {
        outlineColor,
      },
      'svg:not(button > *)': {
        lineHeight: 'revert-layer',
        width: '1lh',
        height: '1lh',
        padding: '2px', // improves visual consistency with figma
      },
    },
    closeButton: {
      height: 'var(--tag-min-height)',
      width: 'var(--tag-min-height)',
      my: -2,
      mr: -2,
      bgColor: 'transparent',
      color: 'inherit',
      opacity: 1,
      '&&': {
        boxShadow: 'none',
      },
      _hover: {
        bgColor: 'whiteAlpha.300',
      },
      _pressed: {
        bgColor: 'whiteAlpha.400',
      },
      _light: {
        _focusVisible: {
          bgColor: 'blackAlpha.200',
        },
        _hover: {
          bgColor: 'blackAlpha.200',
        },
        _pressed: {
          bgColor: 'blackAlpha.300',
        },
      },
      _disabled: {
        bgColor: 'transparent',
        opacity: 0.5,
      },
    },
  }),
  sizes: {
    xs: {
      container: {
        '--tag-min-height': 'var(--chakra-sizes-4)',
        ...textStyles['body-xs'],
        py: 'px',
      },
      closeButton: {
        fontSize: 'sm',
      },
    },
    sm: {
      container: {
        ...textStyles['body-sm'],
        py: 0.5,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'md',
      },
    },
    md: {
      container: {
        ...textStyles['body-sm'],
        py: 1,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'md',
      },
    },
    lg: {
      container: {
        ...textStyles['body-md'],
        py: 1.5,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'lg',
      },
    },
  },
  variants: {
    solid: (props) => ({
      container: {
        bgColor: `${props.colorScheme}.600`,
        color: `${props.colorScheme}.50`,
        '--tag-stroke-color': 'var(--chakra-colors-whiteAlpha-300)',
        _light: {
          bgColor: `${props.colorScheme}.100`,
          color: `${props.colorScheme}.800`,
          '--tag-stroke-color': 'var(--chakra-colors-blackAlpha-300)',
        },
      },
    }),
    subtle: (props) => ({
      container: {
        bgColor: `${props.colorScheme}.800`,
        color: `${props.colorScheme}.50`,
        '--tag-stroke-color': 'var(--chakra-colors-whiteAlpha-200)',
        _light: {
          bgColor: `${props.colorScheme}.50`,
          color: `${props.colorScheme}.700`,
          '--tag-stroke-color': 'var(--chakra-colors-blackAlpha-50)',
        },
      },
    }),
    outline: (props) => ({
      container: {
        color: `${props.colorScheme}.200`,
        '--tag-stroke-color': `var(--chakra-colors-${props.colorScheme}-400)`,
        _light: {
          color: `${props.colorScheme}.600`,
          '--tag-stroke-color': `var(--chakra-colors-${props.colorScheme}-200)`,
        },
      },
    }),
  },
};
