import {
  Box,
  Button,
  Flex,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { IconButton } from '@gamma/buttons';
import { MuiIcon } from '@gamma/icons';
import { Input, Switch } from '@gamma/form-fields';
import { TrashIcon } from '@gamma/icons';
import { URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  ElasticsearchBulk,
  ElasticsearchExporter,
  ElasticsearchExporterAuth,
  ExporterTLSInfo,
  GET_EXPORTERS,
  useSaveExporter,
} from '@gamma/investigator/queries';
import { Alert } from '@gamma/overlay';
import _ from 'lodash';
import {
  FormEvent,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSetExporterFormState } from '../../../Hooks';

const { INVESTIGATOR_DOCS_ALERT_EXPORT, ELASTIC_SEARCH_DOCS_HTTP_EXPORTERS } =
  URLS;

interface ElasticsearchFormProps {
  isUpdating: boolean;
  deleteLoading: boolean;
  onDeleteOpen: () => void;
  clearInProgress: () => void;
  activeExporter: ElasticsearchExporter | null;
  setIsUpdating: (isUpdating: boolean) => void;
  setIsSaveLoading: (isSaveLoading: boolean) => void;
  setIsSaveDisabled: (isSaveDisabled: boolean) => void;
  setIsFormUnchanged: (isFormUnchanged: boolean) => void;
  setIsCancelDisabled: (isCancelDisabled: boolean) => void;
  setFormRef: (formRef: RefObject<HTMLFormElement> | null) => void;
}

interface AlertExportData {
  name: string;
  enabled: boolean;
  auth: ElasticsearchExporterAuth;
  bulk: ElasticsearchBulk;
  endpoint: string;
  tls: ExporterTLSInfo;
}

export const ElasticSearchForm = ({
  isUpdating,
  setFormRef,
  onDeleteOpen,
  deleteLoading,
  setIsUpdating,
  activeExporter,
  clearInProgress,
  setIsSaveLoading,
  setIsSaveDisabled,
  setIsFormUnchanged,
  setIsCancelDisabled,
}: ElasticsearchFormProps) => {
  const { register, handleSubmit, setValue, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      enabled: false,
      auth: {
        user: '',
        password: '',
      },
      bulk: {
        index: '',
      },
      endpoint: '',
      tls: {
        verify_certificate: false,
      },
    },
  });

  const { enabled: enabledLabel, disabled: disabledLabel } =
    i18n.pages.integrations;

  const {
    forms,
    elasticsearchForm,
    userGuide,
    firewallRules,
    tooltips,
    toast,
  } = i18n.pages.alertExport;

  const { elasticDocumentation, elasticDocumentationLink } = elasticsearchForm;

  const { saveSuccessDesc, saveSuccessTitle, saveFailDesc, saveFailTitle } =
    toast;

  const navigate = useNavigate();
  const showToastMessage = useToast();
  const { user } = useContext(AuthContext);
  const tenant = user?.attributes['custom:tenant_id'];

  const formData = watch();

  const formRef = useRef<HTMLFormElement>(null);

  const [exportConfig, setExportConfig] = useState<
    AlertExportData | ElasticsearchExporter | null
  >(activeExporter as ElasticsearchExporter);
  const [shouldDisableSave, setShouldDisableSave] = useState(true);
  const [showAuthPasswordType, setShowAuthPasswordType] =
    useState<boolean>(false);

  const { name, enabled, tls, endpoint, auth, bulk } = exportConfig || {};

  const fillForm = useCallback(() => {
    setValue('name', name || '');
    setValue('enabled', enabled || false);
    setValue('tls', tls || { verify_certificate: false });
    setValue('endpoint', endpoint || '');
    setValue(
      'auth',
      auth || {
        user: '',
        password: '',
      },
    );
    setValue(
      'bulk',
      bulk || {
        index: '',
      },
    );
  }, [setValue, name, enabled, tls, endpoint, bulk, auth]);

  useEffect(() => {
    setExportConfig(activeExporter as ElasticsearchExporter);
  }, [activeExporter]);

  useEffect(() => {
    fillForm();
  }, [activeExporter, exportConfig, fillForm]);

  const [saveExporter, { loading: saveLoading, error: saveError }] =
    useSaveExporter({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_EXPORTERS,
        },
      ],
      onCompleted: (data) => {
        const { saveExporter } = data;
        const { exporter_id } = saveExporter;
        setShouldDisableSave(true);
        setIsUpdating(false);
        showToastMessage({
          status: 'success',
          title: saveSuccessTitle,
          description: saveSuccessDesc,
          isClosable: true,
          position: 'bottom-right',
        });
        clearInProgress();
        navigate(exporter_id);
      },
      onError: (error) => {
        setShouldDisableSave(false);
        setIsUpdating(false);
        showToastMessage({
          status: 'error',
          title: saveFailTitle,
          description: saveFailDesc,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const formIsPopulated =
    formData.name !== '' &&
    formData.endpoint !== '' &&
    formData.bulk.index !== '' &&
    formData.auth.user !== '' &&
    formData.auth.password !== '';

  const formIsUnchanged = _.isEqual(formData, {
    name,
    enabled,
    endpoint,
    tls,
    bulk,
    auth,
  });

  const isSaveDisabled = formIsUnchanged || !formIsPopulated || saveLoading;

  useEffect(() => {
    setShouldDisableSave(isSaveDisabled);
  }, []);

  const submitAlertExport = async (data: AlertExportData) => {
    setShouldDisableSave(false);
    setIsUpdating(true);
    setExportConfig(data);
    const { name, enabled, tls, auth, bulk, endpoint } = data;
    const input = {
      tenant,
      name,
      enabled,
      exporter: 'elasticsearch',
      elasticsearch: {
        auth: {
          user: auth.user,
          password: auth.password,
        },
        bulk: {
          index: bulk.index,
        },
        endpoint,
        tls: {
          verify_certificate: tls.verify_certificate,
        },
      },
      exporter_id: activeExporter?.exporter_id || null,
    };
    saveExporter({
      variables: {
        input,
      },
    });
  };

  const handleVerifySSLChange = async (
    event: FormEvent<HTMLInputElement>,
    onChange: (val: boolean) => void,
  ) => {
    const isChecked = (event.target as HTMLInputElement).checked;
    onChange(isChecked);
    setValue('tls', { ...tls, verify_certificate: isChecked });
  };

  const togglePasswordType = () => {
    if (isUpdating) return;
    setShowAuthPasswordType(!showAuthPasswordType);
  };

  useSetExporterFormState({
    formRef,
    isUpdating,
    saveLoading,
    deleteLoading,
    isSaveDisabled,
    formIsUnchanged,
    shouldDisableSave,
    setFormRef,
    setIsSaveLoading,
    setIsSaveDisabled,
    setIsFormUnchanged,
    setIsCancelDisabled,
  });

  return (
    <>
      <VStack my={2} spacing={4} alignItems="start">
        <Text textStyle="body-md">
          {elasticDocumentation}{' '}
          <Link
            isExternal
            color="blue.400"
            textDecoration="none"
            href={ELASTIC_SEARCH_DOCS_HTTP_EXPORTERS}
          >
            {elasticDocumentationLink}
          </Link>
          .
        </Text>
        <Alert variant="subtle">
          <Text textStyle="body-md">
            {firewallRules}{' '}
            <Link
              isExternal
              color="blue.400"
              textDecoration="none"
              href={`${INVESTIGATOR_DOCS_ALERT_EXPORT}#elastic`}
            >
              {userGuide}
            </Link>
            .
          </Text>
        </Alert>
      </VStack>
      <VStack
        as="form"
        spacing={4}
        ref={formRef}
        align="flex-start"
        id="alertExportForm"
        onSubmit={handleSubmit(submitAlertExport)}
      >
        <Controller
          name="enabled"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <Box w="100%">
              <Switch
                inline
                name={name}
                isChecked={value}
                onChange={onChange}
                data-testid="exportSwitch"
                isDisabled={isUpdating}
                label={formData.enabled ? enabledLabel : disabledLabel}
              />
            </Box>
          )}
        />
        <Flex w="100%">
          <Input
            {...register('name', { required: true })}
            isRequired
            label={forms.exporterName}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('endpoint', {
              required: true,
            })}
            isRequired
            label={forms.url}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('auth.user', { required: true })}
            isRequired
            label={forms.username}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('auth.password', {
              required: true,
            })}
            isRequired
            label={forms.password}
            isDisabled={isUpdating}
            type={showAuthPasswordType ? 'text' : 'password'}
            rightElement={
              <Button variant="unstyled" isDisabled={isUpdating}>
                <MuiIcon cursor="pointer" onClick={togglePasswordType}>
                  {showAuthPasswordType ? 'visibility' : 'visibility_off'}
                </MuiIcon>
              </Button>
            }
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('bulk.index', { required: true })}
            isRequired
            label={forms.index}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%" alignItems="end" justifyContent="space-between">
          <Controller
            name="tls"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <Switch
                name={name}
                label={forms.verifySsl}
                tooltip={tooltips.verifySsl}
                isChecked={value.verify_certificate}
                onChange={(event: FormEvent<HTMLInputElement>) =>
                  handleVerifySSLChange(event, (val) =>
                    onChange({ verify_certificate: val }),
                  )
                }
                data-testid="sslSwitch"
                isDisabled={isUpdating}
              />
            )}
          />
          {!activeExporter?.inProgress && (
            <IconButton
              size="sm"
              variant="solid"
              colorScheme="red"
              aria-label={forms.deleteBtn}
              isDisabled={saveLoading || deleteLoading}
              data-testid="alert-exporter-delete-button"
              onClick={() => {
                setIsUpdating(true);
                onDeleteOpen();
              }}
              icon={
                deleteLoading ? (
                  <Spinner size={'xs'} />
                ) : (
                  <TrashIcon boxSize={5} />
                )
              }
            />
          )}
        </Flex>
      </VStack>
    </>
  );
};
