import { Button, ButtonGroup, Flex, useToast } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertAndDetectionTenantInfo,
  Detection,
  GET_COMMENTS_FOR_PARENT_PAGINATED,
  GetCommentsForParentVariables,
  useCreateComment,
} from '@gamma/investigator/queries';
import { useContext } from 'react';

import { DocumentNode } from '@apollo/client';
import { TextArea } from '@gamma/form-fields';
import { SlideDrawerContext } from '@gamma/investigator/context';
import { Alert } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { SantizedDetection } from '../../Detections';

const {
  toasts,
  detection: { addNote, note, close, noteRequired, noteTooLong },
} = i18n.pages.detections;

export interface AddNoteContentProps {
  onClose?: () => void;
  refetchMain?: boolean;
  detectionData?: Detection | SantizedDetection;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
}

interface AddNoteFormValues {
  note: string;
}

export const AddNoteContent = ({
  onClose: closePopup,
  refetchMain,
  detectionData,
}: AddNoteContentProps) => {
  const showToastMessage = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsNotesDrawerLoading } = useContext(SlideDrawerContext);

  const { detection_id, tenant, tenant_info } = detectionData || {};
  const { tenant_id } = tenant_info as AlertAndDetectionTenantInfo;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddNoteFormValues>();

  const onClose = () => {
    reset();
    closePopup && closePopup();
  };

  const refetchQueries: {
    query: DocumentNode;
    variables: GetCommentsForParentVariables;
  }[] = [
    {
      query: GET_COMMENTS_FOR_PARENT_PAGINATED,
      variables: {
        parent: {
          parent_object_id: detection_id ?? '',
          parent_object_type: 'detection',
        },
        size: 10,
        sort: [{ field: 'created_at', direction: 'desc' }],
      },
    },
  ];

  const [
    createComment,
    { loading: createCommentLoading, error: createCommentError },
  ] = useCreateComment({
    awaitRefetchQueries: true,
    refetchQueries: refetchQueries,
    onCompleted: (data) => {
      showToastMessage({
        status: 'success',
        title: toasts.success,
        description: toasts.addNoteSuccess,
        isClosable: true,
        position: 'bottom-right',
      });
      onClose && onClose();
      if (refetchMain) {
        setSearchParams((params) => {
          params.set('refetch_main', 'true');
          return params;
        });
      }
      setIsNotesDrawerLoading && setIsNotesDrawerLoading(false);
    },
    onError: () => {
      console.error(createCommentError);
      showToastMessage({
        status: 'error',
        title: toasts.error,
        description: toasts.addNoteError,
        isClosable: true,
        position: 'bottom-right',
      });
      setIsNotesDrawerLoading && setIsNotesDrawerLoading(false);
    },
  });

  const onSubmit = (formValues: AddNoteFormValues) => {
    createComment({
      variables: {
        comment: {
          comment: formValues.note,
          parent: {
            parent_object_type: 'detection',
            parent_object_id: detection_id || '',
          },
          tenant: tenant || tenant_id || '',
        },
      },
    });
    setIsNotesDrawerLoading && setIsNotesDrawerLoading(createCommentLoading);
    reset();
  };

  return createCommentLoading ? (
    <GraphQLReqStatus loading={true} />
  ) : (
    <>
      <Controller
        control={control}
        name="note"
        render={({ field: { onChange, name, value } }) => (
          <TextArea
            onChange={onChange}
            name={name}
            label={note}
            isRequired={true}
            value={value}
          />
        )}
        rules={{
          required: noteRequired,
          validate: (value) => {
            if (!value.trim()) return noteRequired;
            if (value.length > 10000) return noteTooLong;
            return true;
          },
        }}
      />

      {errors.note && (
        <div style={{ marginTop: '0.5rem' }}>
          <Alert variant="subtle" status="error">
            {errors.note.message}
          </Alert>
        </div>
      )}
      <Flex justifyContent="end">
        <ButtonGroup marginTop={2}>
          <Button
            variant="solid"
            colorScheme="gray"
            data-testid="notes-close-button"
            onClick={onClose}
            isDisabled={createCommentLoading}
          >
            {close}
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            data-testid="submit-note-button"
            onClick={handleSubmit(onSubmit)}
            isDisabled={createCommentLoading}
            isLoading={createCommentLoading}
          >
            {addNote}
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  );
};
