import { useRef } from 'react';

interface UseQueryWithRetryOptions<T> {
  queryHook: any; // The Apollo query hook to use (e.g., usePollQueryJob)
  variables: Record<string, any>;
  skip: boolean;
  onCompleted: (data: T) => void;
  onError?: (error: any) => void;
  maxRetries?: number; // Default: 5
  retryDelayMs?: number; // Default: 1000ms
}

export const useQueryWithRetry = <T>({
  queryHook,
  variables,
  skip,
  onCompleted,
  onError,
  maxRetries = 5,
  retryDelayMs = 1000,
}: UseQueryWithRetryOptions<T>) => {
  const retryCount = useRef(0);

  const { loading, refetch } = queryHook({
    variables,
    skip,
    onCompleted: (data: T) => {
      const { cancelled, done } = (data as any)?.pollQueryJob; // Assuming all queries return `cancelled` and `done`

      if (!cancelled && !done) {
        if (retryCount.current < maxRetries) {
          retryCount.current += 1;
          setTimeout(() => {
            refetch();
          }, retryDelayMs);
        }
      } else {
        // Reset retry count when query succeeds
        retryCount.current = 0;
        onCompleted(data);
      }
    },
    onError: (error: any) => {
      retryCount.current = 0; // Reset retry count on error
      onError && onError(error);
    },
  });

  return { loading, refetch };
};
