import {
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
  HStack,
  Heading,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ReactNode } from 'react';

export interface PopoverProps extends ChakraPopoverProps {
  title: ReactNode;
  subtitle?: ReactNode;
  titleIcon?: string;
  body: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  width?: string;
}

export const Popover = ({
  title,
  subtitle,
  titleIcon,
  body,
  footer,
  children,
  width,
  ...rest
}: PopoverProps) => {
  return (
    <ChakraPopover {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        justifyItems="start"
        width={width ? width : 'auto'}
        onClick={(e) => e.stopPropagation()}
      >
        <PopoverArrow />
        <PopoverHeader>
          <Stack spacing={1}>
            <HStack>
              {titleIcon && <MuiIcon>{titleIcon}</MuiIcon>}
              <Heading textStyle="body-md-bold">{title}</Heading>
            </HStack>
            {subtitle && <Text color="text.secondary">{subtitle}</Text>}
          </Stack>
          <PopoverCloseButton>
            <MuiIcon>close</MuiIcon>
          </PopoverCloseButton>
        </PopoverHeader>
        <PopoverBody textAlign="left" textStyle="body-md">
          {body}
        </PopoverBody>
        {footer && <PopoverFooter textAlign="left">{footer}</PopoverFooter>}
      </PopoverContent>
    </ChakraPopover>
  );
};
