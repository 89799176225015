import { Box, Flex, Tag, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ServiceNowLogo } from '@gamma/icons';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  useQueryEscalationConfigurations,
  useQueryEscalationConfigurationsPublic,
} from '@gamma/investigator/queries';
import { Panel } from '@gamma/layout';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { useContext, useState } from 'react';

import { ServiceNowSettingsCardModal } from '../ServiceNowSettingsCardModal';

const { integrations } = i18n.pages;
const { enabled, available } = integrations;

const { serviceNow, youCanSendDetectionsManually } = integrations.serviceNow;

export const ServiceNowSettingsCard = () => {
  const { userRole } = useContext(AuthContext);

  const [isEnableChanged, setIsEnableChanged] = useState<boolean>(false);
  const [showHoverBackGround, setShowHoverBackGround] =
    useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setIsEnableChanged(false);
    },
  });

  const escalationConfigurationQuery =
    userRole === 'admin'
      ? useQueryEscalationConfigurations
      : useQueryEscalationConfigurationsPublic;

  const { loading, error, data } = escalationConfigurationQuery({});

  const serviceNowConfiguration =
    data?.queryEscalationConfigurations?.escalation_configurations?.[0];

  if (error) {
    return (
      <GraphQLReqStatus
        error={error}
        loading={false}
        isBackground={true}
      ></GraphQLReqStatus>
    );
  }

  return (
    <>
      <Panel
        w="300px"
        h="200px"
        onClick={onOpen}
        layerStyle="first"
        _hover={{ bgColor: 'blue.alpha500.10' }}
        onMouseEnter={() => setShowHoverBackGround(true)}
        onMouseLeave={() => setShowHoverBackGround(false)}
        cursor={userRole !== 'admin' || loading ? 'not-allowed' : 'pointer'}
      >
        <VStack spacing={loading ? 4 : 8}>
          <Flex
            h="92px"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {showHoverBackGround ? (
              <VStack>
                <Text textStyle="h4">{serviceNow}</Text>
                <Text textAlign="center" textStyle="body-md" mt={2}>
                  {youCanSendDetectionsManually}
                </Text>
              </VStack>
            ) : (
              <>
                <ServiceNowLogo />
                <Text textStyle="h4">{serviceNow}</Text>
              </>
            )}
          </Flex>
          {loading ? (
            <Box>
              <LoadingSpinner size="sm" />
            </Box>
          ) : (
            <Tag
              backgroundColor={
                serviceNowConfiguration?.enabled ? 'green.600' : 'gray.600'
              }
              justifyContent="center"
              alignItems="center"
              data-testid="service-now-configuration-status"
            >
              {serviceNowConfiguration?.enabled ? (
                <MuiIcon mr={2}>check_circle</MuiIcon>
              ) : (
                <MuiIcon mr={2}>add_circle</MuiIcon>
              )}
              <Text textStyle="body-sm">
                {serviceNowConfiguration?.enabled ? enabled : available}
              </Text>
            </Tag>
          )}
        </VStack>
      </Panel>
      {userRole === 'admin' && isOpen && !loading && (
        <ServiceNowSettingsCardModal
          isOpen={isOpen}
          onClose={onClose}
          serviceNowConfiguration={serviceNowConfiguration}
          isEnableChanged={isEnableChanged}
          setIsEnableChanged={setIsEnableChanged}
        />
      )}
    </>
  );
};
