import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { SnippetBox } from '@gamma/display';
import { TimestampCell } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  IQueryAlertsPaginated,
  QUERY_ALERTS_SURICATA_PAYLOAD,
  QUERY_ALERTS_SURICATA_PAYLOAD_LLM_DISABLED,
  SessionSummaryData,
} from '@gamma/investigator/queries';
import { AccordionPanel, Board, Column } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';

import { useQuery } from '@apollo/client';
import { ChatGPTIcon } from '@gamma/icons';
import { Modal } from '@gamma/overlay';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components';
import { Dispatch, SetStateAction, useState } from 'react';
import dedent from 'ts-dedent';
import { SanitizedSuricataAlert } from '../../../DetectionAlerts';
import { InvestigateLogsTableModal } from '../InvestigateLogsTableModal';
import { LogscaleDetails } from '../LogscaleDetails';
import { LogscaleMenuButton } from '../LogscaleMenuButton';
import { SessionSummary } from '../SessionSummary';
import { PayloadTabs } from './PayloadTabs';

const { alerts, detection } = i18n.pages.detections;

interface AlertRowSuricataProps {
  item: SanitizedSuricataAlert;
  logScaleQuery?: string;
  showContent?: boolean;
}

interface AlertRowSplitSuricataProps {
  item: SanitizedSuricataAlert;
  loading: boolean | undefined;
  suricataRuleText: string;
  setDownloadUrl?: Dispatch<SetStateAction<string>>;
  detectionData: Detection;
  getAppConfigurationsData: string | null;
  isGPTPrivateDataEnabled: boolean;
  logScaleQuery?: string;
}

export const SuricataButtonsCol = ({
  item,
  showContent,
  logScaleQuery,
}: AlertRowSuricataProps) => {
  const {
    isOpen: investigateModalIsOpen,
    onOpen: investigateModalOnOpen,
    onClose: investigateModalOnClose,
  } = useDisclosure();
  const formattedTime = moment.unix(item.alert_timestamp?.observed).format();
  const start = moment(formattedTime).subtract(1, 'hours').unix();
  const end = moment(formattedTime).add(24, 'hours').unix();

  const { alert_timestamp } = item;

  const searchQueryString = `?configured-columns.uid.render-as=gotolink&query=${
    logScaleQuery
      ? encodeURIComponent(logScaleQuery)
      : `uid%3D${
          item?.uid
        }%20OR%20%28%23path%3Dsuricata_corelight%20AND%20id.orig_h%3D${encodeURIComponent(
          item?.alert_entity?.entity_name,
        )}%20AND%20alert.signature%20%3D%20%22${encodeURIComponent(
          item?.alert_info?.alert_name,
        )}%22%29%0A%7C%20table%28%5Buid%2C%20%23path%2C%20id.orig_h%2C%20id.resp_h%2C%20id.resp_p%2C%20local_orig%2C%20local_resp%2C%20service%2C%20orig_bytes%2C%20resp_bytes%2C%20qtype_name%2C%20rcode_name%2C%20query%2C%20answers%2C%20filename%2C%20rx_host%2C%20mime_type%2C%20seen_bytes%2C%20md5%2C%20sha256%2C%20method%2C%20status_code%2C%20host%2C%20uri%2C%20user_agent%2C%20client_headers%2C%20server_name%2C%20subject%2C%20issuer%2C%20established%2C%20validation_status%2C%20cert_chain_fps%2C%20alert.signature_id%2C%20alert.signature%5D%29`
  }&live=false&fullscreen=false&widgetType=table-view&newestAtBottom=true&showOnlyFirstLine=false&start=${start}&end=${end}&humioStart=${
    alert_timestamp && (alert_timestamp.observed - 300) * 1000
  }&humioEnd=${alert_timestamp && (alert_timestamp.observed + 300) * 1000}`;

  return (
    <Flex flexGrow={1}>
      <LogscaleMenuButton
        searchQueryString={searchQueryString}
        investigateModalOnOpen={investigateModalOnOpen}
        showContent={showContent}
      />
      <InvestigateLogsTableModal
        isOpen={investigateModalIsOpen}
        onClose={investigateModalOnClose}
        onOpen={investigateModalOnOpen}
        alertsName={item?.alert_info?.alert_name}
        alertsUid={item?.uid}
        alertsTimestamp={formattedTime}
        alertsTenant={item?.tenant}
        logScaleQuery={logScaleQuery}
      />
    </Flex>
  );
};

export const AlertRowSuricata = ({
  item,
  logScaleQuery,
}: AlertRowSuricataProps) => {
  return (
    <HStack spacing={4}>
      <VStack alignItems="start">
        <TimestampCell
          timestamp={item?.alert_timestamp?.observed}
          isFromNow={false}
        />
        <HStack color="text.200">
          <Text textStyle="body-md">{item?.alert_info?.alert_name}</Text>
          <Text textStyle="body-md">|</Text>
          <Text textStyle="body-md">{item?.alert_entity?.entity_name}</Text>
        </HStack>
      </VStack>
      <SuricataButtonsCol item={item} logScaleQuery={logScaleQuery} />
    </HStack>
  );
};

export const AlertRowSplitSuricata = ({
  getAppConfigurationsData,
  item,
  loading,
  suricataRuleText,
  setDownloadUrl,
  detectionData,
  isGPTPrivateDataEnabled,
  logScaleQuery,
}: AlertRowSplitSuricataProps) => {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [payload, setPayload] = useState<string>('');
  const [payloadSummary, setPayloadSummary] = useState<string>('');
  const [sessionSummaryData, setSessionSummaryData] = useState<
    SessionSummaryData | undefined
  >();
  const [availableFlag, setAvailableFlag] = useState<boolean>(false);
  const {
    isOpen: payloadIsOpen,
    onOpen: payloadOnOpen,
    onClose: payloadOnClose,
  } = useDisclosure();

  const time = moment.unix(item?.alert_timestamp?.observed);
  const formattedTime = time.format();

  const isLLMSummary = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags.llm_summary
    : false;

  const query =
    isLLMSummary && isGPTPrivateDataEnabled
      ? QUERY_ALERTS_SURICATA_PAYLOAD
      : QUERY_ALERTS_SURICATA_PAYLOAD_LLM_DISABLED;

  const noFilterVariables = {
    query: `{"query": {"bool": {"must": [{"term": {"alert_info.content_id": "${
      detectionData.alert_info?.content_id
    }"}}, {"term": {"alert_entity.entity_id": "${
      detectionData.alert_entity?.entity_id
    }"}}, {"range": {"alert_timestamp.end": {"gte": ${
      item.alert_timestamp.observed
    }}}}, {"range": {"alert_timestamp.start": {"lte": ${
      item.alert_timestamp.observed
    }}}}${detectionData.tenant_info?.tenant_id ? ',' : ''}${
      detectionData?.tenant_info?.tenant_id
        ? `{"terms":{"tenant":["${detectionData.tenant_info?.tenant_id}"]}}`
        : ''
    }]}}}`,
    log_search_client_session_id: localStorage.getItem('session_id_token'),
  };

  const { loading: payloadLoading } = useQuery<IQueryAlertsPaginated>(query, {
    skip:
      !detectionData ||
      !detectionData?.tenant_info?.tenant_id ||
      !detectionData?.alert_entity?.entity_id,
    variables: noFilterVariables,
    onCompleted: (data) => {
      const alert = data?.queryAlertsPaginated?.alerts?.[0];
      setPayload(alert?.payload ?? '');
      setPayloadSummary(alert?.payload_summary ?? '');
      setSessionSummaryData(alert?.session_summary);
    },
    onError: (error) => {
      if (error.message.includes('Missing payload within alert'))
        setAvailableFlag(true);
    },
  });

  if (loading) {
    return (
      <Center w="100%">
        <GraphQLReqStatus loading={loading} />
      </Center>
    );
  }

  return (
    <VStack mb={2} w="100%" alignItems="start" gap={0}>
      <Box w="100%">
        <AccordionPanel
          title={detection.alertDetails}
          data-testid="suricata-alerts-wrapper"
        >
          <DetailsGrid>
            <DetailsGridRow title={alerts.name}>
              <Text overflowWrap="anywhere">
                {item?.alert_info?.alert_name}
              </Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.timestamp}>
              <Text>{formattedTime}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.uid}>
              <Text>{item?.uid}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.sourceIp}>
              <Text>{item?.alert_entity?.entity_name}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.destinationIp}>
              <Text>{item?.destination_ip}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.type}>
              <Text>{item?.alert_info?.alert_type}</Text>
            </DetailsGridRow>
          </DetailsGrid>
          {isLLMSummary && isGPTPrivateDataEnabled && (
            <SessionSummary
              loading={payloadLoading}
              sessionSummaryData={sessionSummaryData}
            />
          )}
        </AccordionPanel>
      </Box>

      {isLLMSummary && isGPTPrivateDataEnabled ? (
        <Box w="100%">
          <AccordionPanel
            title={
              <HStack>
                <ChatGPTIcon boxSize={5} />{' '}
                <Text>{detection.payloadSummary}</Text>
              </HStack>
            }
          >
            {payloadLoading && (
              <Center w="100%">
                <GraphQLReqStatus loading={payloadLoading} />
              </Center>
            )}
            {availableFlag && <Text>{detection.payloadAvailableMessage}</Text>}
            {payloadSummary && (
              <>
                <Text noOfLines={seeMore ? undefined : 4}>
                  {payloadSummary}
                </Text>
                <VStack alignItems="start">
                  <Button variant="link" onClick={() => setSeeMore(!seeMore)}>
                    {seeMore
                      ? alerts.suricata.seeLess
                      : alerts.suricata.seeMore}
                  </Button>
                  <Button
                    variant="solid"
                    colorScheme="gray"
                    mt={2}
                    onClick={payloadOnOpen}
                  >
                    {alerts.suricata.payload.viewPayload}
                  </Button>
                </VStack>
              </>
            )}
            {!payloadLoading && !payloadSummary && !availableFlag && (
              <Text>{detection.payloadAvailableMessage}</Text>
            )}
          </AccordionPanel>
        </Box>
      ) : (
        <Box w="100%">
          <AccordionPanel title={detection.payload}>
            {payloadLoading && (
              <Center w="100%">
                <GraphQLReqStatus loading={payloadLoading} />
              </Center>
            )}
            {payload && (
              <>
                <PayloadTabs payload={payload} />
                <Button
                  variant="solid"
                  colorScheme="gray"
                  mt={2}
                  onClick={payloadOnOpen}
                >
                  {alerts.suricata.payload.viewPayload}
                </Button>
              </>
            )}
            {!payloadLoading && !payload && (
              <Text>{detection.payloadAvailableMessage}</Text>
            )}
          </AccordionPanel>
        </Box>
      )}
      <Box w="100%">
        <LogscaleDetails
          alertsUid={item?.uid}
          alertsName={item.alert_info?.alert_name}
          alertsTimestamp={formattedTime}
          setDownloadUrl={setDownloadUrl}
          alertsTenant={item?.tenant}
          logScaleQuery={logScaleQuery}
        />
      </Box>

      <Modal
        size="xl"
        isOpen={payloadIsOpen}
        onClose={payloadOnClose}
        title={detection.payload}
        body={
          <Board>
            {suricataRuleText && (
              <Column>
                <Text fontSize="large" py={3}>
                  {detection.rule}
                </Text>
                <SnippetBox
                  snippet={dedent`${suricataRuleText}`}
                  isCopyable={true}
                  height="160px"
                  overflow="scroll"
                />
              </Column>
            )}
            {payload && (
              <Column>
                <Text fontSize="large" py={3}>
                  {detection.payload}
                </Text>
                <PayloadTabs isModal={true} payload={payload} />
              </Column>
            )}
          </Board>
        }
        footer={
          <Button variant="solid" colorScheme="gray" onClick={payloadOnClose}>
            {detection.close}
          </Button>
        }
      />
    </VStack>
  );
};
