import { Tag, TagLabel, Text, Tooltip } from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { MuiIcon } from '@gamma/icons';
import { TimestampCell } from '@gamma/investigator/components';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  IGetGeneralSettings,
  useGetGeneralSettings,
} from '@gamma/investigator/queries';
import { AccordionPanel, FlushPanelContent, Panel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import { useState } from 'react';
import { CellProps } from 'react-table';
import { UpdateTenantDisplayName } from '../UpdateTenantDisplayName';

export interface TenantDisplayNameConfig {
  display_name: string;
  updated_on: string | number;
  updated_by: string;
}

const { descriptionValue, title, tableColumns } = i18n.pages.tenantSettings;

export const TenantSettingsDashboard = () => {
  const [tenantDisplayNameConfig, setTenantDisplayNameConfig] = useState<
    Array<TenantDisplayNameConfig>
  >([{ display_name: 'N/A', updated_by: 'N/A', updated_on: 'N/A' }]);

  const { loading, error } = useGetGeneralSettings({
    onCompleted: (data: IGetGeneralSettings) => {
      if (data?.getGeneralSettings?.tenant_display_name_info) {
        setTenantDisplayNameConfig([
          data?.getGeneralSettings?.tenant_display_name_info,
        ]);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const dataTableColumns: any = [
    {
      Header: tableColumns.description,
      disableSortBy: true,
      Cell: () => <Text>{descriptionValue}</Text>,
    },
    {
      Header: tableColumns.value,
      disableSortBy: true,
      accessor: 'display_name',
      Cell: (props: CellProps<TenantDisplayNameConfig>) =>
        props.value === null ? 'N/A' : props?.value,
    },
    {
      Header: tableColumns.modifiedDate,
      disableSortBy: true,
      accessor: 'updated_on',
      Cell: (props: CellProps<TenantDisplayNameConfig>) =>
        props.value != null ? (
          <TimestampCell
            timestamp={props.value}
            isFromNow={false}
            format="ll"
          />
        ) : (
          <Text>N/A</Text>
        ),
    },
    {
      Header: tableColumns.modifiedBy,
      disableSortBy: true,
      accessor: 'updated_by',
      Cell: (props: CellProps<TenantDisplayNameConfig>) =>
        props.value != null ? (
          <Tooltip
            label={props.value}
            placement="top"
            isDisabled={props?.value?.length <= 20}
          >
            <Tag size="sm" colorScheme="purple" variant="solid">
              <MuiIcon color="text.primary">account_circle</MuiIcon>
              <TagLabel ml={2} isTruncated={props?.value?.length > 20}>
                {props.value}
              </TagLabel>
            </Tag>
          </Tooltip>
        ) : (
          'N/A'
        ),
    },
    {
      Header: tableColumns.actions,
      Cell: (props: CellProps<TenantDisplayNameConfig>) => (
        <UpdateTenantDisplayName
          tenantDisplayNameConfig={tenantDisplayNameConfig}
        />
      ),
    },
  ];

  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }

  return (
    <AccordionPanel
      title={title}
      reduceMotion={true}
      storageKey="tenant-settings-panel"
      data-testid="tenant-settings-panel"
    >
      <FlushPanelContent>
        <DataTable
          data={tenantDisplayNameConfig}
          columns={dataTableColumns}
          isPaginable={false}
          isLined={true}
        />
      </FlushPanelContent>
    </AccordionPanel>
  );
};
