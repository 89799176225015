import {
  Box,
  Center,
  Flex,
  HStack,
  Link,
  Stack,
  StylesProvider,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import * as AllIcons from '@gamma/icons';
import { CorelightLogo } from '@gamma/icons';
import { Panel } from '@gamma/layout';
import { createContext, ReactNode, useState } from 'react';

export interface CardPageProps {
  /** The logo to place at the top of the page */
  logo?: ReactNode;
  /** The card content */
  children: ReactNode;
  /** The content to show underneath the car, above the links */
  footer?: ReactNode;
  /** The links to render underneath the card */
  links?: {
    icon: keyof Omit<typeof AllIcons, 'Illustrations' | 'MuiIcon'>;
    name: string;
    url: string;
    isExternal?: boolean;
  }[];
  'data-testid'?: string;
}

export interface ICardPageContext {
  hasIllustration: boolean;
  setHasIllustration: (value: boolean) => void;
}

export const CardPageContext = createContext<ICardPageContext>({
  hasIllustration: false,
  setHasIllustration: (value: boolean) => null,
});

export const CardPage = ({
  logo = <CorelightLogo />,
  links,
  footer,
  children,
  'data-testid': dataTestId,
}: CardPageProps) => {
  const styles = useMultiStyleConfig('CardPage', {});
  const [hasIllustration, setHasIllustration] = useState<boolean>(false);

  return (
    <Center __css={styles.background} data-testid={dataTestId}>
      <Stack alignItems="center" spacing={4}>
        <Box height="24px">{logo}</Box>
        <Panel
          __css={styles.panel}
          p={6}
          w="354px"
          minH={hasIllustration ? '432px' : 'fit-content'}
        >
          <Flex
            flexDir="column"
            justifyContent="space-between"
            h="full"
            position="relative"
          >
            <CardPageContext.Provider
              value={{ hasIllustration, setHasIllustration }}
            >
              <StylesProvider value={styles}>{children}</StylesProvider>
            </CardPageContext.Provider>
          </Flex>
        </Panel>
        {footer && footer}
        <HStack w="full" justifyContent="space-evenly">
          {links?.map(({ icon, name, url, isExternal = false }) => {
            const Icon = AllIcons[icon];
            return (
              <Link
                fontSize="md"
                href={url}
                key={name}
                isExternal={isExternal}
                data-testid="card-page-link"
              >
                <Icon mr="1" color="blue.500" /> {name}
              </Link>
            );
          })}
        </HStack>
      </Stack>
    </Center>
  );
};
