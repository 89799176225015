import { flattenTokens } from '../../../util';

export const colors = flattenTokens({
  layer: {
    0: {
      _light: 'gray.50',
      _dark: 'gray.900',
      active: {
        _light: 'gray.100',
        _dark: 'gray.800',
      },
    },
    1: {
      _light: 'white',
      _dark: 'gray.800',
      active: {
        _light: 'gray.50',
        _dark: 'gray.700',
      },
    },
    2: {
      _light: 'gray.50',
      _dark: 'gray.700',
      active: {
        _light: 'gray.100',
        _dark: 'gray.600',
      },
    },
    3: {
      _light: 'white',
      _dark: 'gray.600',
      active: {
        _light: 'gray.50',
        _dark: 'gray.500',
      },
    },
  },
  border: {
    0: {
      _light: 'gray.300',
      _dark: 'gray.700',
    },
    1: {
      _light: 'gray.200',
      _dark: 'gray.600',
    },
    2: {
      _light: 'gray.100',
      _dark: 'gray.500',
    },
    3: {
      _light: 'gray.50',
      _dark: 'gray.400',
    },
    layer: {
      0: {
        _light: 'gray.200',
        _dark: 'gray.700',
      },
      1: {
        _light: 'gray.100',
        _dark: 'gray.700',
      },
      2: {
        _light: 'gray.200',
        _dark: 'gray.600',
      },
      3: {
        _light: 'gray.100',
        _dark: 'gray.500',
      },
    },
  },
  text: {
    primary: {
      _light: 'gray.900',
      _dark: 'gray.50',
    },
    secondary: {
      _light: 'gray.700',
      _dark: 'gray.200',
    },
    placeholder: {
      _light: 'gray.500',
      _dark: 'gray.500',
    },
    disabled: {
      _light: 'rgba(22, 22, 22, 0.25)',
      _dark: 'rgba(244, 244, 244, 0.25)',
    },
    link: {
      _light: 'blue.500',
      _dark: 'blue.300',
      hover: {
        _light: 'blue.600',
        _dark: 'blue.300',
      },
      action: {
        _light: 'blue.400',
        _dark: 'blue.500',
        hover: {
          _light: 'blue.500',
          _dark: 'blue.100',
        },
      },
    },
  },
  state: {
    selected: {
      _light: 'blue.400',
      _dark: 'blue.400',
      bg: {
        _light: 'gray.50',
        _dark: 'gray.800',
      },
    },
    disabled: {
      _light: 'gray.600',
      _dark: 'gray.500',
    },
    unknown: {
      _light: 'gray.600',
      _dark: 'gray.500',
    },
    success: {
      _light: 'green.400',
      _dark: 'green.400',
    },
    warning: {
      _light: 'yellow.700',
      _dark: 'yellow.400',
    },
    error: {
      _light: 'red.600',
      _dark: 'red.300',
    },
    info: {
      _light: 'blue.600',
      _dark: 'blue.600',
    },
  },
});
