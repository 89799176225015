import { Flex, Tooltip } from '@chakra-ui/react';
import { Checkbox } from '@gamma/form-fields';
import { CellProps, Hooks, Row } from 'react-table';

export interface UseTableSelectionColumnParams<
  DataType extends Record<string, unknown>,
> {
  rowIsSelectable?: (row: Row<DataType>) => boolean | string;
  isSelectable?: boolean;
}

export const useTableSelectionColumn =
  <DataType extends Record<string, unknown>>({
    isSelectable,
    rowIsSelectable = () => true,
  }: UseTableSelectionColumnParams<DataType>) =>
  (hooks: Hooks<DataType>) => {
    if (isSelectable) {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          disableResizing: true,
          minWidth: 40,
          width: 40,
          maxWidth: 40,
          padding: 0,
          Header: (headerProps) => {
            let allNonDisabledRowsChecked = false;
            const nonDisabledRows = headerProps.page.filter((row) =>
              rowIsSelectable?.(row),
            );

            if (nonDisabledRows.length) {
              allNonDisabledRowsChecked = !nonDisabledRows.some(
                (row) => !row.isSelected,
              );
            }

            const { checked, indeterminate, ...rest } =
              headerProps.getToggleAllPageRowsSelectedProps({
                checked: allNonDisabledRowsChecked,
                indeterminate: Boolean(
                  !allNonDisabledRowsChecked &&
                    nonDisabledRows.some((row) => row.isSelected),
                ),
                onChange: () => {
                  if (allNonDisabledRowsChecked) {
                    headerProps.rows
                      .filter((row) => row.isSelected)
                      .forEach((row) => {
                        headerProps.toggleRowSelected(row.id);
                      });
                  } else {
                    headerProps.toggleAllPageRowsSelected(true);
                  }
                },
              });
            return (
              <Checkbox
                isChecked={checked}
                isIndeterminate={indeterminate}
                data-testid="data-table-select-all"
                isLabelHidden
                {...rest}
              />
            );
          },
          Cell: (cellProps: CellProps<DataType>) => {
            const {
              checked,
              indeterminate,
              title: titleProp,
              ...rest
            } = cellProps.row.getToggleRowSelectedProps();
            const rowSelectableStatus = rowIsSelectable?.(cellProps.row);
            const isRowSelectable = rowSelectableStatus === true;
            const title =
              typeof rowSelectableStatus === 'string'
                ? rowSelectableStatus
                : titleProp;
            return (
              <Tooltip label={title}>
                <Flex>
                  <Checkbox
                    isChecked={isRowSelectable && checked}
                    isIndeterminate={indeterminate}
                    isDisabled={!isRowSelectable}
                    aria-label="Row Selected"
                    isLabelHidden
                    {...rest}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    data-testid="data-table-select-row"
                  />
                </Flex>
              </Tooltip>
            );
          },
        },
        ...columns,
      ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  };
