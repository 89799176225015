import { IconButton, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import {
  ExcludedEntity,
  QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
  useRemoveExcludedEntitiesFromAlertMetadata,
} from '@gamma/investigator/queries';
import { DeleteModal } from '@gamma/overlay';
import { LoadingSpinner } from '@gamma/progress';

import { QueryVariables } from '../../';

interface DeleteModalRowProps {
  title: string;
  type: string;
  tenant?: string;
  content_id?: string;
  original: ExcludedEntity;
  queryVariables: QueryVariables;
  refetchExcludedEntitiesPaginated: () => void;
}

export const DeleteModalRow = ({
  title,
  type,
  tenant,
  original,
  content_id,
  queryVariables,
  refetchExcludedEntitiesPaginated,
}: DeleteModalRowProps) => {
  const disclosure = useDisclosure();
  const showToastMessage = useToast();

  const {
    actionCannotBeUndone,
    deleteExcluded,
    successRes,
    errorRes,
    entityRemoved,
    entityNotRemoved,
  } = i18n.pages.system.alertCatalog;

  const [removeEntity, { loading: removeLoading }] =
    useRemoveExcludedEntitiesFromAlertMetadata({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
          variables: queryVariables,
        },
      ],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: successRes,
          description: entityRemoved,
          isClosable: true,
          position: 'bottom-right',
        });
        refetchExcludedEntitiesPaginated();
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: errorRes,
          description: entityNotRemoved,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  return (
    <DeleteModal
      {...disclosure}
      size="sm"
      body={''}
      hideBody={true}
      isFooterCentered={false}
      data-testid="delete-modal"
      title={deleteExcluded}
      subtitle={actionCannotBeUndone}
      onConfirm={() => {
        const input = {
          content_id,
          tenant,
          entity_name: original?.entity_name,
          entity_type: original?.entity_type,
          alert_name: title,
          alert_type: type,
        };
        if (tenant) {
          removeEntity({
            variables: {
              items: [input],
            },
          });
        }
      }}
    >
      <Tooltip label={'Delete'} placement="bottom-end">
        {removeLoading ? (
          <LoadingSpinner data-testid="delete-loading" size="sm" minH="auto" />
        ) : (
          <IconButton
            size="box-md"
            variant="solid"
            colorScheme="gray"
            icon={<MuiIcon>delete</MuiIcon>}
            aria-label={'Delete'}
            onClick={disclosure.onOpen}
            data-testid="delete-modal-trigger"
          />
        )}
      </Tooltip>
    </DeleteModal>
  );
};
