import {
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';
import { Label, LabelProps } from '../Components';

export interface TextAreaProps extends Omit<LabelProps, 'children'> {
  /** The name of the input field */
  name: string;
  /** The value of the input field. Used in conjunction with onChange to create a controlled component */
  value?: string;
  /** The placeholder text content to show when the input is valueless */
  placeholder?: string;
  id?: string;
  rows?: TextareaProps['rows'];
  /** The function triggered whenever the input value is updated */
  onChange?: TextareaProps['onChange'];
  /** The function triggered whenever the input is focused */
  onFocus?: TextareaProps['onFocus'];
  /** The function triggered whenever the input is blurred */
  onBlur?: TextareaProps['onBlur'];
  /** Node following the Input */
  afterInput?: ReactNode;
  /** Whether to make squiggly underlines under the stuff you mispelled */
  spellCheck?: boolean;
  /** The element to place inside the input field to the right */
  rightElement?: ReactNode;
  /** backgronud color */
  bg?: string;
  'data-testid'?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      name,
      placeholder,
      value,
      onFocus,
      onBlur,
      onChange,
      spellCheck,
      afterInput,
      rows,
      rightElement,
      'data-testid': dataTestId,
      bg,
      ...rest
    },
    ref,
  ) => (
    <Label name={name} {...rest}>
      <Grid templateColumns="1fr min-content" alignItems="center">
        <GridItem>
          <InputGroup>
            <Textarea
              ref={ref}
              placeholder={placeholder}
              value={value}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              name={name}
              spellCheck={spellCheck}
              data-testid={dataTestId}
              rows={rows}
              bg={bg}
              width="full"
            />
            {rightElement && (
              <InputRightElement>{rightElement}</InputRightElement>
            )}
          </InputGroup>
        </GridItem>
        {afterInput && <GridItem pl={4}>{afterInput}</GridItem>}
      </Grid>
    </Label>
  ),
);
