import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { URLS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  RouterLink,
  SidebarContext,
  SidebarFooterItem,
} from '@gamma/navigation';
import moment from 'moment';
import { useContext } from 'react';

export const CopyrightPrivacyPolicy = () => {
  const { isOpen } = useContext(SidebarContext);

  const { corelight, privacyPolicy: privacyPolicyLink } = i18n.navigation;

  return (
    <Tooltip shouldWrapChildren isDisabled={!isOpen}>
      <SidebarFooterItem
        icon={<MuiIcon color="gray.400">copyright</MuiIcon>}
        href={URLS.CORELIGHT_PRIVACY_POLICY}
        target="_self"
      >
        <HStack spacing={1} fontSize="sm" divider={<Box border={0}>|</Box>}>
          <Text>
            {moment().format('YYYY')} {corelight}
          </Text>
          <RouterLink
            color="text.link"
            data-testid="privacy-policy"
            to={URLS.CORELIGHT_PRIVACY_POLICY}
          >
            {privacyPolicyLink}
          </RouterLink>
        </HStack>
      </SidebarFooterItem>
    </Tooltip>
  );
};
