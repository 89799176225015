import {
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Input } from '@gamma/form-fields';
import { MuiIcon } from '@gamma/icons';
import { gammaContext } from '@gamma/theme';
import { mergeRefs } from '@gamma/util';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterProps } from 'react-table';

interface ColumnFilterPopoverForm {
  filterColumn: string;
}

export const ColumnFilterPopover = <DataType extends {}>({
  column: { filterValue, setFilter, id },
}: FilterProps<DataType>) => {
  const { i18n } = useContext(gammaContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const { handleSubmit, register, watch, setValue, reset } =
    useForm<ColumnFilterPopoverForm>();

  const filter = watch('filterColumn');

  // update local state whenever the column's filter value is changed
  useEffect(() => {
    setValue('filterColumn', filterValue ?? '');
  }, [filterValue, setValue]);

  const resetColumnFilter = (onClose: () => void) => () => {
    setFilter(undefined);
    onClose();
  };

  const onSubmit = useCallback(
    (onClose: () => void): SubmitHandler<ColumnFilterPopoverForm> =>
      ({ filterColumn }) => {
        setFilter(filterColumn);
        onClose();
      },
    [setFilter],
  );

  const {
    title,
    placeholder,
    reset: i18nReset,
    apply,
  } = i18n.tables.column.filter;

  const { ref, ...inputProps } = register('filterColumn');
  return (
    <Popover
      initialFocusRef={inputRef}
      placement="bottom-end"
      onClose={() => {
        reset({ filterColumn: filterValue ?? '' });
      }}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton
              aria-label="filter column"
              data-testid={`${id}-filter-column`}
              color={filterValue ? 'text.link' : 'text'}
              ml={2}
              icon={<MuiIcon size="sm">filter_list</MuiIcon>}
              variant="link"
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <form onSubmit={handleSubmit(onSubmit(onClose))}>
                <PopoverHeader border="none">
                  <Heading
                    textStyle="body-md"
                    fontWeight="normal"
                    data-testid={`${id}-title`}
                  >
                    {title}
                  </Heading>
                </PopoverHeader>
                <PopoverBody>
                  <Input
                    {...inputProps}
                    data-testid={`${id}-search-input`}
                    ref={mergeRefs(ref, inputRef)}
                    label={title}
                    placeholder={placeholder}
                    leftElement={<MuiIcon>search</MuiIcon>}
                    rightElement={
                      filter && (
                        <MuiIcon onClick={() => reset()} boxSize={5} my={-1}>
                          close
                        </MuiIcon>
                      )
                    }
                    isLabelHidden
                  />
                </PopoverBody>
                <PopoverFooter border="none">
                  <ButtonGroup
                    w="full"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="solid"
                      colorScheme="gray"
                      data-testid={`${id}-reset`}
                      onClick={resetColumnFilter(onClose)}
                      isDisabled={filterValue === undefined}
                    >
                      {i18nReset}
                    </Button>
                    <Button
                      data-testid={`${id}-submit`}
                      variant="solid"
                      type="submit"
                    >
                      {apply}
                    </Button>
                  </ButtonGroup>
                </PopoverFooter>
              </form>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
