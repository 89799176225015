import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export interface ParentInfo {
  parent_object_type: 'detection';
  parent_object_id: string;
}

export interface GetCommentsForParentSortParameters {
  field: 'created_at';
  direction: 'asc' | 'desc';
}

export interface GetCommentsForParentVariables {
  parent: ParentInfo;
  size: number;
  sort: GetCommentsForParentSortParameters[];
}

export interface Comment {
  comment_id: string;
  parent: ParentInfo;
  comment: string;
  author_alias: string;
  created_at: number;
}
export interface GetCommentsForParentData {
  total_count: number;
  offset: number;
  size: number;
  comments: Comment[];
}

export interface GetCommentsForParentResponse {
  getCommentsForParentPaginated: GetCommentsForParentData;
}

export const GET_COMMENTS_FOR_PARENT_PAGINATED = gql`
  query getCommentsForParentPaginated(
    $parent: CommentParentInput
    $size: Int
    $sort: [CommentSortParameterInput]
  ) {
    getCommentsForParentPaginated(parent: $parent, size: $size, sort: $sort) {
      total_count
      offset
      size
      comments {
        comment_id
        parent {
          parent_object_id
          parent_object_type
        }
        comment
        created_at
        author_alias
      }
    }
  }
`;

export const useGetCommentsForParentPaginated = (
  options: QueryHookOptions<
    GetCommentsForParentResponse,
    GetCommentsForParentVariables
  >,
) => {
  return useQuery<GetCommentsForParentResponse, GetCommentsForParentVariables>(
    GET_COMMENTS_FOR_PARENT_PAGINATED,
    options,
  );
};
