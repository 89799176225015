import {
  Box,
  Flex,
  HStack,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { graphqlErrorRedirects, URLS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { BlogOutput, useGetCorelightBlogs } from '@gamma/investigator/queries';
import { Panel, PanelHeader, PanelHeading } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';

export const CorelightBlogsPanel = () => {
  const postBgColor = useColorModeValue('gray.50', 'gray.800');
  const postBorderColor = useColorModeValue('gray.50', 'gray.800');
  const postHoverBgColor = useColorModeValue('gray.50', 'gray.800');

  const { loading, error, data } = useGetCorelightBlogs({
    variables: {
      limit: 5,
    },
  });

  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }

  const blogs = data?.getCorelightBlogs;

  const sevenDays = moment().subtract(7, 'd').format();

  const { heading, latestUpdate, label } =
    i18n.pages.securityOverview.panels.corelightBlogs;

  return (
    <Panel pb={2}>
      <PanelHeader divider>
        <PanelHeading>{heading}</PanelHeading>
        <Link
          isExternal
          href={URLS.CORELIGHT_BLOG}
          data-testid="external-blog-link"
        >
          <MuiIcon size="sm">open_in_new</MuiIcon>
        </Link>
      </PanelHeader>
      {blogs?.map((blog: BlogOutput, index: number) => {
        const isNewPost = moment(blog.dc_date).isSameOrAfter(sevenDays);
        return (
          <Panel
            layerStyle={'second'}
            mb={2}
            padding={2}
            key={index}
            _hover={{
              cursor: 'pointer',
              background: 'layer.3',
            }}
          >
            <Flex flexDirection="row" justifyContent="space-between">
              <Text
                mb={1}
                textStyle="body-md"
                w={isNewPost ? 'calc(100% - 32px)' : '100%'}
              >
                <Link isExternal href={blog.link} data-testid="blog-link">
                  {blog.title}
                </Link>
              </Text>
            </Flex>
            <HStack justifyContent="space-between">
              <Text textStyle="body-sm">
                {i18n.formatString(
                  latestUpdate,
                  moment(blog.dc_date).format('MMM D, YYYY'),
                )}
              </Text>
              {isNewPost && (
                <Flex h="fit-content" px={1} bg="blue.600" borderRadius="base">
                  <Text fontSize="12px" fontWeight="600" color="blue.50">
                    {label}
                  </Text>
                </Flex>
              )}
            </HStack>
          </Panel>
        );
      })}
    </Panel>
  );
};
