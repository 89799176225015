import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Text,
  Tooltip,
  useDisclosure,
  usePrevious,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { Input, Switch } from '@gamma/form-fields';
import { URLS } from '@gamma/investigator/constants';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  EscalationConfigurationInput,
  IVerifyEscalationConfiguration,
  QUERY_ESCALATION_CONFIGURATIONS,
  ServiceNowEscalationConfiguration,
  useDeleteEscalationConfigurations,
  useSaveEscalationConfigurations,
  useVerifyEscalationConfiguration,
} from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const { integrations } = i18n.pages;
const {
  save,
  cancel,
  error,
  success,
  warning,
  noGoBack,
  yesDelete,
  enabled: enabledLabel,
  disabled: disabledLabel,
  connectionVerified: connectionVerifiedMessage,
  connectionNotVerified,
} = integrations;

const {
  deleteResult,
  deleteWarning,
  saveSuccess,
  saveError,
  deleteError,
  deleteSuccess,
  tableIDInfo,
  forMoreInfo,
  instanceIDInfo,
  enterCredentials,
  verifyConnection,
  serviceNowFormFIelds,
  serviceNowIntegration,
  deleteServiceNowConnection,
  youCanSendDetectionsManually,
  serviceNowDocumentation,
  connectionVerifiedEnableIntegration,
  detectionsCanBeEscalatedToServiceNow,
  detectionsCanNotBeEscalatedToServiceNow,
} = integrations.serviceNow;

const { SERVICENOW_DOCS } = URLS;

interface ServiceNowSettingsCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceNowConfiguration?: ServiceNowEscalationConfiguration;
  isEnableChanged: boolean;
  setIsEnableChanged: Dispatch<SetStateAction<boolean>>;
}

export const ServiceNowSettingsCardModal = ({
  isOpen,
  onClose,
  serviceNowConfiguration,
  isEnableChanged,
  setIsEnableChanged,
}: ServiceNowSettingsCardModalProps) => {
  const showToast = useToast();
  const logOperationCall = useLogOperationCall();

  const {
    enabled,
    instance_name,
    password,
    table_name,
    username,
    service_name,
    escalation_configuration_id,
  } = serviceNowConfiguration || {};

  const defaultFormDataValues = {
    enabled: enabled || false,
    instance_name: instance_name || '',
    password: password || '',
    table_name: table_name || '',
    username: username || '',
  };

  const {
    watch,
    control,
    register,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultFormDataValues,
  });

  const formData = watch();
  const previousFormData = usePrevious(formData);

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [pwInputType, setPwInputType] = useState<boolean>(true);

  const [configuration, setConfiguration] = useState<
    Omit<EscalationConfigurationInput, 'escalation_configuration_id'>
  >({
    enabled: false,
    service_name: service_name || 'servicenow',
    servicenow: {
      username: '',
      password: '',
      instance_name: '',
      table_name: '',
    },
  });

  const [configurationVerified, setConfigurationVerified] = useState<
    boolean | undefined
  >();

  const [
    verifyEscalationConfiguration,
    {
      data: verifyEscalationConfigurationData,
      loading: verifyEscalationConfigurationLoading,
      error: verifyEscalationConfigurationError,
    },
  ] = useVerifyEscalationConfiguration({
    fetchPolicy: 'network-only',
    variables: {
      escalation_configuration: {
        ...configuration,
      },
    },
    onCompleted: (data: IVerifyEscalationConfiguration) => {
      setConfigurationVerified(data?.verifyEscalationConfiguration?.verified);
    },
  });

  const handleVerifyEscalationConfiguration = async () => {
    verifyEscalationConfiguration();
  };

  const [
    saveEscalationConfigurations,
    {
      data: saveEscalationConfigurationsData,
      loading: saveEscalationConfigurationsLoading,
      error: saveEscalationConfigurationsError,
    },
  ] = useSaveEscalationConfigurations({
    variables: {
      escalation_configurations: [
        {
          escalation_configuration_id,
          ...configuration,
        },
      ],
    },
    refetchQueries: [
      {
        query: QUERY_ESCALATION_CONFIGURATIONS,
      },
    ],
    onCompleted: async () => {
      showToast({
        title: success,
        description: saveSuccess,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
      if (isEnableChanged && enabled !== formData.enabled) {
        const updatedInput = {
          status: formData.enabled ? 'Enabled' : 'Disabled',
          service_name: 'ServiceNow',
        };
        logOperationCall(
          'ENABLE_DISABLE_CONFIGURATION',
          'INTEGRATION',
          JSON.stringify(updatedInput),
        );
      }
      onClose();
    },
    onError: async () => {
      showToast({
        title: error,
        description: saveError,
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const handleSaveEscalationConfigurations = async () => {
    await saveEscalationConfigurations();
    setConfigurationVerified(undefined);
  };

  const [
    deleteEscalationConfigurations,
    {
      data: deleteEscalationConfigurationsData,
      loading: deleteEscalationConfigurationsLoading,
      error: deleteEscalationConfigurationsError,
    },
  ] = useDeleteEscalationConfigurations({
    variables: {
      escalation_configuration_ids: [escalation_configuration_id],
    },
    refetchQueries: [
      {
        query: QUERY_ESCALATION_CONFIGURATIONS,
      },
    ],
    onCompleted: async () => {
      showToast({
        title: success,
        description: deleteSuccess,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
      onClose();
    },
    onError: async () => {
      showToast({
        title: error,
        description: deleteError,
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const handleDeleteEscalationConfigurations = async () => {
    await deleteEscalationConfigurations();
    setConfigurationVerified(undefined);
  };

  const handleEnableDisableConfigurations = () => {
    setIsEnableChanged(!isEnableChanged);
  };

  useEffect(() => {
    if (
      previousFormData !== undefined &&
      !isEqual(previousFormData, formData)
    ) {
      setConfigurationVerified(undefined);
    }
  }, [formData, previousFormData]);

  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        title={serviceNowIntegration}
        closeOnEsc={!verifyEscalationConfigurationLoading}
        isCloseDisabled={verifyEscalationConfigurationLoading}
        closeOnOverlayClick={!verifyEscalationConfigurationLoading}
        body={
          <VStack as="form" alignItems="flex-start" spacing={4}>
            <Text textStyle="body-md">{youCanSendDetectionsManually}</Text>
            {/* @ts-ignore */}
            <Controller
              name="enabled"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Switch
                  inline
                  name={name}
                  isChecked={value}
                  isDisabled={false}
                  onChange={(e) => {
                    onChange(e);
                    handleEnableDisableConfigurations();
                  }}
                  label={formData.enabled ? enabledLabel : disabledLabel}
                />
              )}
            />
            <Box>
              <Text>
                {`${enterCredentials} `}
                <Link
                  isExternal
                  href={SERVICENOW_DOCS}
                  textDecoration="underline"
                >
                  {serviceNowDocumentation}
                </Link>
                {` ${forMoreInfo}`}
              </Text>
            </Box>
            <Input
              {...register('username', {
                required: true,
              })}
              isRequired={true}
              isDisabled={false}
              label={serviceNowFormFIelds.username}
            />
            <Input
              {...register('password', {
                required: true,
              })}
              isRequired={true}
              isDisabled={false}
              label={serviceNowFormFIelds.password}
              type={pwInputType ? 'password' : 'text'}
              rightElement={
                <Button>
                  <MuiIcon
                    cursor="pointer"
                    aria-label={`${pwInputType ? 'Show' : 'Hide'} Password`}
                    onClick={() => setPwInputType(!pwInputType)}
                  >
                    {pwInputType ? 'visibility' : 'visibility_off'}
                  </MuiIcon>
                </Button>
              }
            />
            <Input
              {...register('instance_name', {
                required: true,
              })}
              isRequired={true}
              isDisabled={false}
              tooltip={instanceIDInfo}
              label={serviceNowFormFIelds.instanceID}
            />
            <Input
              {...register('table_name', {
                required: true,
              })}
              isRequired={true}
              isDisabled={false}
              tooltip={tableIDInfo}
              label={serviceNowFormFIelds.tableName}
            />
            <ButtonGroup
              w="100%"
              justifyContent="space-between"
              isDisabled={!isValid || configurationVerified}
            >
              <Button
                variant="solid"
                colorScheme="gray"
                leftIcon={<MuiIcon>check_circle</MuiIcon>}
                onClick={async () => {
                  await setConfiguration({
                    enabled: formData?.enabled,
                    service_name: service_name || 'servicenow',
                    servicenow: {
                      username: formData?.username,
                      password: formData?.password,
                      instance_name: formData?.instance_name,
                      table_name: formData?.table_name,
                    },
                  });
                  handleVerifyEscalationConfiguration();
                }}
                isDisabled={!isValid || configurationVerified}
                isLoading={verifyEscalationConfigurationLoading}
              >
                {verifyConnection}
              </Button>
              <Tooltip label={deleteServiceNowConnection}>
                <IconButton
                  variant="solid"
                  colorScheme={
                    !escalation_configuration_id ||
                    deleteEscalationConfigurationsLoading
                      ? 'gray'
                      : 'red'
                  }
                  onClick={deleteOnOpen}
                  icon={<MuiIcon>delete</MuiIcon>}
                  aria-label={deleteServiceNowConnection}
                  isLoading={deleteEscalationConfigurationsLoading}
                  isDisabled={
                    !escalation_configuration_id ||
                    deleteEscalationConfigurationsLoading
                  }
                />
              </Tooltip>
            </ButtonGroup>
            {configurationVerified === true && (
              <Alert status="success" w="100%">
                <Flex>
                  <MuiIcon color="green.100" mr={2}>
                    check_circle
                  </MuiIcon>
                  <Box>
                    <Text textStyle="body-md-bold">
                      {connectionVerifiedMessage}
                    </Text>
                    <Text>
                      {configuration.enabled
                        ? detectionsCanBeEscalatedToServiceNow
                        : connectionVerifiedEnableIntegration}
                    </Text>
                  </Box>
                </Flex>
              </Alert>
            )}
            {configurationVerified === false && (
              <Alert status="error" w="100%">
                <Flex>
                  <MuiIcon color="red.100" mr={2}>
                    warning
                  </MuiIcon>
                  <Box>
                    <Text textStyle="body-md-bold">
                      {connectionNotVerified}
                    </Text>
                    <Text>{detectionsCanNotBeEscalatedToServiceNow}</Text>
                  </Box>
                </Flex>
              </Alert>
            )}
          </VStack>
        }
        footer={
          <ButtonGroup isDisabled={saveEscalationConfigurationsLoading}>
            <Button variant="solid" colorScheme="gray" onClick={onClose}>
              {cancel}
            </Button>
            <Button
              variant="solid"
              onClick={async () => {
                await setConfiguration({
                  enabled: formData?.enabled,
                  service_name: service_name || 'servicenow',
                  servicenow: {
                    username: formData?.username,
                    password: formData?.password,
                    instance_name: formData?.instance_name,
                    table_name: formData?.table_name,
                  },
                });
                handleSaveEscalationConfigurations();
              }}
              isLoading={saveEscalationConfigurationsLoading}
              isDisabled={
                saveEscalationConfigurationsLoading ||
                !(isDirty && isValid) ||
                (formData?.enabled && !configurationVerified)
              }
            >
              {save}
            </Button>
          </ButtonGroup>
        }
      />
      <Modal
        size="lg"
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        title="Delete ServiceNow Configuration"
        body={
          <Alert variant="subtle" status="warning" w="100%">
            <Flex>
              <MuiIcon color="yellow.100" mr={2}>
                error
              </MuiIcon>
              <Box>
                <Text textStyle="body-md-bold">{warning}</Text>
                <Text>{deleteWarning}</Text>
                <Text>{deleteResult}</Text>
              </Box>
            </Flex>
          </Alert>
        }
        footer={
          <ButtonGroup isDisabled={deleteEscalationConfigurationsLoading}>
            <Button variant="solid" colorScheme="gray" onClick={deleteOnClose}>
              {noGoBack}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={async () => {
                await handleDeleteEscalationConfigurations();
                deleteOnClose();
              }}
              isLoading={deleteEscalationConfigurationsLoading}
              isDisabled={deleteEscalationConfigurationsLoading}
            >
              {yesDelete}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};
