import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import {
  Flex,
  FormHelperText,
  HStack,
  Stack,
  Tooltip,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { MuiIcon } from '@gamma/icons';

export interface LabelProps {
  /** The child content for the label, usually an HTML input */
  children: ReactNode;
  /** The label text to render */
  label: string;
  /** The name of the input to be labeled */
  name: string;
  /** The hint text (light blue) to render underneath the children content */
  hint?: string;
  /**
   * The error text (red) to render underneath the children content. Also
   * causes red border around most input elements
   */
  error?: string | boolean;
  /** The warning text (yellow) to render underneath the children content */
  warning?: string;
  /**
   * The Tooltip text content. Causes a question mark icon to render next to
   * the label text
   */
  tooltip?: ReactNode;
  /** The flag for if the label should render inline to the left of the input */
  inline?: boolean;
  /**
   * The flag for if the input is required. Causes red asterisk to appear next
   * to label text
   */
  isRequired?: FormControlProps['isRequired'];
  /** The flag for if the input is disabled. Causes input to be greyed out */
  isDisabled?: FormControlProps['isDisabled'];
  /** The flag for if the input is read only. Causes input to have striped bg */
  isReadOnly?: FormControlProps['isReadOnly'];
  /** Flags if the label is hidden, then hides it from visual view */
  isLabelHidden?: boolean;
  width?: string | number;
  /** Node following label text */
  afterLabel?: ReactNode;
  htmlFor?: string;
  id?: string;
  h?: string;
}

export const Label = ({
  label,
  hint,
  inline,
  children,
  error,
  warning,
  tooltip,
  name,
  isLabelHidden,
  htmlFor,
  width = 'auto',
  afterLabel,
  id,
  ...rest
}: LabelProps) => {
  const shouldShowHelperText =
    typeof error === 'string' ||
    typeof warning === 'string' ||
    typeof hint === 'string';
  const labelVariant = inline ? 'inline' : undefined;
  const labelContent = (
    <HStack spacing={2} alignItems="center" mb={inline ? 0 : 1.5}>
      <FormLabel
        variant={labelVariant}
        htmlFor={htmlFor}
        data-testid={`${name}-label-text`}
      >
        {label}
      </FormLabel>
      {tooltip && (
        <Tooltip
          hasArrow
          label={tooltip}
          bg="layer.0"
          color="text.base"
          placement="top"
          p={3}
          data-testid={`${name}-tooltip`}
        >
          <Flex>
            <MuiIcon
              data-testid={`${name}-tooltip-icon`}
              size="sm"
              cursor="default"
            >
              help
            </MuiIcon>
          </Flex>
        </Tooltip>
      )}
    </HStack>
  );

  return (
    <FormControl
      {...rest}
      id={id}
      isInvalid={Boolean(error)}
      display={inline ? 'flex' : 'initial'}
      width={width}
    >
      {inline && children}
      <HStack spacing={2} alignItems="center">
        {isLabelHidden ? (
          <VisuallyHidden>{labelContent}</VisuallyHidden>
        ) : (
          labelContent
        )}
        {afterLabel}
      </HStack>
      {!inline && children}
      {shouldShowHelperText && (
        <Stack spacing={0} mt={2} w={inline ? '100%' : 'auto'}>
          {hint && (
            <FormHelperText data-testid={`${name}-hint-text`}>
              {hint}
            </FormHelperText>
          )}
          {typeof error === 'string' && error && (
            <FormErrorMessage data-testid={`${name}-error-text`}>
              {error}
            </FormErrorMessage>
          )}
          {warning && (
            <FormHelperText
              color="state.warning"
              data-testid={`${name}-warning-text`}
            >
              {warning}
            </FormHelperText>
          )}
        </Stack>
      )}
    </FormControl>
  );
};
